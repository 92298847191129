import React, { useState, useEffect } from "react";
import styles from "./LiveJourneys.module.css";
import useGoogleSheets from "use-google-sheets";
import { dynamicSortMultiple } from "../../Util/sort";
import {
  getValueCounts,
  processDate,
  selectOptionsFromArray,
  yyyymmddToLocalDate,
} from "../../Util/util";
import { getUniqueValues } from "../../Util/groups";
import Select from "react-select";
import * as Constants from "../../constants";
import ScoreCard, { formatData } from "./components/ScoreCard";
import ScorecardGroup from "./components/ScorecardGroup";
import PieChartWithLabel from "../../Components/Charts/PieChartWithLabel";
import { v4 as uuidv4 } from "uuid";
import { omit } from "../../Util/util";
import SortableFormatTable from "../../Components/SortableFormattedTable/SortableFormatTable";
import { getObjectUrl } from "../../Util/s3";
import ReactPlayer from "react-player";
import { formatMetric } from "../../Util/util";
import TimeSeriesChart from "../../Components/Charts/TimeSeriesChart";
import FunnelCard from "../Journeys/Components/FunnelCard";

const JOURNEY_FILES = {
  "2023 Q1 - Renewable Energy": {
    filename: "data/journeys/journey_data_2023_Q1.xlsx",
    header: "assets/Journeys/2023Q1_Header.png",
  },
  "2023 Q2 - Regenerative Agriculture": {
    filename: "data/journeys/journey_data_2023_Q2.xlsx",
    header: "assets/Journeys/2023Q2_Header.png",
  },

  "2023 Q3 - Biodiversity": {
    header: "assets/Journeys/2023Q3_Header.png",
  },
};

const sheetsOptions = [{ id: "Meta" }, { id: "TikTok" }, { id: "Google" }];
const BENCHMARKS = {
  TikTok: {
    Aquisition: {
      CPM: 5.0,
      CPC: 1.67,
      CTR: 0.003,
    },
    "Retargeting Layer 1": {
      CPM: 6.0,
      CPC: 2.0,
      CTR: 0.003,
    },
    "Retargeting Layer 2": {
      CPM: 8.0,
      CPC: 2.67,
      CTR: 0.003,
    },
  },
  Google: {
    Aquisition: {
      CPM: null,
      CPC: 0.75,
      CTR: 0.005,
    },
    "Retargeting Layer 1": {
      CPM: null,
      CPC: 3.0,
      CTR: 0.005,
    },
    "Retargeting Layer 2": {
      CPM: null,
      CPC: 3.0,
      CTR: 0.005,
    },
  },
  Meta: {
    Aquisition: {
      CPM: 8.0,
      CPC: 4.0,
      CTR: 0.002,
    },
    "Retargeting Layer 1": {
      CPM: 25.0,
      CPC: 3.0,
      CTR: 0.003,
    },
    "Retargeting Layer 2": {
      CPM: 40.0,
      CPC: 3.33,
      CTR: 0.005,
    },
  },
};

const COLUMNS = {
  Date: { Meta: "Date", TikTok: "Date", Google: "Date" },
  "Campaign Name": {
    Meta: "Campaign name",
    TikTok: "Campaign name",
    Google: "Campaign name",
  },
  "Campaign ID": {
    Meta: "Campaign ID",
    TikTok: "Campaign ID",
    Google: "Campaign ID",
  },
  "Ad Group Name": {
    Meta: "Ad set name",
    TikTok: "Ad group name",
  },
  "Ad Group ID": {
    Meta: "Ad set ID",
    TikTok: "Ad group ID",
  },
  Cost: { Meta: "Cost", TikTok: "Cost", Google: "Cost" },
  Impressions: {
    Meta: "Impressions",
    TikTok: "Impressions",
    Google: "Impressions",
  },
  Clicks: { Meta: "Link clicks", TikTok: "Clicks", Google: "Clicks" },
  Conversions: {
    Meta: "Landing page views",
    TikTok: "Conversions",
    Google: "Conversions",
  },
};

function getSegment(row) {
  if (
    row["Campaign Name"].includes("Acquisition") ||
    row["Ad Group Name"].includes("Acquisition")
  ) {
    return { layer: "Acquisition", segment: "Fragile Identified" };
  } else if (
    row["Campaign Name"].includes("Retargeting 1") ||
    row["Campaign Name"].includes("Retargeting Phase 1") ||
    row["Ad Group Name"].includes("Retargeting 1") ||
    row["Ad Group Name"].includes("Retargeting Phase 1")
  ) {
    return { layer: "Retargeting Layer 1", segment: "Fragile Nurtured" };
  } else if (
    row["Campaign Name"].includes("Retargeting 2") ||
    row["Campaign Name"].includes("Retargeting Phase 2") ||
    row["Ad Group Name"].includes("Retargeting 2") ||
    row["Ad Group Name"].includes("Retargeting Phase 2")
  ) {
    return { layer: "Retargeting Layer 2", segment: "Fragile Engaged" };
  }

  return "Unknown";
}

function getLanguage(row) {
  if (
    row["Campaign Name"].toLowerCase().includes("english") ||
    row["Ad Group Name"].toLowerCase().includes("english")
  ) {
    return "English";
  } else if (
    row["Campaign Name"].toLowerCase().includes("french") ||
    row["Ad Group Name"].toLowerCase().includes("french")
  ) {
    return "French";
  }
  return "Unknown";
}

function getAudience(row) {
  if (
    (row["Campaign Name"].toLowerCase().includes("m2m") &&
      row["Campaign Name"].toLowerCase().includes("core")) ||
    (row["Ad Group Name"].toLowerCase().includes("m2m") &&
      row["Ad Group Name"].toLowerCase().includes("core"))
  ) {
    return "M2M/CORE";
  } else if (
    row["Campaign Name"].toLowerCase().includes("m2m") ||
    row["Ad Group Name"].toLowerCase().includes("m2m")
  ) {
    return "M2M";
  } else if (
    row["Campaign Name"].toLowerCase().includes("core") ||
    row["Ad Group Name"].toLowerCase().includes("core")
  ) {
    return "CORE";
  }
  return "Unknown";
}

const FILTER_TYPES = {
  EQUALS: "EQUALS",
  CONTAINS: "CONTAINS",
};

const platformColumns = [
  {
    label: ["Platform"],
    accessor: "Platform",
    sortable: true,
    style: { fontSize: ".5rem", minWidth: "100%" },
  },
  {
    label: ["Spend"],
    accessor: "Cost",
    sortable: true,
    format: { format: "$", precision: 2 },
  },
  {
    label: ["Impressions"],
    accessor: "Impressions",
    sortable: true,
    format: { format: "", precision: 0 },
  },
  {
    label: ["Clicks"],
    accessor: "Clicks",
    sortable: true,
    format: { format: "", precision: 0 },
  },
  {
    label: ["CPM"],
    accessor: "CPM",
    sortable: true,
    format: { format: "$", precision: 2 },
  },
  {
    label: ["CPC"],
    accessor: "CPC",
    sortable: true,
    format: { format: "$", precision: 2 },
  },
  {
    label: ["CTR"],
    accessor: "CTR",
    sortable: true,
    format: { format: "%%", precision: 4 },
  },
  {
    label: ["Conversions"],
    accessor: "Conversions",
    sortable: true,
    format: { format: "", precision: 0 },
  },
];

const adGroupColumns = [
  {
    label: ["Campaign Name"],
    accessor: "PlatformCampaignAdGroup",
    callback: (name) => {
      return name.split(" -- ")[1];
    },
    sortable: true,
    style: { fontSize: ".5rem", minWidth: "100%" },
  },
  {
    label: ["Platform"],
    accessor: "PlatformCampaignAdGroup",
    callback: (name) => {
      return name.split(" -- ")[0];
    },
    sortable: true,
    style: { fontSize: ".5rem", minWidth: "100%" },
  },
  {
    label: ["Ad Group"],
    accessor: "PlatformCampaignAdGroup",
    callback: (name) => {
      return name.split(" -- ")[2];
    },
    sortable: true,
    style: { fontSize: ".5rem", minWidth: "100%" },
  },
  {
    label: ["Spend"],
    accessor: "Cost",
    sortable: true,
    format: { format: "$", precision: 2 },
  },
  {
    label: ["Impressions"],
    accessor: "Impressions",
    sortable: true,
    format: { format: "", precision: 0 },
  },
  {
    label: ["Clicks"],
    accessor: "Clicks",
    sortable: true,
    format: { format: "", precision: 0 },
  },
  {
    label: ["CPM"],
    accessor: "CPM",
    sortable: true,
    format: { format: "$", precision: 2 },
  },
  {
    label: ["CPC"],
    accessor: "CPC",
    sortable: true,
    format: { format: "$", precision: 2 },
  },
  {
    label: ["CTR"],
    accessor: "CTR",
    sortable: true,
    format: { format: "%%", precision: 4 },
  },
  {
    label: ["Conversions"],
    accessor: "Conversions",
    sortable: true,
    format: { format: "", precision: 0 },
  },
];

const JOURNEY_OPTIONS = [
  "2023 Q3 - Biodiversity",
  "2023 Q2 - Regenerative Agriculture",
  "2023 Q1 - Renewable Energy",
];

const JOURNEY_CAMPAIGN_FILTERS = {
  "2023 Q1 - Renewable Energy": [
    {
      accessor: "Campaign Name",
      value: "Q2",
      type: FILTER_TYPES.CONTAINS,
      exclude: true,
    },
    {
      accessor: "Campaign Name",
      value: "Q3",
      type: FILTER_TYPES.CONTAINS,
      exclude: true,
    },
  ],
  "2023 Q2 - Regenerative Agriculture": [
    {
      accessor: "Campaign Name",
      value: "Q2",
      type: FILTER_TYPES.CONTAINS,
      exclude: false,
    },
  ],
  "2023 Q3 - Biodiversity": [
    {
      accessor: "Campaign Name",
      value: "Q3",
      type: FILTER_TYPES.CONTAINS,
      exclude: false,
    },
  ],
};

const aggregateFilters = {
  Overall: [],
  M2M: [
    {
      accessor: "Audience",
      value: "M2M",
      type: FILTER_TYPES.EQUALS,
      exclude: false,
    },
  ],
  CORE: [
    {
      accessor: "Audience",
      value: "CORE",
      type: FILTER_TYPES.EQUALS,
      exclude: false,
    },
  ],
  "M2M/CORE": [
    {
      accessor: "Audience",
      value: "M2M/CORE",
      type: FILTER_TYPES.EQUALS,
      exclude: false,
    },
  ],
  English: [
    {
      accessor: "Campaign Name",
      value: "English",
      type: FILTER_TYPES.CONTAINS,
      exclude: false,
    },
  ],
  French: [
    {
      accessor: "Campaign Name",
      value: "French",
      type: FILTER_TYPES.CONTAINS,
      exclude: false,
    },
  ],
};

function getSegmentConversions(data, segment) {
  return data
    .filter((item) => item["Segment"] === segment)
    .reduce((sum, row) => {
      return sum + Number(row.Conversions);
    }, 0);
}
const standardizeMergeColumns = (data, sheetNameColumn, columns) => {
  const newData = [];

  for (const sheetName of Object.keys(data)) {
    for (const row of data[sheetName]) {
      const newRow = {};
      newRow[sheetNameColumn] = sheetName;
      for (const column of Object.keys(columns)) {
        newRow[column] =
          sheetName === "Google" &&
          ["Ad Group Name", "Ad Group ID"].includes(column)
            ? (row[column] = "")
            : row[columns[column][sheetName]];
      }
      //console.log(row.Date)
      newRow["Nice Date"] = processDate(row.Date);
      newData.push(newRow);
    }
  }

  for (const [idx, row] of newData.entries()) {
    newData[idx] = {
      ...row,
      PlatformCampaignAdGroup: `${row["Platform"]} -- ${row["Campaign Name"]} -- ${row["Ad Group Name"]}`,
      Segment: getSegment(row).segment,
      Layer: getSegment(row).layer,
      Language: getLanguage(row),
      Audience: getAudience(row),
    };
  }

  //console.log(newData);
  return newData.sort(dynamicSortMultiple(["Date", 1], ["Platform", 1]));
};

function filterMultiple(data, filters) {
  let filteredData = data;
  if (!filters) {
    return filteredData;
  }
  for (const filter of filters) {
    filteredData = filteredData.filter((item) => {
      if (filter.exclude) {
        return filter.type === FILTER_TYPES.EQUALS
          ? item[filter.accessor] !== filter.value
          : !item[filter.accessor].includes(filter.value);
      } else {
        return filter.type === FILTER_TYPES.EQUALS
          ? item[filter.accessor] == filter.value
          : item[filter.accessor].includes(filter.value);
      }
    });
  }
  return filteredData;
}

function makeJSON(data, accessor) {
  const unique = getUniqueValues(data, accessor);

  const result = {};

  for (const value of unique) {
    result[value] = data.filter((item) => item[accessor] === value)[0];
  }
  return result;
}

function groupBy(data, groupAccessor) {
  let groupedData = {};
  if (data.length === 0) {
    return {};
  }
  const unique = getUniqueValues(data, groupAccessor);

  let aggregates = {};

  for (const key of unique) {
    groupedData[key] = data.filter((item) => item[groupAccessor] === key);
  }
  return groupedData;
}

function groupByAggregateJSON(data, groupAccessor = null) {
  let groupedData = [];
  if (groupAccessor) {
    const unique = getUniqueValues(data, groupAccessor);

    let aggregates = {};

    for (const key of unique) {
      let group = {
        data: data.filter((item) => item[groupAccessor] === key),
        id: uuidv4(),
      };
      group[groupAccessor] = key ? key : "N/A";
      groupedData.push(group);
    }
  } else {
    //console.log("POOP");
    //console.log(data);
    groupedData = [{ data: data, id: uuidv4() }];
  }

  //console.log(groupedData);
  groupedData = groupedData.map((group) => ({
    ...group,
    Cost: group.data.reduce((sum, row) => {
      return sum + Number(row.Cost);
    }, 0),
    Impressions: group.data.reduce((sum, row) => {
      return sum + Number(row.Impressions);
    }, 0),
    Clicks: group.data.reduce((sum, row) => {
      return sum + Number(row.Clicks);
    }, 0),
    Conversions: group.data.reduce((sum, row) => {
      return sum + Number(row.Conversions);
    }, 0),
  }));

  groupedData = groupedData.map((group) => ({
    ...group,

    CPM:
      group["Impressions"] > 0
        ? (group["Cost"] / group["Impressions"]) * 1000
        : null,
    CPC: group["Clicks"] > 0 ? group["Cost"] / group["Clicks"] : null,
    CTR:
      group["Impressions"] > 0 ? group["Clicks"] / group["Impressions"] : null,
  }));

  groupedData = groupedData.map((group) => omit(group, "data"));

  const result = {};

  for (const group of groupedData) {
    result[group[groupAccessor]] = group;
  }
  return result;
}

function groupByAggregate(data, groupAccessor = null) {
  let groupedData = [];
  if (groupAccessor) {
    const unique = getUniqueValues(data, groupAccessor);

    let aggregates = {};

    for (const key of unique) {
      let group = {
        data: data.filter((item) => item[groupAccessor] === key),
        id: uuidv4(),
      };
      group[groupAccessor] = key ? key : "N/A";
      groupedData.push(group);
    }
  } else {
    //console.log("POOP");
    //console.log(data);
    groupedData = [{ data: data, id: uuidv4() }];
  }

  //console.log(groupedData);
  groupedData = groupedData.map((group) => ({
    ...group,
    Cost: group.data.reduce((sum, row) => {
      return sum + Number(row.Cost);
    }, 0),
    Impressions: group.data.reduce((sum, row) => {
      return sum + Number(row.Impressions);
    }, 0),
    Clicks: group.data.reduce((sum, row) => {
      return sum + Number(row.Clicks);
    }, 0),
    Conversions: group.data.reduce((sum, row) => {
      return sum + Number(row.Conversions);
    }, 0),
  }));

  groupedData = groupedData.map((group) => ({
    ...group,

    CPM:
      group["Impressions"] > 0
        ? (group["Cost"] / group["Impressions"]) * 1000
        : null,
    CPC: group["Clicks"] > 0 ? group["Cost"] / group["Clicks"] : null,
    CTR:
      group["Impressions"] > 0 ? group["Clicks"] / group["Impressions"] : null,
  }));

  groupedData = groupedData.map((group) => omit(group, "data"));
  return groupedData;
}

/*
function groupByMultipleAggregate(data, groupAccessors = []) {
  let groupedData = [];
  if (groupAccessors.length > 0 ) {
    const unique = getUniqueValues(data, groupAccessor);

    let aggregates = {};

    for (const key of unique) {
      let group = {
        data: data.filter((item) => item[groupAccessor] === key),
        id: uuidv4(),
      };
      group[groupAccessor] = key ? key : "N/A";
      groupedData.push(group);
    }
  } else {
    //console.log("POOP");
    //console.log(data);
    groupedData = [{ data: data, id: uuidv4() }];
  }

  //console.log(groupedData);
  groupedData = groupedData.map((group) => ({
    ...group,
    Cost: group.data.reduce((sum, row) => {
      return sum + Number(row.Cost);
    }, 0),
    Impressions: group.data.reduce((sum, row) => {
      return sum + Number(row.Impressions);
    }, 0),
    Clicks: group.data.reduce((sum, row) => {
      return sum + Number(row.Clicks);
    }, 0),
    Conversions: group.data.reduce((sum, row) => {
      return sum + Number(row.Conversions);
    }, 0),
  }));

  groupedData = groupedData.map((group) => ({
    ...group,

    CPM:
      group["Impressions"] > 0
        ? (group["Cost"] / group["Impressions"]) * 1000
        : null,
    CPC: group["Clicks"] > 0 ? group["Cost"] / group["Clicks"] : null,
    CTR:
      group["Impressions"] > 0 ? group["Clicks"] / group["Impressions"] : null,
  }));

  groupedData = groupedData.map((group) => omit(group, "data"));
  return groupedData;
}



*/

function aggregate(data) {
  let aggregates = {};

  aggregates = {
    ...aggregates,
    Cost: data.reduce((sum, row) => {
      return sum + Number(row.Cost);
    }, 0),

    Impressions: data.reduce((sum, row) => {
      return sum + Number(row.Impressions);
    }, 0),
    Clicks: data.reduce((sum, row) => {
      return sum + Number(row.Clicks);
    }, 0),
    Conversions: data.reduce((sum, row) => {
      return sum + Number(row.Conversions);
    }, 0),
  };
  aggregates = {
    ...aggregates,
    CPM:
      aggregates["Impressions"] > 0
        ? (aggregates["Cost"] / aggregates["Impressions"]) * 1000
        : null,
    CPC:
      aggregates["Clicks"] > 0
        ? aggregates["Cost"] / aggregates["Clicks"]
        : null,
    CTR:
      aggregates["Impressions"] > 0
        ? aggregates["Clicks"] / aggregates["Impressions"]
        : null,
  };

  return aggregates;
}

function makePieChartData(aggregateData, labelAccessor, valueAccessor) {
  let pieData = [];

  for (const data of aggregateData) {
    pieData.push({ name: data[labelAccessor], value: data[valueAccessor] });
  }
  return pieData;
}

const LiveJourneys = () => {
  const { data, loading, error } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_JOURNEYS_GOOGLE_SHEETS_ID,
    sheetsOptions,
  });
  const [bottleUrl, setBottleUrl] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  useEffect(() => {
    getObjectUrl("bottle.webm").then(setBottleUrl).catch(console.error);
  }, []);

  const [journeyData, setJourneyData] = useState({});
  const [selectedJourney, setSelectedJourney] = useState(JOURNEY_OPTIONS[0]);
  const [headerImageUrl, setHeaderImageUrl] = useState(null);
  useEffect(() => {
    if (selectedJourney) {
      getObjectUrl(JOURNEY_FILES[selectedJourney].header)
        .then(setHeaderImageUrl)
        .catch(console.error);
    }
  }, [selectedJourney]);
  useEffect(() => {
    if (!loading) {
      const tempData = {};
      for (const obj of data) {
        const newData = [];
        for (const row of obj.data) {
          const newRow = {
            ...row,
            Date: yyyymmddToLocalDate(row["Date"]),
          };
          newData.push(newRow);
        }
        tempData[obj.id] = newData;
      }

      setJourneyData(standardizeMergeColumns(tempData, "Platform", COLUMNS));
    }
  }, [loading]);

  const [aggregatedData, setAggregatedData] = useState(null);
  const filteredData =
    journeyData.length > 0
      ? filterMultiple(journeyData, JOURNEY_CAMPAIGN_FILTERS[selectedJourney])
      : [];

  const segmentAggregates =
    journeyData.length > 0
      ? makeJSON(groupByAggregate(journeyData, "Segment"), "Segment")
      : null;

  const platformAggregates =
    journeyData.length > 0
      ? makeJSON(groupByAggregate(journeyData, "Platform"), "Platform")
      : null;

  const dateGroups = groupBy(journeyData, "Nice Date");

  const dateSegmentAggregatesTemp = Object.keys(dateGroups)
    .sort((a, b) => new Date(a) - new Date(b))
    .map((key) => {
      return { [key]: groupByAggregateJSON(dateGroups[key], "Segment") };
    });

  const dateSegmentAggregates = {};
  for (const dateSegment of dateSegmentAggregatesTemp) {
    const dsKey = Object.keys(dateSegment)[0];
    dateSegmentAggregates[dsKey] = dateSegment[dsKey];
  }

  function makeTimeSeriesData(data, segments, accessor) {
    const totals = {};

    if (Object.keys(data).length === 0) {
      return null;
    }
    for (const segment of segments) {
      totals[segment] = 0;
    }

    totals["Total"] = 0;

    const timeSeries = [];

    for (const date of Object.keys(data)) {
      const newRow = { date: date };
      let total = 0;
      for (const segment of segments) {
        if (Object.keys(data[date]).includes(segment)) {
          totals[segment] += data[date][segment][accessor];
        }
        total += totals[segment];
        newRow[segment] = totals[segment];
      }

      newRow["Total"] = total;

      timeSeries.push(newRow);
    }

    return timeSeries;
  }
  const audienceTimeSeriesData = makeTimeSeriesData(
    dateSegmentAggregates,
    ["Fragile Identified", "Fragile Nurtured", "Fragile Engaged"],
    "Conversions"
  );

  //console.log(segmentAggregates);
  useEffect(() => {
    if (journeyData.length > 0) {
      const tempAggData = {};

      for (const key of Object.keys(aggregateFilters)) {
        tempAggData[key] = aggregate(
          filterMultiple(journeyData, [
            ...JOURNEY_CAMPAIGN_FILTERS[selectedJourney],
            ...aggregateFilters[key],
          ])
        );
      }
      setAggregatedData(tempAggData);
    }
  }, [journeyData, selectedJourney]);
  /* console.log(journeyData);
  console.log(groupByAggregate(journeyData, "Campaign Name"));
  console.log(groupByAggregate(filteredData));

  console.log(aggregatedData);

  console.log(selectedJourney);
  console.log("Campaigns");
  console.log(getUniqueValues(filteredData, "Campaign Name"));

  console.log("Ad Groups");
  console.log(getUniqueValues(filteredData, "Ad Group Name"));
  console.log(getUniqueValues(filteredData, 'Segment'))
  console.log("TEST")
  console.log(getValueCounts(filteredData, 'Segment'))
  console.log(getValueCounts(filteredData, 'Audience'))
  console.log(getValueCounts(filteredData, 'Language'))
  console.log(getSegmentConversions(filteredData, 'Fragile Identified'))*/

  // const aggregatedData =
  //  filteredData.length > 0 ? aggregateFiltersaggregate(filteredData) : null;

  /*

  function getPlatformLayerAggregates(filteredData){
    const metrics = ["CPM", "CPC", "CTR"]
    if(filteredData.length === 0){
      return [];
    }
    const platforms = getUniqueValues(filteredData, 'Platform');

    
    for(const aggData of aggregated){
      const platformData = {
        adsets: [],
        platformName: aggData.Platform
      }
      for(const layer of Object.keys(BENCHMARKS)){
        console.log(layer)
      }
      
    }
    return [];
  }
  getPlatformLayerAggregates(filteredData)*/

  function getFunnelLayers(data, filterAccessor, filterValue, name) {
    const filteredData = filterData(data, filterAccessor, filterValue);
    const aggregateData = aggregate(filteredData);
    const groupData = groupByAggregateJSON(filteredData, "Segment");

    const cost = aggregateData.Cost;
    const layers = Object.keys(groupData);
    const identified = layers.includes("Fragile Identified")
      ? groupData["Fragile Identified"].Conversions
      : 0;
    const nurtured = layers.includes("Fragile Nurtured")
      ? groupData["Fragile Nurtured"].Conversions
      : 0;
    const engaged = layers.includes("Fragile Engaged")
      ? groupData["Fragile Engaged"].Conversions
      : 0;

    return {
      "Amount Spent": cost,
      "Fragile Identified": identified,
      "Fragile Nurtured": nurtured,
      "Fragile Engaged": engaged,
      Name: name,
    };
  }

  function getFunnelData(data, filters, niceDate) {
    const funnelData = { Date: niceDate, data: [] };
    for (const filter of filters) {
      funnelData.data.push(
        getFunnelLayers(data, filter.accessor, filter.value, filter.name)
      );
    }

    return funnelData;
  }

  const funnelFilters = [
    { accessor: "Audience", value: "M2M", name: "M2M" },
    { accessor: "Audience", value: "CORE", name: "CORE" },
    { accessor: "Language", value: "English", name: "English" },
    { accessor: "Language", value: "French", name: "French" },
  ];
  //console.log(aggregate(filteredData));
  const funnelData =
    filteredData.length > 0
      ? getFunnelData(
          filteredData,
          funnelFilters,
          journeyData.at(-1)["Nice Date"]
        )
      : null;
  const mainMetrics = [
    { accessor: "Cost", title: "Spend", format: "$", precision: 2 },
    { accessor: "Impressions", title: "Impressions", format: "", precision: 0 },
    { accessor: "Clicks", title: "Clicks", format: "", precision: 0 },
    { accessor: "CPM", title: "CPM", format: "$", precision: 2 },
    { accessor: "CPC", title: "CPC", format: "$", precision: 2 },
    { accessor: "CTR", title: "CTR", format: "%%", precision: 2 },
    { accessor: "Conversions", title: "Conversions", format: "", precision: 0 },
  ];

  //console.log(aggregatedData);

  /*if (journeyData.length > 0) {
    console.log(getUniqueValues(journeyData, "Platform"));
    console.log(groupByMultiple(journeyData, ["Platform", "Campaign Name"]));
  }*/
  const [seconds, setSeconds] = useState(0);
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error!</div>;
  }
  const totalCoords = { x: 120, y: 250 };
  const engagedCoords = { x: 1045, y: 355 };
  const nurturedCoords = { x: -50, y: 535 };
  const identifiedCoords = { x: 1050, y: 660 };
  const mvsCoords = { x: 980, y: 170 };
  const fragileWidth = 350;
  const slant = { x: 60, y: 50 };

  return (
    <>
      <div className="w-full mt-0 mx-auto flex flex-col justify-center items-center mb-20">
        <div className=" flex flex-col items-end justify-end mb-0 w-[90%]">
          <h1 className="text-xs font-bold text-primary-dark">
            {journeyData.length > 0
              ? `Last Update: ${journeyData.at(-1)["Nice Date"]}`
              : null}{" "}
          </h1>
        </div>

        {
          <div className={styles.audienceAnalyticsContainer}>
            <div className={styles.audienceTitle}>
              JOURNEY AUDIENCE ANALYTICS
            </div>
            {segmentAggregates && (
              <div className={styles.bottle}>
                {bottleUrl && (
                  <ReactPlayer
                    width="972px"
                    height="590px"
                    playing={seconds < 15}
                    autoPlay
                    url={bottleUrl}
                    onProgress={(e) => {
                      setSeconds(e.playedSeconds);
                    }}
                    muted
                    config={{
                      file: {
                        attributes: {
                          autoPlay: true,
                          muted: true,
                        },
                      },
                    }}
                    onStart={() => {
                      setIsPlaying(true);
                    }}
                  />
                )}
                <svg className={styles.milksvg} viewBox="0 0 1000 1000">
                  <text
                    className={
                      isPlaying
                        ? `${styles.milkBold} ${styles.totalText}`
                        : styles.hidden
                    }
                    textAnchor="middle"
                    x={totalCoords.x}
                    y={totalCoords.y - 90}
                  >
                    TOTAL CANADIANS IN OUR
                  </text>
                  <text
                    className={
                      isPlaying
                        ? `${styles.milkBold} ${styles.totalText}`
                        : styles.hidden
                    }
                    textAnchor="middle"
                    x={totalCoords.x}
                    y={totalCoords.y - 50}
                  >
                    DAIRY ECOSYSTEM JOURNEY
                  </text>
                  <text
                    className={
                      isPlaying
                        ? `${styles.milkNormal} ${styles.totalText}`
                        : styles.hidden
                    }
                    textAnchor="middle"
                    x={totalCoords.x}
                    y={totalCoords.y - 10}
                  >
                    {formatMetric(
                      segmentAggregates["Fragile Identified"]["Conversions"] +
                        segmentAggregates["Fragile Nurtured"]["Conversions"] +
                        segmentAggregates["Fragile Engaged"]["Conversions"]
                    )}{" "}
                    CANADIANS
                  </text>
                  <path
                    className={
                      isPlaying
                        ? `${styles.path} ${styles.mvsPath}`
                        : styles.hidden
                    }
                    fill="none"
                    d={`M${mvsCoords.x + 140} ${mvsCoords.y} L${
                      mvsCoords.x - fragileWidth
                    } ${mvsCoords.y} L${mvsCoords.x - fragileWidth - slant.x} ${
                      mvsCoords.y + slant.y
                    }`}
                  />
                  <text
                    className={
                      isPlaying
                        ? `${styles.milkBold} ${styles.mvsText}`
                        : styles.hidden
                    }
                    textAnchor="start"
                    x={mvsCoords.x - fragileWidth + 20}
                    y={mvsCoords.y - 10}
                  >
                    JOURNEY COMPLETERS (MVS)
                  </text>
                  <text
                    className={
                      isPlaying
                        ? `${styles.milkNormal} ${styles.mvsText}`
                        : styles.hidden
                    }
                    textAnchor="start"
                    x={mvsCoords.x - fragileWidth + 100}
                    y={mvsCoords.y + 30}
                  >
                    {formatMetric(
                      segmentAggregates["Fragile Engaged"]["Conversions"]
                    )}{" "}
                    CANADIANS
                  </text>

                  <text
                    className={
                      isPlaying
                        ? `${styles.milkNormal} ${styles.fragileEngagedText}`
                        : styles.hidden
                    }
                    textAnchor="start"
                    x={engagedCoords.x - fragileWidth + 20}
                    y={engagedCoords.y + 30}
                  >
                    {formatMetric(
                      segmentAggregates["Fragile Engaged"]["Conversions"]
                    )}{" "}
                    CANADIANS
                  </text>

                  <path
                    className={
                      isPlaying
                        ? `${styles.path} ${styles.fragileEngagedPath}`
                        : styles.hidden
                    }
                    fill="none"
                    d={`M${engagedCoords.x} ${engagedCoords.y} L${
                      engagedCoords.x - fragileWidth
                    } ${engagedCoords.y} L${
                      engagedCoords.x - fragileWidth - slant.x
                    } ${engagedCoords.y - slant.y}`}
                  />
                  <text
                    className={
                      isPlaying
                        ? `${styles.milkBold} ${styles.fragileEngagedText}`
                        : styles.hidden
                    }
                    textAnchor="start"
                    x={engagedCoords.x - fragileWidth + 20}
                    y={engagedCoords.y - 10}
                  >
                    FRAGILE ENGAGED
                  </text>
                  <text
                    className={
                      isPlaying
                        ? `${styles.milkNormal} ${styles.fragileEngagedText}`
                        : styles.hidden
                    }
                    textAnchor="start"
                    x={engagedCoords.x - fragileWidth + 20}
                    y={engagedCoords.y + 30}
                  >
                    {formatMetric(
                      segmentAggregates["Fragile Engaged"]["Conversions"]
                    )}{" "}
                    CANADIANS
                  </text>

                  <path
                    className={
                      isPlaying
                        ? `${styles.path} ${styles.fragileNurturedPath}`
                        : styles.hidden
                    }
                    fill="none"
                    d={`M${nurturedCoords.x} ${nurturedCoords.y} L${
                      nurturedCoords.x + fragileWidth
                    } ${nurturedCoords.y} L${
                      nurturedCoords.x + fragileWidth + slant.x
                    } ${nurturedCoords.y - slant.y}`}
                  />

                  <text
                    className={
                      isPlaying
                        ? `${styles.milkBold} ${styles.fragileNurturedText}`
                        : styles.hidden
                    }
                    textAnchor="start"
                    x={nurturedCoords.x + 20}
                    y={nurturedCoords.y - 10}
                  >
                    FRAGILE NURTURED
                  </text>
                  <text
                    className={
                      isPlaying
                        ? `${styles.milkNormal} ${styles.fragileNurturedText}`
                        : styles.hidden
                    }
                    textAnchor="start"
                    x={nurturedCoords.x + +20}
                    y={nurturedCoords.y + 30}
                  >
                    {formatMetric(
                      segmentAggregates["Fragile Nurtured"]["Conversions"]
                    )}{" "}
                    CANADIANS
                  </text>

                  <path
                    className={
                      isPlaying
                        ? `${styles.path} ${styles.fragileIdentifiedPath}`
                        : styles.hidden
                    }
                    fill="none"
                    d={`M${identifiedCoords.x} ${identifiedCoords.y} L${
                      identifiedCoords.x - fragileWidth
                    } ${identifiedCoords.y} L${
                      identifiedCoords.x - fragileWidth - slant.x
                    } ${identifiedCoords.y - slant.y}`}
                  />
                  <text
                    className={
                      isPlaying
                        ? `${styles.milkBold} ${styles.fragileIdentifiedText}`
                        : styles.hidden
                    }
                    textAnchor="start"
                    x={identifiedCoords.x - fragileWidth + 20}
                    y={identifiedCoords.y - 10}
                  >
                    FRAGILE IDENTIFIED
                  </text>
                  <text
                    className={
                      isPlaying
                        ? `${styles.milkNormal} ${styles.fragileIdentifiedText}`
                        : styles.hidden
                    }
                    textAnchor="start"
                    x={identifiedCoords.x - fragileWidth + 20}
                    y={identifiedCoords.y + 30}
                  >
                    {formatMetric(
                      segmentAggregates["Fragile Identified"]["Conversions"]
                    )}{" "}
                    CANADIANS
                  </text>
                </svg>
              </div>
            )}

            {platformAggregates && (
              <div className={styles.audienceSummary}>
                <p className={styles.fragileTitle}>
                  TOTAL 2023 ADDRESSABLE FRAGILE AUDIENCE
                </p>
                <div className={styles.fragileText}>
                  <p className={styles.statText}>3.3MM Canadians</p>
                </div>
                <p className={styles.platformAudiences}>PLATFORM AUDIENCES</p>
                <table className={styles.audienceTable}>
                  <tbody>
                    <tr>
                      <td className={styles.platformTitle}>GOOGLE</td>
                      <td className={styles.platformNumber}>
                        {formatMetric(
                          platformAggregates["Google"]["Conversions"]
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.platformTitle}>TIKTOK</td>
                      <td className={styles.platformNumber}>
                        {formatMetric(
                          platformAggregates["TikTok"]["Conversions"]
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.platformTitle}>META</td>
                      <td className={styles.platformNumber}>
                        {formatMetric(
                          platformAggregates["Meta"]["Conversions"]
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        }
        {audienceTimeSeriesData && (
          <div className={styles.completedJourneysContainer}>
            <TimeSeriesChart
              title={"Audience Growth"}
              data={audienceTimeSeriesData}
              dataKeys={[
                { dataKey: "Fragile Identified", stroke: "rgb(53, 162, 235)" },
                { dataKey: "Fragile Nurtured", stroke: "rgb(255, 99, 132)" },
                { dataKey: "Fragile Engaged", stroke: "rgb(0, 99, 132)" },
              ]}
            />
          </div>
        )}
        <div className = "w-full h-1 bg-black"></div>
        <header className="bg-white mr-auto flex justify-center items-center w-full mt-2">
          <div className="flex items-start justify-start flex-1 ml-10">
            <Select
              options={selectOptionsFromArray(JOURNEY_OPTIONS)}
              onChange={(selectedOption) => {
                setSelectedJourney(selectedOption.value);
              }}
              value={{ value: selectedJourney, label: selectedJourney }}
              menuPortalTarget={document.querySelector("body")}
              styles={Constants.SELECT_STYLE("400px", "40px", "1rem", "1rem")}
              placeholder="Select a Journey"
            />
          </div>
          <h1 className="font-bold text-2xl">{`DFC Journey ${selectedJourney}`}</h1>
          <div className="flex-1"></div>
          
        </header>
        <div className={styles.journeyHeader}>
            
            <img style={{ width: "30%", aspectRatio:"16/9" }} src={headerImageUrl} />
          </div>
        <div className="w-full mt-2 mb-5 bg-white">
          <div className="bg-white w-[80%] mx-auto flex flex-col justify-center items-center gap-4 mt-2 pb-[20px]">
            {aggregatedData &&
              Object.keys(aggregatedData).map((key) => {
                return (
                  aggregatedData[key].Cost > 0 && (
                    <ScorecardGroup
                      title={key}
                      key={key}
                      data={aggregatedData[key]}
                      metrics={mainMetrics}
                    />
                  )
                );
              })}
          </div>
          <div className="flex w-[80%] h-[200px] justify-evenly items-center mx-auto mt-2 ">
            <fieldset className="w-[40%] h-full border-solid border-black border bg-white">
              <legend align="left" className=" font-medium text-sm ml-2">
                BUDGET DISTRIBUTION
              </legend>
              {filteredData.length > 0 && (
                <PieChartWithLabel
                  metric="Spend"
                  data={makePieChartData(
                    groupByAggregate(filteredData, "Platform"),
                    "Platform",
                    "Cost"
                  )}
                />
              )}
            </fieldset>
            <fieldset className="w-[40%] h-full border-solid border-black border bg-white">
              <legend align="left" className=" font-medium text-sm ml-2">
                CLICK SHARE
              </legend>
              {filteredData.length > 0 && (
                <PieChartWithLabel
                  metric="Clicks"
                  data={makePieChartData(
                    groupByAggregate(filteredData, "Platform"),
                    "Platform",
                    "Clicks"
                  )}
                />
              )}
            </fieldset>
          </div>
        </div>
        <fieldset className="w-[80%] h-full  bg-white">
          <legend align="left" className=" font-medium text-sm ml-2">
            Breakdown by Platform
          </legend>
          {filteredData.length > 0 && (
            <SortableFormatTable
              columns={platformColumns}
              data={groupByAggregate(filteredData, "Platform")}
              defaultSort={{ accessor: "Platform", order: 1 }}
              rowKeyAccessors={["Platform"]}
            />
          )}
        </fieldset>

        <fieldset className="mt-4 w-[80%] max-h-full mx-auto overflow-auto bg-white mb-5">
          <legend align="left" className=" font-medium text-sm ml-2">
            Breakdown by Platform
          </legend>
          {filteredData.length > 0 && (
            <SortableFormatTable
              columns={adGroupColumns}
              data={groupByAggregate(filteredData, "PlatformCampaignAdGroup")}
              defaultSort={{ accessor: "PlatformCampaignAdGroup", order: 1 }}
              rowKeyAccessors={["PlatformCampaignAdGroup"]}
            />
          )}
        </fieldset>

        {funnelData && (
          <div className={styles.funnelContainer}>
            {funnelData.data.map((item) => {
              return <FunnelCard {...item} key={item.Name} />;
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default LiveJourneys;

/*<fieldset className="bg-gray-200 rounded-xl w-[95%] mx-auto border border-solid border-black p-2 flex flex-col">
          <legend align="center" className="font-semibold text-lg">
            Overall
          </legend>
          <div className=" flex justify-evenly items-center">
            {false &&
              aggregatedData &&
              mainMetrics.map((metric) => (
                <ScoreCard
                  title={metric.title}
                  content={aggregatedData[metric.accessor]}
                  format={metric.format}
                  precision={metric.precision}
                  key={metric.accessor}
                />
              ))}
          </div>
        </fieldset>*/

function filterData(data, column, value) {
  const keys = data.flatMap(Object.keys);

  if (!keys.includes(column)) {
    throw new Error(
      `Error in filterData: The column ${column} does not exist as a valid key in the passed data.`
    );
  }

  return data.filter((item) => item[column] === value);
}

/*function filterMultiple2(data, columns, values) {
  const keys = data.flatMap(Object.keys);

  if (!keys.includes(column)) {
    throw new Error(
      `Error in filterData: The column ${column} does not exist as a valid key in the passed data.`
    );
  }
}*/

function groupByMultiple(data, columns) {
  if (!Array.isArray(columns)) {
    columns = [columns];
  }

  const keys = data.flatMap(Object.keys);

  for (const column of columns) {
    if (!keys.includes(column)) {
      throw new Error(
        `Error in getUnique: The column ${column} does not exist as a valid key in the passed data.`
      );
    }
  }

  const unique = {};
  for (const column of columns) {
    unique[column] = getUniqueValues(data, column);
  }

  return unique;
}
