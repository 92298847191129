import styles from "./Carousel.module.css";

import ProgressSlider from "./ProgressSlider";
import ThumbnailCard from "../Thumbnail/ThumbnailCard";
import { getObjectUrl, getObject, getSignedUrl } from "../../../../Util/s3";
import { Fragment, useMemo, useState, useEffect } from "react";
import { faDownload, faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";

const XLSX = require("xlsx");

const getPageNumber = (idx, thumbnailsPerPage) => {
  return Math.ceil((idx + 1) / thumbnailsPerPage);
};

const Carousel = (props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [translateStyle, setTranslateStyle] = useState({
    transform: "translateX(0%)",
  });

  const NUM_PAGES = useMemo(() => {
    return Math.ceil(props.assetData.length / props.thumbnailsPerPage) - 1;
  }, [props.assetData, props.thumbnailsPerPage]);

  useEffect(() => {
    setCurrentPage((prevState) => {
      return Math.min(prevState, NUM_PAGES);
    });
  }, [props.thumbnailsPerPage]);

  useEffect(() => {
    setTranslateStyle({ transform: `translateX(${-100 * currentPage}%)` });
  }, [currentPage]);

  const onLeftHandleClick = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => {
        return prevPage - 1;
      });
    }
  };

  const onRightHandleClick = () => {
    if (currentPage < NUM_PAGES) {
      setCurrentPage((prevPage) => {
        return prevPage + 1;
      });
    }
  };
  if (props.assetData.length === 0) {
    return null;
  }

  const handleCarouselExport = async () => {
    const tsn = "Traffic Sheet Name";

    const urlen = "URL EN";
    const urlfr = "URL FR";
    const ts = "Traffic Sheet";
    const fn = "Filename";

    const exportData = await Promise.all(
      props.assetData.map(async ({ tsn, urlen, urlfr, ts, ...rest }) =>  {return {...rest,
        filename: await getSignedUrl(
          "assets/" + rest.LOB + "/" + rest.Campaign + "/" + rest.Filename,
          rest.Filename
        )
      }})
    ); //.then(url => {return {...rest, filename: url}}));
    
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Carousel Data");
    XLSX.writeFile(workbook, `Carousel_${props.group}_${props.title}_${props.sort[0][0]}.xlsx`, { compression: true });

    
  };
  return (
    <div
      className={styles["carouselWrapper"]}
      style={{ height: props.height ? props.height : "100%" }}
    >
      <div className={styles["carouselHeader"]}>
        <div className={styles["carouselTitle"]}>{props.title}</div>
        <div className={styles["exportButton"]}>
          <FontAwesomeIcon
            icon={faDownload}
            onClick={handleCarouselExport}
            data-tooltip-id="export-carousel"
            data-tooltip-content="Export Data"
          />
          <Tooltip id="export-carousel" />
        </div>
      </div>

      <div className={styles["sliderContainer"]}>
        {currentPage > 0 && (
          <button
            className={`${styles["handle"]} ${styles["left"]}`}
            onClick={onLeftHandleClick}
          >
            <div className={styles["handleText"]}>&#8249;</div>
          </button>
        )}
        <div className={styles["carouselSlider"]} style={translateStyle}>
          {props.assetData.map((data, idx) => {
            return (
              <ThumbnailCard
                key={
                  data.Filename +
                  "_" +
                  data.Platform +
                  "_" +
                  data.CPM +
                  "_" +
                  data.Spend +
                  "_" +
                  data.Platform +
                  data.Placement
                }
                border={true}
                asset={{ ...data }}
                sort={props.sort}
                onClick={props.onClick}
                thumbnailsPerPage={props.thumbnailsPerPage}
                hidden={
                  getPageNumber(idx, props.thumbnailsPerPage) - 1 <
                    currentPage - 1 ||
                  getPageNumber(idx, props.thumbnailsPerPage) - 1 >
                    currentPage + 1
                }
                maxHeight={props.maxHeight ? props.maxHeight : "200px"}
                fontSize={".75rem"}
              />
            );
          })}
        </div>
        {currentPage < NUM_PAGES && (
          <button
            className={`${styles["handle"]} ${styles["right"]}`}
            onClick={onRightHandleClick}
          >
            <div className={styles["handleText"]}>&#8250;</div>
          </button>
        )}
      </div>
      <ProgressSlider
        numAssets={props.assetData.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        num_pages={NUM_PAGES + 1}
        thumbnailsPerPage={props.thumbnailsPerPage}
      />
    </div>
  );
};

export default Carousel;
