import styles from "./OrClause.module.css";

import Clause from "./Clause";

import { v4 as uuidv4 } from "uuid";
import { Fragment } from "react";

const OrClause = (props) => {


  function AndLabel(props){
    return (<div className={styles["andLabel"]}>
      
  
    <div style = {{width: `${props.length}px`, height: `${props.height}px`, "borderLeft": "1px solid black"}}></div>
    <div style = {{"width":`${props.length-10}px`, height:`${props.height}px` }}></div>
    <div style = {{"position":"absolute", "top":`4.5px`, "right":"10px", "color":"rgb(255, 140, 0)"}}>AND</div>        
    <div style = {{width: `${props.length}px`, height: `${props.height}px`, "borderLeft": "1px solid black"}}></div>
    
    
    </div>);
  }
  const handleANDbuttonClick = () => {
    props.setFilterState((prevState) => {
      return {
        ...prevState,
        orClauses: [...prevState.orClauses,  {
            id: uuidv4(),
            clauses: [
              {
                id: uuidv4(),
                clause_data: {
                  field: "",
                  comparisonType: "",
                  comparisonValue: "",
                  comparisonValue2: "",
                  isValid: false, 
                },
              },
            ],
          },],
      };
    });
  };

  return (
    <Fragment>
      <div className={styles["or-group-container"]}>
        {Object.values(props.clauses).map((clause, idx) => {
          return (
            <Clause
              id={clause.id}
              clause={clause.clause_data}
              parentid={props.id}
              setFilterState={props.setFilterState}
              isLast={idx + 1 === props.clauses.length}
              key={clause.id}
              categoricalOptions = {props.categoricalOptions}
            />
          );
        })}
        {!props.isLast && <AndLabel length = {20} height = {7}/>}
      </div>
      
      {props.isLast && (
        <button className={styles.andButton} onClick={handleANDbuttonClick}>
          AND
        </button>
      )}
    </Fragment>
  );
};

export default OrClause;
