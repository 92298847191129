import styles from "./GroupMenu.module.css";
import Select from "react-select";
import SortDirection from "./SortDirection";
const GroupMenu = (props) => {
  return (
    <div className={styles["GroupMenu"]}>
      <span>Group</span>
      <div className={styles["groupSelect"]}>
        <Select
          options={props.groupByOptions}
          onChange={props.handleGroupChange}
          defaultValue={{ value: "Campaign", label: "Campaign" }}
          menuPortalTarget={document.querySelector('body')}
        />
      </div>
    </div>
  );
};
export default GroupMenu;
