import styles from "./PlatformIcon.module.css";
import { Fragment } from "react";

const PlatformIcon = (props) => {
  let platformIconFile = "/icons/default-icon.png";

  switch (props.platform.toLowerCase()) {
    case "tiktok":
    case "tiktok-influencer":
    case "tiktok - topview":
      platformIconFile = "/icons/tiktok-icon.png";
      break;
    case "meta":
    case "meta - influencer":
      platformIconFile = "/icons/meta-icon.png";
      break;
    case "pinterest":
      platformIconFile = "/icons/pinterest-icon.png";
      break;
    case "google":
      platformIconFile = "/icons/google-icon.png";
      break;
    case "mobile_video":
    case "tremor":
      platformIconFile = "/icons/mobile_video-icon.png";
      break;
    case "fb/ig":
      platformIconFile = "/icons/facebook-icon.png";
      break;
    case "instagram":
      platformIconFile = "/icons/instagram-icon.png";
      break;
    case "youtube":
      platformIconFile = "/icons/youtube-icon.png";
      break;
    case "snapchat":
      platformIconFile = "/icons/snapchat-icon.png";
      break;
    case "cluep":
      platformIconFile = "/icons/cluep-icon.png";
      break;
    case "twitch":
      platformIconFile = "/icons/twitch-icon.png";
      break;
  }

  return (
    <Fragment>
      <img
        style={{
          width: props.width,
          height: props.height,
        }}
        src={platformIconFile}
      />
    </Fragment>
  );
};

export default PlatformIcon;
