import styles from "./AddFilterModal.module.css";

import AddFilter from "./AddFilter";


import { Fragment } from "react";

function AddFilterModal(props) {
  
  return (
    <Fragment>
      <div className={styles["backdrop"]} onClick={props.onClose}></div>
      <AddFilter
        className={styles["modal"]}        
        onAdd={props.onAdd}
        FIELD_TYPES={props.FIELD_TYPES}
        FILTER_TYPES={props.FILTER_TYPES}
        categoricalOptions={props.categoricalOptions}
        options={props.options}
        onClose = {props.onClose}
        initialFilter = {props.initialFilter}
      />
    </Fragment>
  );
}

export default AddFilterModal;
