import styles from "./AssetMetric.module.css";

import { formatMetric } from "../../../../Util/util";
import { computeComparisonBound } from "../../../../Util/util";
const AssetMetric = (props) => {
  const upperBound = computeComparisonBound(
    props.value,
    props.comparison,
    props.rand
  );

  return (
    <div className={styles["asset-metric"]}>
      <div className={styles["header"]}>{props.title}</div>

      <div className={styles["container"]}>
        <div className={styles["metric"]}>
          {Number.isNaN(props.value)
            ? "N/A"
            : formatMetric(props.value, props.metric)}
        </div>

        <div className={styles["comparison-bar-graph"]}>
          <div
            className={`${styles["bar"]} ${styles['color-primary']}`}
            style={{ height: `${(props.value / upperBound) * 100}%` }}
            data-before={formatMetric(props.value, props.metric)}
          ></div>
          <div
            className={`${styles["bar"]} ${styles['color-dark']}`}
            style={{ height: `${(props.comparison / upperBound) * 100}%` }}
            data-before={formatMetric(props.comparison, props.metric)}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default AssetMetric;
