import styles from "./SortableFormatTable.module.css";

import { TABLE_DTYPES } from "../../constants";
import { useState } from "react";
import { dynamicSortMultiple } from "../../Util/sort";
import { formatData } from "../../Util/util";
const UPARROW = "\u{25B2}";
const DOWNARROW = "\u{25BC}";
function SortableFormatTable({
  columns,
  data,
  lastRow,
  lastRowBG = "#AAA",
  lastRowTitle,
  backgroundColors,
  rowKeyAccessors,
  defaultSort = { accessor: null, order: 1 },
}) {
  const [sort, setSort] = useState(defaultSort);

  const handleSortChange = (accessor) => {
    if (accessor === sort.accessor) {
      setSort((prevState) => {
        return {
          ...prevState,
          order: -1 * prevState.order,
        };
      });
    } else {
      setSort({ accessor: accessor, order: 1 });
    }
  };

  const sortedData =
    sort.accessor !== null
      ? data.sort(dynamicSortMultiple([sort.accessor, sort.order]))
      : data;

  return (
    <table className = {styles.table}>
      <thead>
        <tr>
          {columns.map(({ label, accessor, sortable }) => {
            return (
              <th
                key={accessor + label}
                className={sortable ? styles.sortable : ""}
                onClick={
                  sortable
                    ? () => {
                        handleSortChange(accessor);
                      }
                    : null
                }
                style={
                  sort.accessor === accessor
                    ? { color: "var(--clr-primary-dark)" }
                    : null
                }
              >
                {label.length === 1
                  ? label
                  : label.map((item, itemIdx) => {
                      return (
                        <span>
                          {item} {itemIdx < label.length - 1 && <br />}
                        </span>
                      );
                    })}
                <span
                  style={{
                    verticalAlign: "-1px",
                    marginLeft: "2px",
                    visibility:
                      sort.accessor === accessor ? "visible" : "hidden",
                  }}
                >
                  {sort.accessor === accessor
                    ? sort.order === 1
                      ? UPARROW
                      : DOWNARROW
                    : UPARROW}
                </span>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className={styles.tbody}>
        {sortedData.map((row, index) => (
          <tr
            key={rowKeyAccessors.map((accessor) => row[accessor]).join(" ") }
            style={{ backgroundColor: backgroundColors ? backgroundColors[index] : null}}
          >
            {columns.map(
              ({ label, accessor, callback, format, keys, style }) => {
                return (
                  <td key={accessor + callback} style={style ? style : {}}>
                    {callback
                      ? callback(row[accessor])
                      : format
                      ? formatData(
                          row[accessor],
                          format.format,
                          format.precision
                        )
                      : row[accessor]}
                  </td>
                );
              }
            )}
          </tr>
        ))}
        {lastRow ?
          lastRow.map((row, index) => (
            <tr
              key={rowKeyAccessors.map((accessor) => row[accessor]).join(" ")}
              style={{ backgroundColor: lastRowBG }}
            >
              {columns.map(
                ({ label, accessor, callback, format, keys, style }, index) => {
                  return (
                    <td
                      key={accessor}
                      style={style ? style : { backgroundColor: lastRowBG }}
                    >
                      {index === 0
                        ? lastRowTitle
                        : callback
                        ? callback(row)
                        : format
                        ? formatData(
                            row[accessor],
                            format.format,
                            format.precision
                          )
                        : row[accessor]}
                    </td>
                  );
                }
              )}
            </tr>
          )) : <tr></tr>} 
      </tbody>
    </table>
  );
}

export default SortableFormatTable;
