import styles from "./AssetMetricsContainer.module.css";

import AssetMetric from "./AssetMetric";

import { getComparisonValue } from "../../../../Util/util";
import { useState } from "react";
import Select from "react-select";
import * as Constants from "../../../../constants";
const RANDS = [10.45, 11.2, 12.634, 11.78, 10.792];
const AssetMetricsContainer = (props) => {
  const [comparisonOption, setComparisonOption] = useState("Overall");

  return (
    <div className={styles["container"]}>
      <div style={{ width: "100%" }}>
        <div className={styles["select-legend-header"]}>
          <div className={styles["comparison-select-container"]}>
            <span>Compare to:</span>
            <Select
              styles={Constants.SELECT_STYLE("auto")}
              options={Constants.COMPARISON_DROPDOWN_OPTIONS()}
              onChange={(selectedOption) => {
                setComparisonOption(selectedOption.value);
              }}
              defaultValue={{ value: "Overall", label: "Overall" }}
            />
          </div>
          <div className={styles["legend"]}>
            <div
              className={`${styles["legend-bar"]} ${styles["color-primary"]}`}
            />
            Current Asset
            <div
              className={`${styles["legend-bar"]} ${styles["color-dark"]}  ${styles["left-margin"]}`}
            />
            {comparisonOption === "Overall"
              ? "Overall"
              : props.asset[comparisonOption]}
          </div>
        </div>
      </div>
      <div className={styles["metric-row-container"]}>
        <div className={styles["assetMetricsRow"]}>
          <AssetMetric
            value={props.asset.CPM}
            comparison={getComparisonValue(
              props.asset,
              props.filteredData,
              "CPM",
              comparisonOption
            )}
            rand={RANDS[0]}
            metric={"CPM"}
            title={"Cost per 1,000 Impressions (CPM)"}
          />

          <AssetMetric
            value={props.asset.CTR * 100}
            comparison={
              getComparisonValue(
                props.asset,
                props.filteredData,
                "CTR",
                comparisonOption
              ) * 100
            }
            rand={RANDS[1]}
            metric={"CTR"}
            title={"Click-Through Rate (CTR)"}
          />

          <AssetMetric
            value={props.asset.CPC}
            comparison={getComparisonValue(
              props.asset,
              props.filteredData,
              "CPC",
              comparisonOption
            )}
            metric={"CPC"}
            title={"Cost per Click (CPC)"}
            rand={RANDS[2]}
          />
        </div>
        <div className={styles["assetMetricsRow"]}>
          <AssetMetric
            value={props.asset.VCR * 100}
            comparison={
              getComparisonValue(
                props.asset,
                props.filteredData,
                "VCR",
                comparisonOption
              ) * 100
            }
            rand={RANDS[3]}
            metric={"VCR"}
            title={"Video Completion Rate (VCR)"}
          />

          <AssetMetric
            value={props.asset.CPCV * 100}
            comparison={
              getComparisonValue(
                props.asset,
                props.filteredData,
                "CPCV",
                comparisonOption
              ) * 100
            }
            rand={RANDS[3]}
            metric={"CPCV"}
            title={"Cost per Completed View (CPCV)"}
          />
        </div>
      </div>
    </div>
  );
};

export default AssetMetricsContainer;
