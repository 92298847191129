import styles from "./StackedBarChartContainer.module.css";
import "chartjs-plugin-datalabels";
import React from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { processDate } from "../../../Util/util";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const stackedLabels = [
  "Fragile Identified",
  "Fragile Nurtured",
  "Fragile Engaged",
  ["Total Canadians in Our", "Dairy Journey Ecosystem"],
];

function StackedBarChartContainer(props) {

  
  const datasets = [
    {
      label: processDate(props.data[1]["Date"],false),
      data: [
        props.data[1]["Fragile Identified"],
        props.data[1]["Fragile Nurtured"],
        props.data[1]["Fragile Engaged"],
        props.data[1]["Total"],
      ],
      backgroundColor: "rgba(159, 197, 232,0.8)",
      datalabels: {
        display: false,
      },
    },
    {
      label: processDate(props.data[0]["Date"],false),
      data: [
        props.data[0]["Fragile Identified"] -
          props.data[1]["Fragile Identified"],
        props.data[0]["Fragile Nurtured"] - props.data[1]["Fragile Nurtured"],
        props.data[0]["Fragile Engaged"]-props.data[1]["Fragile Engaged"],
        props.data[0]["Total"] -  props.data[1]["Total"],
      ],
      backgroundColor: "rgba(43, 121, 190,0.8)",
      datalabels: {
        color: "rgba(43, 121, 190,0.8)",

        display: true,
        formatter: (val, context) => {
          return context.dataIndex < 3
            ? `+${val.toLocaleString("en-US")} Fragiles`
            : `+${val.toLocaleString("en-US")} Canadians`;
        },
        font: {
          weight: "bold",
          size: "18px",
        },
      },
    },
  ];

  const options = {
    plugins: {
      title: {
        display: true,
        text: props.title,
        font: {
          size: 22,
        },
      },
      datalabels: {
        display: true,
        color: "black",
        align: "end",
        anchor: "end",
      },
    },

    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        stacked: true,
        grid: { display: false },
        ticks: {
          font: {
            weight: "bold",
          },
        },
      },
      y: {
        stacked: true,
        
      },
    },
  };

  const data = {
    labels: stackedLabels,
    datasets: datasets,
  };

  return (
    <div className={styles.barChartContainer}>
      <Bar width={"100%"}
        height = {"56.25%"} options={options} data={data} plugins={[ChartDataLabels] } />
    </div>
  );
}

export default StackedBarChartContainer;
