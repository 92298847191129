import AWS from "aws-sdk";

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

export const getObject = async (key = "assetData.json") => {
  const params = {
    Bucket: process.env.REACT_APP_BUCKET_NAME,
    Key: key,
  };
  const response = await s3.getObject(params).promise();
  return response;
};

export const getSignedUrl = async (key, filename) => {
  const params = {
    Bucket: process.env.REACT_APP_BUCKET_NAME,
    Key: key,
    Expires: 10,
    ResponseContentDisposition: `attachment; filename="${filename}"`,
  };
  const url = s3.getSignedUrl("getObject", params);

  return url;
};

export const uploadObject = async (key, body) => {
  const params = {
    Bucket: process.env.REACT_APP_BUCKET_NAME,
    Key: key,
    Body: body,
  };
  const response = await s3.upload(params).promise();
  return response;
};

export const getObjectUrl = async (src) => {
  const params = {
    Bucket: process.env.REACT_APP_BUCKET_NAME,
    Key: src,
  };
  const response = await s3.getObject(params).promise();
  // @ts-ignore
  const url = URL.createObjectURL(new Blob([response.Body]));
  return url;
};
