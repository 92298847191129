import styles from "./MainNavigation.module.css";
import AWSLogout from "../Auth/AWSLogout";
import { withAuthenticator } from "@aws-amplify/ui-react";

import { Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";


const TITLES = {
  "/" : "Executive Summary",
  "/creativeanalysis" : "Creative Analysis",
  "/assetlibrary" : "Asset Library", 
  "/journeys" : "Journey Analytics",
  "/admin" : "Admin Page",
  "/daisy"  : "Daisy",
  "/regenerativeag" : "Regenerative Agriculture",
  "/Q3Media" : "Q3 Media Reporting",
  "/livejourneys" : "Journeys"
}


const MainNavigation = ({ signOut, user }) => {

  const {pathname}  = useLocation();
  const session = user.getSignInUserSession();
  if (!session) throw new Error("SignInSession is empty!");
  const accessToken = session.getAccessToken();
  const groups = accessToken.payload["cognito:groups"] || [];
  const isAdmin = groups.includes("admin");

  return (
    <div className={styles["header"]}>
      <div className={styles["titleLogoContainer"]}>
        <img src={"./icons/dfc-logo.png"} className={styles["mainLogo"]} />
        <div style = {{display: "flex", flexDirection:"column"}}>
        <p className={styles["titleText"]}>{`Dairy Insights`}</p>
        <p className={styles["subTitleText"]}>{`${TITLES[pathname]}`}</p>
        </div>
      </div>

      <div style={{ display: "flex", marginLeft: "auto", justifyContent: "flex-end" }}>
        <ul className={styles.navBar}>
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
            >
              Executive Summary
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/assetlibrary"
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
            >
              Asset Library
            </NavLink>
          </li>
    
          <li>
            <NavLink
              to="/journeys"
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
            >
              Journeys
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/livejourneys"
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
            >
              Live Journeys
            </NavLink>
          </li>
          
          <li>
            <NavLink
              to="/regenerativeag"
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
            >
              2023 Q2 - Regenerative Agriculture
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/creativeanalysis"
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
            >
              Creative Analysis
            </NavLink>
          </li>
          {
          <li>
            <NavLink
              to="/Q3Media"
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
            >
              Q3 Media
            </NavLink>
          </li>}
          {isAdmin && 
          <li>
            <NavLink
              to="/admin"
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
            >
              Admin
            </NavLink>
          </li>}
        </ul>
      </div>
      <div style={{ width: "auto", margin: "auto 5px auto 0" }}>
        <AWSLogout signOut={signOut} user={user} />
      </div>
    </div>
  );
};

export default withAuthenticator(MainNavigation, { hideSignUp: true });
