import styles from "./AssetDescription.module.css";

import { formatMetric, processDate } from "../../../../Util/util";
import { Fragment } from "react";
const AssetDescription = (props) => {
  return (
    <div className={styles["assetDescriptionContainer"]}>
      <ul className={styles["assetDescription"]}>
        <li>
          <span>Campaign:</span> {props.Campaign}
        </li>
        <li>
          <span>LOB:</span> {props.LOB}
        </li>
        <li>
          <span>Date:</span>{" "}
          {`${processDate(props["Start Date"])} - ${processDate(
            props["End Date"]
          )}`}
        </li>
        <li>
          <span>Platform:</span> {props.Platform}{" "}
          {props.Placement != "default" ? `(${props.Placement})` : null}
        </li>
        <li>
          <span>Length:</span>{" "}
          {props["Asset Type"] === "Video" ? props.Length + "s" : "Static"}
        </li>
        <li>
          <span>Aspect Ratio:</span> {props["Aspect Ratio"]}
        </li>
        <li>
          <span>Pillar: </span>
          {props["Pillar"]}
        </li>
      </ul>
      <ul className={styles["assetDescription"]}>
        <li>
          <span>Spend: </span>
          {formatMetric(props.Spend, "Spend", true)}
        </li>
        <li>
          <span>Impressions: </span>
          {formatMetric(props.Impressions, "Impressions", true)}
        </li>
        <li>
          <span>CPM: </span>
          {formatMetric(props.CPM, "CPM", true)}
        </li>
        <li>
          <span>Clicks: </span>
          {formatMetric(props["Link Clicks"], "Link Clicks", true)}
        </li>
        <li>
          <span>CTR: </span>
          {formatMetric(props["CTR"], "CTR", true)}
        </li>
        <li>
          <span>Video Completions: </span>
          {formatMetric(props["Video Completions"], "Video Completions", true)}
        </li>
        <li>
          <span>VCR: </span>
          {formatMetric(props["VCR"], "VCR", true)}
        </li>
      </ul>
      {props['LOB'] === "Daisy" && <ul className={styles["assetDescription"]}>
        <li>
          <span>Paid Profile Visits: </span>
          {formatMetric(props["Paid Profile Visits"], "Paid Profile Visits", true)}
        </li>
        <li>
          <span>CPPV: </span>
          {formatMetric(props["Cost per Profile Visit"], "Cost per Profile Visit", true)}
        </li>
        <li>
          <span>Paid Followers: </span>
          {formatMetric(props["Paid Followers"], "Paid Followers", true)}
        </li>
        <li>
          <span>CPF: </span>
          {formatMetric(props["Cost per Follower"], "Cost per Follower", true)}
        </li>
        <li>
          <span>Likes: </span>
          {formatMetric(props["Likes"], "Likes", true)}
        </li>
        <li>
          <span>Comments: </span>
          {formatMetric(props["Comments"], "Comments", true)}
        </li>
        <li>
          <span>Shares: </span>
          {formatMetric(props["Shares"], "Shares", true)}
        </li>
      </ul>}
    </div>
  );
};

export default AssetDescription;
