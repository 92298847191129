import styles from "./TimeSeriesChart.module.css";

import React, { PureComponent } from "react";
import { formatMetric } from "../../Util/util";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

/*
props:
    data: JSON of data
    yAccessor: 
    yType: "number" or "category"
    xAccesor
    xType
    color
    aspectRatio "width / height"
*/

const CustomTooltip = ({ active, payload, label, metric }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles["customTooltipContainer"]}>
        <p className={styles["customTooltipTitle"]}>{`${label}`}</p>
        <p className={styles["customTooltipLabel"]}>
          {" "}
          {`${payload[0].name}: ${formatMetric(payload[0].value, metric)}`}
        </p>
      </div>
    );
  }
};

const CustomizedLabel = (props) => {
  const { x, y, width, height, fill, value, metric } = props;
  return (
    <text
      x={x + width}
      y={y + height / 2}
      dy={5}
      dx={5}
      fontSize="12"
      fontFamily="sans-serif"
      fill={fill}
      textAnchor="start"
    >
      {formatMetric(value, metric)}
    </text>
  );
};

function onClick(e, group, metric) {}

const TimeSeriesChart = (props) => {
  return (
    <div className={styles.wrapper}>
      <h1>{props.title}</h1>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 50,
          }}
        >
          
          <XAxis dataKey="date" angle="-45" textAnchor="end"  style = {{fontSize: ".65rem", fontWeight: "800"}}   />
          <YAxis />
          <CartesianGrid strokeDasharray="5 5" />
          <Tooltip
            itemSorter={(item) => {
              return item.value * -1;
            }}
          />
          <Legend verticalAlign="top" />
          {props.dataKeys.map((key, index) => (
            <Line
              type="monotone"
              dataKey={key.dataKey}
              dot={false}
              stroke={key.stroke}
              strokeWidth="3"
              activeDot={{ r: 5 }}
              key = {key.dataKey}
            />
          ))} 
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TimeSeriesChart;
