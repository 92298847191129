import styles from "./SortDirection.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpAZ, faArrowDownAZ } from "@fortawesome/free-solid-svg-icons";



const SORT_DIRECTIONS = {
  ASC: 1,
  DESC: 0,
};

const SortDirection = (props) => {
  const title =
    props.direction === SORT_DIRECTIONS.ASC
      ? "Ascending (Low to High)"
      : "Descending (High to Low)";

  return (
    <div
      onClick={props.sortHandler}
      className={styles["sort-toggle"]}
      title={title}
    >
      {" "}
      {props.direction === SORT_DIRECTIONS.ASC ? (
        <FontAwesomeIcon icon={faArrowDownAZ} />
      ) : (
        <FontAwesomeIcon icon={faArrowUpAZ} />
      )}
    </div>
  );
};

export default SortDirection;