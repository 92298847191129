import React, { useState, useEffect } from "react";
import styles from "./DataTest.module.css";
import useGoogleSheets from "use-google-sheets";
import { dynamicSortMultiple } from "../../Util/sort";
import {
  getValueCounts,
  processDate,
  selectOptionsFromArray,
  yyyymmddToLocalDate,
} from "../../Util/util";
//import { getUniqueValues } from "../../Util/groups";
import Select from "react-select";
import * as Constants from "../../constants";

import { v4 as uuidv4 } from "uuid";
import { omit } from "../../Util/util";
import SortableFormatTable from "../../Components/SortableFormattedTable/SortableFormatTable";

const sheetsOptions = [{ id: "Meta" }, { id: "TikTok" }, { id: "Google" }];
const BENCHMARKS = {
  TikTok: {
    Aquisition: {
      CPM: 5.0,
      CPC: 1.67,
      CTR: 0.003,
    },
    "Retargeting Layer 1": {
      CPM: 6.0,
      CPC: 2.0,
      CTR: 0.003,
    },
    "Retargeting Layer 2": {
      CPM: 8.0,
      CPC: 2.67,
      CTR: 0.003,
    },
  },
  Google: {
    Aquisition: {
      CPM: null,
      CPC: 0.75,
      CTR: 0.005,
    },
    "Retargeting Layer 1": {
      CPM: null,
      CPC: 3.0,
      CTR: 0.005,
    },
    "Retargeting Layer 2": {
      CPM: null,
      CPC: 3.0,
      CTR: 0.005,
    },
  },
  Meta: {
    Aquisition: {
      CPM: 8.0,
      CPC: 4.0,
      CTR: 0.002,
    },
    "Retargeting Layer 1": {
      CPM: 25.0,
      CPC: 3.0,
      CTR: 0.003,
    },
    "Retargeting Layer 2": {
      CPM: 40.0,
      CPC: 3.33,
      CTR: 0.005,
    },
  },
};

const COLUMNS = {
  Date: { Meta: "Date", TikTok: "Date", Google: "Date" },
  "Campaign Name": {
    Meta: "Campaign name",
    TikTok: "Campaign name",
    Google: "Campaign name",
  },
  "Campaign ID": {
    Meta: "Campaign ID",
    TikTok: "Campaign ID",
    Google: "Campaign ID",
  },
  "Ad Group Name": {
    Meta: "Ad set name",
    TikTok: "Ad group name",
  },
  "Ad Group ID": {
    Meta: "Ad set ID",
    TikTok: "Ad group ID",
  },
  Cost: { Meta: "Cost", TikTok: "Cost", Google: "Cost" },
  Impressions: {
    Meta: "Impressions",
    TikTok: "Impressions",
    Google: "Impressions",
  },
  Clicks: { Meta: "Link clicks", TikTok: "Clicks", Google: "Clicks" },
  Conversions: {
    Meta: "Landing page views",
    TikTok: "Conversions",
    Google: "Conversions",
  },
};

function getSegment(row) {
  if (
    row["Campaign Name"].includes("Acquisition") ||
    row["Ad Group Name"].includes("Acquisition")
  ) {
    return { layer: "Acquisition", segment: "Fragile Identified" };
  } else if (
    row["Campaign Name"].includes("Retargeting 1") ||
    row["Campaign Name"].includes("Retargeting Phase 1") ||
    row["Ad Group Name"].includes("Retargeting 1") ||
    row["Ad Group Name"].includes("Retargeting Phase 1")
  ) {
    return { layer: "Retargeting Layer 1", segment: "Fragile Nurtured" };
  } else if (
    row["Campaign Name"].includes("Retargeting 2") ||
    row["Campaign Name"].includes("Retargeting Phase 2") ||
    row["Ad Group Name"].includes("Retargeting 2") ||
    row["Ad Group Name"].includes("Retargeting Phase 2")
  ) {
    return { layer: "Retargeting Layer 2", segment: "Fragile Engaged" };
  }

  return "Unknown";
}

function getLanguage(row) {
  if (
    row["Campaign Name"].toLowerCase().includes("english") ||
    row["Ad Group Name"].toLowerCase().includes("english")
  ) {
    return "English";
  } else if (
    row["Campaign Name"].toLowerCase().includes("french") ||
    row["Ad Group Name"].toLowerCase().includes("french")
  ) {
    return "French";
  }
  return "Unknown";
}

function getAudience(row) {
  if (
    (row["Campaign Name"].toLowerCase().includes("m2m") &&
      row["Campaign Name"].toLowerCase().includes("core")) ||
    (row["Ad Group Name"].toLowerCase().includes("m2m") &&
      row["Ad Group Name"].toLowerCase().includes("core"))
  ) {
    return "M2M/CORE";
  } else if (
    row["Campaign Name"].toLowerCase().includes("m2m") ||
    row["Ad Group Name"].toLowerCase().includes("m2m")
  ) {
    return "M2M";
  } else if (
    row["Campaign Name"].toLowerCase().includes("core") ||
    row["Ad Group Name"].toLowerCase().includes("core")
  ) {
    return "CORE";
  }
  return "Unknown";
}

const FILTER_TYPES = {
  EQUALS: "EQUALS",
  CONTAINS: "CONTAINS",
};

const platformColumns = [
  {
    label: ["Platform"],
    accessor: "Platform",
    sortable: true,
    style: { fontSize: ".5rem", minWidth: "100%" },
  },
  {
    label: ["Spend"],
    accessor: "Cost",
    sortable: true,
    format: { format: "$", precision: 2 },
  },
  {
    label: ["Impressions"],
    accessor: "Impressions",
    sortable: true,
    format: { format: "", precision: 0 },
  },
  {
    label: ["Clicks"],
    accessor: "Clicks",
    sortable: true,
    format: { format: "", precision: 0 },
  },
  {
    label: ["CPM"],
    accessor: "CPM",
    sortable: true,
    format: { format: "$", precision: 2 },
  },
  {
    label: ["CPC"],
    accessor: "CPC",
    sortable: true,
    format: { format: "$", precision: 2 },
  },
  {
    label: ["CTR"],
    accessor: "CTR",
    sortable: true,
    format: { format: "%%", precision: 4 },
  },
  {
    label: ["Conversions"],
    accessor: "Conversions",
    sortable: true,
    format: { format: "", precision: 0 },
  },
];

const adGroupColumns = [
  {
    label: ["Campaign Name"],
    accessor: "PlatformCampaignAdGroup",
    callback: (name) => {
      return name.split(" -- ")[1];
    },
    sortable: true,
    style: { fontSize: ".5rem", minWidth: "100%" },
  },
  {
    label: ["Platform"],
    accessor: "PlatformCampaignAdGroup",
    callback: (name) => {
      return name.split(" -- ")[0];
    },
    sortable: true,
    style: { fontSize: ".5rem", minWidth: "100%" },
  },
  {
    label: ["Ad Group"],
    accessor: "PlatformCampaignAdGroup",
    callback: (name) => {
      return name.split(" -- ")[2];
    },
    sortable: true,
    style: { fontSize: ".5rem", minWidth: "100%" },
  },
  {
    label: ["Spend"],
    accessor: "Cost",
    sortable: true,
    format: { format: "$", precision: 2 },
  },
  {
    label: ["Impressions"],
    accessor: "Impressions",
    sortable: true,
    format: { format: "", precision: 0 },
  },
  {
    label: ["Clicks"],
    accessor: "Clicks",
    sortable: true,
    format: { format: "", precision: 0 },
  },
  {
    label: ["CPM"],
    accessor: "CPM",
    sortable: true,
    format: { format: "$", precision: 2 },
  },
  {
    label: ["CPC"],
    accessor: "CPC",
    sortable: true,
    format: { format: "$", precision: 2 },
  },
  {
    label: ["CTR"],
    accessor: "CTR",
    sortable: true,
    format: { format: "%%", precision: 4 },
  },
  {
    label: ["Conversions"],
    accessor: "Conversions",
    sortable: true,
    format: { format: "", precision: 0 },
  },
];

const JOURNEY_OPTIONS = ["2023 - Q3", "2023 - Q2", "2023 - Q1"];

const JOURNEY_CAMPAIGN_FILTERS = {
  "2023 - Q1": [
    {
      accessor: "Campaign Name",
      value: "Q2",
      type: FILTER_TYPES.CONTAINS,
      exclude: true,
    },
    {
      accessor: "Campaign Name",
      value: "Q3",
      type: FILTER_TYPES.CONTAINS,
      exclude: true,
    },
  ],
  "2023 - Q2": [
    {
      accessor: "Campaign Name",
      value: "Q2",
      type: FILTER_TYPES.CONTAINS,
      exclude: false,
    },
  ],
  "2023 - Q3": [
    {
      accessor: "Campaign Name",
      value: "Q3",
      type: FILTER_TYPES.CONTAINS,
      exclude: false,
    },
  ],
};

const aggregateFilters = {
  Overall: [],
  M2M: [
    {
      accessor: "Campaign Name",
      value: "M2M",
      type: FILTER_TYPES.CONTAINS,
      exclude: false,
    },
  ],
  CORE: [
    {
      accessor: "Campaign Name",
      value: "Core",
      type: FILTER_TYPES.CONTAINS,
      exclude: false,
    },
  ],
  English: [
    {
      accessor: "Campaign Name",
      value: "English",
      type: FILTER_TYPES.CONTAINS,
      exclude: false,
    },
  ],
  French: [
    {
      accessor: "Campaign Name",
      value: "French",
      type: FILTER_TYPES.CONTAINS,
      exclude: false,
    },
  ],
};

function getSegmentConversions(data, segment) {
  return data
    .filter((item) => item["Segment"] === segment)
    .reduce((sum, row) => {
      return sum + Number(row.Conversions);
    }, 0);
}
const standardizeMergeColumns = (data, sheetNameColumn, columns) => {
  const newData = [];

  for (const sheetName of Object.keys(data)) {
    for (const row of data[sheetName]) {
      const newRow = {};
      newRow[sheetNameColumn] = sheetName;
      for (const column of Object.keys(columns)) {
        newRow[column] =
          sheetName === "Google" &&
          ["Ad Group Name", "Ad Group ID"].includes(column)
            ? (row[column] = "")
            : row[columns[column][sheetName]];
      }
      
      newRow["Nice Date"] = processDate(row.Date);
      newData.push(newRow);
    }
  }

  for (const [idx, row] of newData.entries()) {
    newData[idx] = {
      ...row,
      PlatformCampaignAdGroup: `${row["Platform"]} -- ${row["Campaign Name"]} -- ${row["Ad Group Name"]}`,
      Segment: getSegment(row).segment,
      Layer: getSegment(row).layer,
      Language: getLanguage(row),
      Audience: getAudience(row),
    };
  }

  
  return newData.sort(dynamicSortMultiple(["Date", 1], ["Platform", 1]));
};

function filterMultiple(data, filters) {
  let filteredData = data;
  if (!filters) {
    return filteredData;
  }
  for (const filter of filters) {
    filteredData = filteredData.filter((item) => {
      if (filter.exclude) {
        return filter.type === FILTER_TYPES.EQUALS
          ? item[filter.accessor] !== filter.value
          : !item[filter.accessor].includes(filter.value);
      } else {
        return filter.type === FILTER_TYPES.EQUALS
          ? item[filter.accessor] == filter.value
          : item[filter.accessor].includes(filter.value);
      }
    });
  }
  return filteredData;
}

function groupByAggregate(data, groupAccessor = null) {
  let groupedData = [];
  if (groupAccessor) {
    const unique = getUniqueValues(data, groupAccessor);

    let aggregates = {};

    for (const key of unique) {
      let group = {
        data: data.filter((item) => item[groupAccessor] === key),
        id: uuidv4(),
      };
      group[groupAccessor] = key ? key : "N/A";
      groupedData.push(group);
    }
  } else {
    
    groupedData = [{ data: data, id: uuidv4() }];
  }


  groupedData = groupedData.map((group) => ({
    ...group,
    Cost: group.data.reduce((sum, row) => {
      return sum + Number(row.Cost);
    }, 0),
    Impressions: group.data.reduce((sum, row) => {
      return sum + Number(row.Impressions);
    }, 0),
    Clicks: group.data.reduce((sum, row) => {
      return sum + Number(row.Clicks);
    }, 0),
    Conversions: group.data.reduce((sum, row) => {
      return sum + Number(row.Conversions);
    }, 0),
  }));

  groupedData = groupedData.map((group) => ({
    ...group,

    CPM:
      group["Impressions"] > 0
        ? (group["Cost"] / group["Impressions"]) * 1000
        : null,
    CPC: group["Clicks"] > 0 ? group["Cost"] / group["Clicks"] : null,
    CTR:
      group["Impressions"] > 0 ? group["Clicks"] / group["Impressions"] : null,
  }));

  groupedData = groupedData.map((group) => omit(group, "data"));
  return groupedData;
}

/*
function groupByMultipleAggregate(data, groupAccessors = []) {
  let groupedData = [];
  if (groupAccessors.length > 0 ) {
    const unique = getUniqueValues(data, groupAccessor);

    let aggregates = {};

    for (const key of unique) {
      let group = {
        data: data.filter((item) => item[groupAccessor] === key),
        id: uuidv4(),
      };
      group[groupAccessor] = key ? key : "N/A";
      groupedData.push(group);
    }
  } else {
    //console.log("POOP");
    //console.log(data);
    groupedData = [{ data: data, id: uuidv4() }];
  }

  //console.log(groupedData);
  groupedData = groupedData.map((group) => ({
    ...group,
    Cost: group.data.reduce((sum, row) => {
      return sum + Number(row.Cost);
    }, 0),
    Impressions: group.data.reduce((sum, row) => {
      return sum + Number(row.Impressions);
    }, 0),
    Clicks: group.data.reduce((sum, row) => {
      return sum + Number(row.Clicks);
    }, 0),
    Conversions: group.data.reduce((sum, row) => {
      return sum + Number(row.Conversions);
    }, 0),
  }));

  groupedData = groupedData.map((group) => ({
    ...group,

    CPM:
      group["Impressions"] > 0
        ? (group["Cost"] / group["Impressions"]) * 1000
        : null,
    CPC: group["Clicks"] > 0 ? group["Cost"] / group["Clicks"] : null,
    CTR:
      group["Impressions"] > 0 ? group["Clicks"] / group["Impressions"] : null,
  }));

  groupedData = groupedData.map((group) => omit(group, "data"));
  return groupedData;
}



*/

function aggregate(data) {
  let aggregates = {};

  aggregates = {
    ...aggregates,
    Cost: data.reduce((sum, row) => {
      return sum + Number(row.Cost);
    }, 0),

    Impressions: data.reduce((sum, row) => {
      return sum + Number(row.Impressions);
    }, 0),
    Clicks: data.reduce((sum, row) => {
      return sum + Number(row.Clicks);
    }, 0),
    Conversions: data.reduce((sum, row) => {
      return sum + Number(row.Conversions);
    }, 0),
  };
  aggregates = {
    ...aggregates,
    CPM:
      aggregates["Impressions"] > 0
        ? (aggregates["Cost"] / aggregates["Impressions"]) * 1000
        : null,
    CPC:
      aggregates["Clicks"] > 0
        ? aggregates["Cost"] / aggregates["Clicks"]
        : null,
    CTR:
      aggregates["Impressions"] > 0
        ? aggregates["Clicks"] / aggregates["Impressions"]
        : null,
  };

  return aggregates;
}

function makePieChartData(aggregateData, labelAccessor, valueAccessor) {
  let pieData = [];

  for (const data of aggregateData) {
    pieData.push({ name: data[labelAccessor], value: data[valueAccessor] });
  }
  return pieData;
}



const DataTest = () => {
  const { data, loading, error } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_JOURNEYS_GOOGLE_SHEETS_ID,
    sheetsOptions,
  });

  const [journeyData, setJourneyData] = useState([]);
  const [selectedJourney, setSelectedJourney] = useState(JOURNEY_OPTIONS[0]);
  
  useEffect(() => {
    if (!loading) {
      const tempData = {};
      for (const obj of data) {
        const newData = [];
        for (const row of obj.data) {
          const newRow = {
            ...row,
            Date: yyyymmddToLocalDate(row["Date"]),
          };
          newData.push(newRow);
        }
        tempData[obj.id] = newData;
      }

      setJourneyData(standardizeMergeColumns(tempData, "Platform", COLUMNS));
    }
  }, [loading]);

  const [aggregatedData, setAggregatedData] = useState(null);
  const filteredData =
    journeyData.length > 0
      ? filterMultiple(journeyData, JOURNEY_CAMPAIGN_FILTERS[selectedJourney])
      : [];

  const layerAggregates = journeyData
    ? groupByAggregate(journeyData, "Layer")
    : null;

  useEffect(() => {
    if (journeyData.length > 0) {
      const tempAggData = {};

      for (const key of Object.keys(aggregateFilters)) {
        tempAggData[key] = aggregate(
          filterMultiple(journeyData, [
            ...JOURNEY_CAMPAIGN_FILTERS[selectedJourney],
            ...aggregateFilters[key],
          ])
        );
      }
      setAggregatedData(tempAggData);
    }
  }, [journeyData, selectedJourney]);
  /* console.log(journeyData);
  console.log(groupByAggregate(journeyData, "Campaign Name"));
  console.log(groupByAggregate(filteredData));

  console.log(aggregatedData);

  console.log(selectedJourney);
  console.log("Campaigns");
  console.log(getUniqueValues(filteredData, "Campaign Name"));

  console.log("Ad Groups");
  console.log(getUniqueValues(filteredData, "Ad Group Name"));
  console.log(getUniqueValues(filteredData, 'Segment'))
  console.log("TEST")
  console.log(getValueCounts(filteredData, 'Segment'))
  console.log(getValueCounts(filteredData, 'Audience'))
  console.log(getValueCounts(filteredData, 'Language'))
  console.log(getSegmentConversions(filteredData, 'Fragile Identified'))*/

  // const aggregatedData =
  //  filteredData.length > 0 ? aggregateFiltersaggregate(filteredData) : null;

  /*

  function getPlatformLayerAggregates(filteredData){
    const metrics = ["CPM", "CPC", "CTR"]
    if(filteredData.length === 0){
      return [];
    }
    const platforms = getUniqueValues(filteredData, 'Platform');

    
    for(const aggData of aggregated){
      const platformData = {
        adsets: [],
        platformName: aggData.Platform
      }
      for(const layer of Object.keys(BENCHMARKS)){
        console.log(layer)
      }
      
    }
    return [];
  }
  getPlatformLayerAggregates(filteredData)*/
  
  const gbmTest = groupByMultiple(journeyData, ['Platform', "Audience"]);
  
  const mainMetrics = [
    { accessor: "Cost", title: "Spend", format: "$", precision: 2 },
    { accessor: "Impressions", title: "Impressions", format: "", precision: 0 },
    { accessor: "Clicks", title: "Clicks", format: "", precision: 0 },
    { accessor: "CPM", title: "CPM", format: "$", precision: 2 },
    { accessor: "CPC", title: "CPC", format: "$", precision: 2 },
    { accessor: "CTR", title: "CTR", format: "%%", precision: 2 },
    { accessor: "Conversions", title: "Conversions", format: "", precision: 0 },
  ];

  //console.log(aggregatedData);

  /*if (journeyData.length > 0) {
    console.log(getUniqueValues(journeyData, "Platform"));
    console.log(groupByMultiple(journeyData, ["Platform", "Campaign Name"]));
  }*/

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error!</div>;
  }

  return (
    <div className="w-full mt-0 mx-auto flex flex-col justify-center items-center mb-20">
      Data Test
    </div>
  );
};

export default DataTest;

/*<fieldset className="bg-gray-200 rounded-xl w-[95%] mx-auto border border-solid border-black p-2 flex flex-col">
          <legend align="center" className="font-semibold text-lg">
            Overall
          </legend>
          <div className=" flex justify-evenly items-center">
            {false &&
              aggregatedData &&
              mainMetrics.map((metric) => (
                <ScoreCard
                  title={metric.title}
                  content={aggregatedData[metric.accessor]}
                  format={metric.format}
                  precision={metric.precision}
                  key={metric.accessor}
                />
              ))}
          </div>
        </fieldset>*/

function filterData(data, column, value) {
  const keys = data.flatMap(Object.keys);

  if (!keys.includes(column)) {
    throw new Error(
      `Error in filterData: The column ${column} does not exist as a valid key in the passed data.`
    );
  }

  return data.filter((item) => item[column] === value);
}

/*function filterMultiple2(data, columns, values) {
  const keys = data.flatMap(Object.keys);

  if (!keys.includes(column)) {
    throw new Error(
      `Error in filterData: The column ${column} does not exist as a valid key in the passed data.`
    );
  }
}*/

function groupByMultiple(data, columns) {


  if (!Array.isArray(columns)) {
    columns = [columns];
  }

  const keys = data.flatMap(Object.keys);

  let groups = [data]
  for (const column of columns) {
    const tempGroups = []
    for(const group of groups){
      
      tempGroups.push(groupBy(group, column))
    }
    groups = tempGroups.flat();
  }

  return groups;
 
}

export function getUniqueValues(data, accessor) {
  if (data.length > 0) {
    const keys = data.flatMap(Object.keys);

    if (!keys.includes(accessor)) {
      throw new Error(
        `Error in getUnique: The key ${accessor} does not exist as a valid accessor in data.`
      );
    }

    const unique = Array.from(new Set(data.map((item) => item[accessor])))
      .sort()
      .reverse();
    return unique;
  }
  return [];
}


function groupBy(data, column) {
  if (!data || data.length === 0) {
    return [];
  }

  const keys = data.flatMap(Object.keys);

  if (!keys.includes(column)) {
    throw new Error(
      `Error in getUnique: The key ${column} does not exist as a valid column in data.`
    );
  }

  const unique = getUniqueValues(data, column);
 
  if(unique.length === 0){
    return data;
  }
  else{
    const groups = [];
    for(const value of unique){
      groups.push(data.filter((item) => item[column]===value))
    }
    return groups;
  }

  
}
