import styles from "./FunnelCard.module.css";
import Funnel from "./Funnel";
import { formatData, processDate } from "../../../Util/util";
import ChangeArrow from "./ChangeArrow";
function DataCard(props) {
  return (
    <div className={styles.dataCard}>
      <p className={styles.dataCardTitle}>{props.title}</p>
      <div style={{ display: "flex", placeItems: "center" }}>
        <p className={styles.dataCardNumber}>{props.data}</p>
        {props.showChange && (
          <ChangeArrow
            oldValue={props.oldValue}
            newValue={props.newValue}
            metric={"CPCJ"}
            precision={2}
          />
        )}
      </div>
      {props.showChange && (
        <div style={{ fontSize: ".5rem", color: "black", marginTop: "-5px" }}>
          (Since {props.prevDate})
        </div>
      )}
    </div>
  );
}

function FunnelCard(props) {
  const prevData = props.prevData
    ? props.prevData.data.filter((item) => {
        return item.Name === props.Name;
      })[0]
    : { Date: props.Date, "Amount Spent": props["Amount Spent"], 
  "Fragile Identified": props['Fragile Identified'], 
  "Fragile Nurtured": props['Fragile Nurtured'], 
  "Fragile Engaged": props['Fragile Engaged'], };

  const prevCPCJ = prevData["Amount Spent"] / prevData["Fragile Engaged"];
  const prevDate = processDate(prevData[["Date"]]);
  const currentCPCJ = props["Amount Spent"] / props["Fragile Engaged"];

  return (
    <div className={styles.funnelCard}>
      <div className={styles.cardHeader}>{props.Name}</div>

      <div className={styles.cardContainer}>
        <div className={styles.funnelContainer}>
          <Funnel
            layers={[
              {
                layerName: "Fragile Idenitified",
                number: props["Fragile Identified"],
              },
              {
                layerName: "Fragile Nurtured",
                number: props["Fragile Nurtured"],
              },
              {
                layerName: "Fragile Engaged",
                number: props["Fragile Engaged"],
              },
            ]}
            total={props["Fragile Identified"]}
          />
        </div>
        <div className={styles.dataCardContainer}>
          <DataCard
            title={"Amount Spent"}
            data={formatData(props["Amount Spent"], "$", 2)}
            key={props["Amount Spent"] + props.Name}
          />

          <DataCard
            title={"Cost per Completed Journey"}
            data={
              isFinite(currentCPCJ) ? formatData(currentCPCJ, "$", 2) : "N/A"
            }
            oldValue={prevCPCJ}
            newValue={currentCPCJ}
            metric="CPCJ"
            prevDate={prevDate}
            key={"CPCJ" + props.Name}
            showChange={false}
          />
        </div>
      </div>
    </div>
  );
}

export default FunnelCard;
