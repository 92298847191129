import styles from "./TimeSeriesContainer.module.css";
import "chartjs-plugin-datalabels";
import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function TimeSeriesContainer(props) {
  const options = {
    responsive: true,
    maintainaspectratio: false,
    plugins: {
      legend: {
        position: "top",
        reverse: true,
      },
      title: {
        display: true,
        text: props.title,
        font: {
          size: 22,
        },
      },
    },
  };

  const data = {
    labels: props.data.dates,
    datasets: [
      {
        label: props.data.label,
        data: props.data.values,
        borderColor: props.data.borderColor ? props.data.borderColor : "rgb(50, 176, 240)"  ,
        backgroundColor: props.data.backgroundColor ? props.data.backgroundColor : "rgba(50, 176, 240, 0.5)",
        lineTension: 0.5
      },

    ],
  };

  return (
    <div className={styles.timeSeriesContainer}>
      <Line options={options} data={data} />
    </div>
  );
}

export default TimeSeriesContainer;
