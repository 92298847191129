import styles from "./SortMenu.module.css";

import Select from "react-select";
import * as Constants from "../../../../constants";
import SortDirection from "./SortDirection";

const SortMenu = (props) => {
  const customStyles = {
    control: (defaultStyles) => ({
      ...defaultStyles,
      textAlign: "left",
    }),
  };

  return (
    <div className={styles["sortMenu"]}>
      <span>Sort</span>

      <div className={styles["sortSelect"]}>
        <div style={{ textAlign: "left", width: "10%", margin: "0 10px 0 0", "marginRight":"auto", "flexShrink":"1"}}>
          <span style={{ margin: "0" }}>Primary: </span>
        </div>
        <div style={{ "flexGrow": "1","flexShrink":"1", maxWidth: "70%",  }}>
          <Select
            options={[
              { value: "None", label: "None" },
              ...Constants.DROPDOWN_OPTIONS(),
            ]}
            value={{value: props.sort[0][0], label: props.sort[0][0] }}
            onChange={props.handlePrimarySortChange}
            defaultValue={{ value: props.sort[0][0], label: props.sort[0][0] }}
            styles={customStyles}
            menuPortalTarget={document.querySelector('body')}
          />
        </div>
        <div style={{ width: "5%","flexShrink":"1" , display:"flex", justifyContent:"flex-end"}}>
          {props.sort[0][0] !== "None" && (
            <SortDirection
              sortHandler={props.handlePrimarySortDirection}
              direction={props.sort[0][1]}
            />
          )}
        </div>
      </div>
      <div className={styles["sortSelect"]}>
        <div style={{ textAlign: "left", width: "10%", margin: "0 10px 0 0", marginRight:"auto"}}>
          <span >Secondary:</span>
        </div>
        <div style={{ maxWidth: "70%", "flexGrow": "1","flexShrink":"1"}}>
          <Select
            options={[
              { value: "None", label: "None" },
              ...Constants.DROPDOWN_OPTIONS(),
            ]}
            menuPortalTarget={document.querySelector('body')}
            value={{value: props.sort[1][0], label: props.sort[1][0] }}
            onChange={props.handleSecondarySortChange}
            defaultValue={{ value: props.sort[1][0], label: props.sort[1][0] }}
            styles={customStyles}
          />
        </div>
        <div style={{ width: "5%", "flexShrink":"1",  display:"flex"}}>
          {props.sort[1][0] !== "None" && (
            <SortDirection
              sortHandler={props.handleSecondarySortDirection}
              direction={props.sort[1][1]}
              
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SortMenu;
