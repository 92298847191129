import styles from "./HorizontalBarChart.module.css";

import React, { PureComponent } from "react";
import { formatMetric } from "../../Util/util";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

/*
props:
    data: JSON of data
    yAccessor: 
    yType: "number" or "category"
    xAccesor
    xType
    color
    aspectRatio "width / height"
*/

const CustomTooltip = ({ active, payload, label, metric}) => {
  
  if (active && payload && payload.length) {
    return (
      <div className={styles["customTooltipContainer"]}>
        <p className={styles["customTooltipTitle"]}>{`${label}`}</p>
        <p className={styles["customTooltipLabel"]}>
          {" "}
          {`${payload[0].name}: ${formatMetric(payload[0].value, metric)}`}
        </p>
      </div>
    );
  }
};

const CustomizedLabel = (props) => {
  
  const { x, y, width, height, fill, value, metric } = props;
  return (
    <text
      x={x + width}
      y={y + height / 2}
      dy={5}
      dx={5}
      fontSize="12"
      fontFamily="sans-serif"
      fill={fill}
      textAnchor="start"
    >
      {formatMetric(value, metric)}
    </text>
  );
};

function onClick(e, group, metric){
  
}

const HorizontalBarChart = (props) => {
  
  return (
    <div className = {styles.wrapper}>
      <h1>{props.title}</h1>
      <ResponsiveContainer width="100%" height="100%" style = {{overflow: "visible !important"}}>
        <BarChart
          data={props.data}
          layout="vertical"
          margin={{
            top: 5,
            right: props.rightMargin ? props.rightMargin : 25,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="5" horizontal = {false} />
          <XAxis type={props.xType} dataKey={props.xAccessor} />
          <YAxis type={"category"} dataKey={"group"} style = {{fontSize:"10px"}}interval={0} width={props.yAxisWidth ?  props.yAxisWidth : 100} />
          <Tooltip
            content={<CustomTooltip group={props.group} metric = {props.xAccessor}/>}
            cursor={{ fill: "rgba(0,0,0,.05)" }}
          />

          
          <Bar
            dataKey={props.xAccessor}
            fill={props.barFillColor}
            label={<CustomizedLabel metric={props.xAccessor}/>}
            onClick={(e) => {props.setCarouselFilter({
              group: props.group,
              metric: props.xAccessor,
              value: e.group,
            });
            props.setShowCarouselModal(true);}}
          
            cursor = {"pointer"}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HorizontalBarChart;
