import styles from "./BarChartContainer.module.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
    },
  },
  scales: {
    x: {
      grid: { display: false },
    },
  },
};

function BarChartContainer(props) {
  return (
    <div className={styles.barChartContainer}>
      <Bar
        options={{
          ...options,
          plugins: {
            ...options.plugins,
            title: {
              display: true,
              text: props.title,
              align: "center",
            },
            
          },
          hover: {
            onHover: (event, chartElement) => {
              event.target.style.cursor =  'pointer' ;
            }
          },
          scales: {
            ...options.scales,
            y: {
              max: props.yMax,
            },
          },
        }}
        data={props.data}
      />
    </div>
  );
}

export default BarChartContainer;
