import styles from "./ThumbnailIconContainer.module.css";

import PlatformIcon from "./Icons/PlatformIcon";
import LengthIcon from "./Icons/LengthIcon";
import AspectRatioIcon from "./Icons/AspectRatioIcon";
import MetricIcon from "./MetricIcon";
const ThumbnailIconContainer = (props) => {

    


  return props.LOB !== "Daisy" ? (
    <div className={styles["thumbnail-icon-container"]}>
      <MetricIcon value={props.CPM} metric="CPM" />
      <MetricIcon value={props.CPC} metric="CPC" />
      <MetricIcon value={props.CTR*100} metric="CTR" />
      <MetricIcon value={props.VCR*100} metric="VCR" />
      <MetricIcon value={props.CPCV} metric="CPCV" />{" "}
    </div>
  ) : 
    props["Paid/Organic"] === "Paid" ? 
  (
    <div className={styles["thumbnail-icon-container"]}>
      <MetricIcon value={props.CPM} metric="CPM" />
      <MetricIcon value={props["Cost per Profile Visit"]} metric="Cost per Profile Visit" title = "CPPV" />
      <MetricIcon value={props['Cost per Follower']} metric="Cost per Follower" title = "CPF" />
      <MetricIcon value={props.VCR*100} metric="VCR" />
      <MetricIcon value={props.CPCV} metric="CPCV" />{" "}
    </div>
  ) :
  (
    <div className={styles["thumbnail-icon-container"]}>
      <MetricIcon value={props.Views} metric="Views" />
      <MetricIcon value={props["Likes"]} metric="Likes" />
      <MetricIcon value={props['Comments']} metric="Comments"/>
      <MetricIcon value={props.Shares} metric="Shares" />
        
    </div>
  )
};

export default ThumbnailIconContainer;

/*<PlatformIcon platform = {props.Platform} width = "2.0rem" height = "2rem"/>
            <LengthIcon length = {props.Length} />   
            <AspectRatioIcon aspectratio = {props['Aspect Ratio']}/>    */
