import styles from "./FilterMenuItem.module.css";

import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

const FilterMenuItem = (props) => {
  return (
    <div
      className={
        styles["filtermenuitem"] +
        " " +
        (props.isLast ? styles["lastItem"] : "")
      }
    >
      <span className={styles["filterName"]}>{props.text}</span>

      <div className={styles["filteritembuttons"]}>
        <button
          className={styles["filter-button"]}
          onClick={() => props.onEdit(props.id)}
          data-tooltip-id="edit-filter"
          data-tooltip-content="Edit Filter"
        >
          Edit
        </button>

        <div
          className={styles.deleteFilter}
          onClick={() => props.onRemove(props.id)}
        >
          <FontAwesomeIcon
            icon={faCircleXmark}
            data-tooltip-id="delete-filter"
            data-tooltip-content="Delete Filter"
          />
          <Tooltip id="delete-filter" delayShow={1000} />
        </div>
      </div>
    </div>
  );
};

export default FilterMenuItem;
