import styles from "./SettingsMenu.module.css";

import * as Constants from "../../../../constants";

import GroupMenu from "./GroupMenu";
import FilterMenu from "./FilterMenu";
import SortMenu from "./SortMenu";

const SettingsMenu = (props) => {
  // console.log(props)
  return (
    <div className={styles.settingsContainer}>
      <div className={styles["settingsHeader"]}>
        <span className={styles.settingsTitle}>Settings</span>
        {props.showCarouselSize && <fieldset className={styles["carouselSizeFieldset"]}>
          <legend align="center" className={styles["carouselSizeLegend"]}>
            Assets per page: {props.thumbnailsPerPage}
          </legend>
          <div style={{ width: "auto"}}>
            <input
              id="typeinp"
              type="range"
              min="1"
              max="13"
              value={props.thumbnailsPerPage}
              onChange={props.handleThumbnailChange}
              step="1"
              className={styles["slider"]}
            />
          </div>
        </fieldset>}
      </div>
      <div className={styles["filter-container"]}>
        <GroupMenu {...props} />
        <SortMenu {...props} />
        <FilterMenu {...props} FIELD_TYPES={Constants.FIELD_TYPES} headerBackgroundColor = "none" headerTitleColor = "var(--clr-primary-dark)" />
      </div>
    </div>
  );
};

export default SettingsMenu;
