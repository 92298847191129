import styles from "./AssetCard.module.css";

import AssetDescription from "./AssetDescription";
import AssetMetricsContainer from "./AssetMetricsContainer";
import VideoWrapper from "./VideoWrapper.js";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { useEffect } from "react";

import { useState } from "react";

const AssetCard = (props) => {
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        props.onClose();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  
  return (
    <div className={`${styles["asset-card-container"]} ${styles["modal"]}`}>
      <div className={styles["close-button"]}>
        <FontAwesomeIcon
          icon={faXmark}
          size="2x"
          onClick={props.onClose}
          data-tooltip-id="close-asset-modal"
          data-tooltip-content="Close"
        />
        <Tooltip id="close-asset-modal" />
      </div>

      <div className={styles["main-content"]}>
        <header className={styles["asset-title"]}>
          {props.asset.Name}{" "}
          <span
            className={
              props.asset.Language === "English"
                ? styles["language-english"]
                : styles["language-french"]
            }
          >
            ({props.asset.Language === "English" ? "EN" : "FR"})
          </span>
        </header>

        <VideoWrapper {...props.asset} />
        <AssetDescription {...props.asset} />

        <AssetMetricsContainer
          asset={props.asset}
          filteredData={props.filteredData}
        />
      </div>
    </div>
  );
};

export default AssetCard;
