export const SORT_DIRECTION = {
  ASC: 1,
  DESC: -1,
};

export const FIELD_TYPES = {
  CPC: "NUMERIC",
  CPM: "NUMERIC",
  CTR: "NUMERIC",
  Impressions: "NUMERIC",
  Length: "NUMERIC",
  "Link Clicks": "NUMERIC",
  Spend: "NUMERIC",
  VCR: "NUMERIC",
  CPCV: "NUMERIC",
  Likes: "NUMERIC",
  Comments: "NUMERIC",
  Shares: "NUMERIC",

  "Video Completions": "NUMERIC",
  Views: "NUMERIC",
  Comments: "NUMERIC",
  Likes: "NUMERIC",
  Shares: "NUMERIC",
  "Average Watch Time per Video View": "NUMERIC",
  "Paid Followers": "NUMERIC",
  "Paid Profile Visits": "NUMERIC",

  Name: "STRING",
  Text: "STRING",
  "TikTok Video ID": "STRING",

  "Aspect Ratio": "CATEGORICAL",
  "Asset Type": "CATEGORICAL",
  Campaign: "CATEGORICAL",
  LOB: "CATEGORICAL",

  Language: "CATEGORICAL",
  Platform: "CATEGORICAL",
  Placement: "CATEGORICAL",
  Audience: "CATEGORICAL",
  Pillar: "CATEGORICAL",
  Message: "CATEGORICAL",
  "Paid/Organic": "CATEGORICAL",

  "Start Date": "DATE",
  "End Date": "DATE",
};


export const CATEGORICAL_FIELDS = () => {
  let fields = [];

  for (const field in FIELD_TYPES) {
    if (FIELD_TYPES[field] === "CATEGORICAL") {
      fields.push(field);
    }
  }
  return fields;
};
export const AGGREGATE_METRICS = [
  "CPM",
  "CPC",
  "CTR",
  "CPCV",
  "VCR",
  "Spend",
  "Impressions",
  "Link Clicks",
  "Video Completions",
];

export const AGGREGATE_METRICS_DAISY = [
  "CPM",
  "CPC",
  "CTR",
  "CPCV",
  "VCR",
  "Spend",
  "Impressions",
  "Link Clicks",
  "Video Completions",
  "Likes",
  "Comments",
  "Shares",
  "Paid Followers",
  "Cost per Follower",
  "Paid Profile Visits",
  "Cost per Profile Visit",
];

export const AGGREGATE_ORDER = {
  CPM: 1,
  CPC: 1,
  CTR: -1,
  CPCV: 1,
  VCR: -1,
  Spend: -1,
  Impressions: -1,
  "Link Clicks": -1,
  "Video Completions": -1,
};

export const AGGREGATE_ORDER_DAISY = {
  CPM: 1,
  CPC: 1,
  CTR: -1,
  CPCV: 1,
  VCR: -1,
  Spend: -1,
  Impressions: -1,
  "Link Clicks": -1,
  "Video Completions": -1,
  Likes: -1,
  Comments: -1,
  Shares: -1,
  "Paid Followers": -1,
  "Paid Profile Visits": -1,
  "Cost per Follower": 1,
  "Cost per Profile Visit": 1,
};

export const AGGREGATE_GROUPS = [
  "Campaign",
  "Language",
  "Platform",
  "Asset Type",
  "Length Range",
  "Aspect Ratio",
  "Placement",
  "Audience",
  "Pillar",
];

export const AGGREGATE_GROUPS_DAISY = [
  "Campaign",
  "Language",
  "Length Range",
  "Paid/Organic",
];

export const COMPARISON_OPTIONS = [
  "Overall",
  "Campaign",
  "Platform",
  "Aspect Ratio",
  "Language",
  "Length",
  "Placement",
  "Audience",
  "Pillar",
];
export function SELECT_STYLE(minWidth = "200px", height = "30px", controlFontSize = "0.5rem", menuFontSize = "0.75rem") {
  return {
    control: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
      fontSize: controlFontSize,
      width: minWidth,
      minWidth: minWidth,
      textAlign: "center",
      minHeight: height,
      height: height,
      marginLeft: "auto",
    }),
    menu: (base) => ({
      ...base,
      fontSize: menuFontSize,
      textAlign: "center",
      minHeight: height,
      width: minWidth,
      minWidth: minWidth,
      marginLeft: "auto",
      postition: "relative",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: height,
    }),
    dropdownIndicator: (base) => ({
      ...base,
    }),

    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: height,
    }),
  };
}
export let NUMERIC_TYPES = Object.keys(FIELD_TYPES).filter(
  (k) => FIELD_TYPES[k] === "NUMERIC"
);

export let STRING_TYPES = Object.keys(FIELD_TYPES).filter(
  (k) => FIELD_TYPES[k] === "STRING"
);

export let DATE_TYPES = Object.keys(FIELD_TYPES).filter(
  (k) => FIELD_TYPES[k] === "DATE"
);

export function NUMERIC_DROPDOWN_OPTIONS() {
  let options = [];

  for (const item of NUMERIC_TYPES) {
    options.push({ value: item, label: item });
  }

  return options;
}

export function GROUPBY_OPTIONS() {
  let options = [];

  for (const item of Object.keys(FIELD_TYPES)
    .filter((k) => FIELD_TYPES[k] !== "NUMERIC" && FIELD_TYPES[k] !== "DATE")
    .sort()) {
    options.push({ value: item, label: item });
  }
  return options;
}

export function DROPDOWN_OPTIONS() {
  let options = [];

  for (const item of Object.keys(FIELD_TYPES).sort()) {
    options.push({ value: item, label: item });
  }

  return options;
}

export function AGGREGATE_OPTIONS() {
  let options = [];

  for (const item of AGGREGATE_METRICS) {
    options.push({ value: item, label: item });
  }

  return options;
}

export function AGGREGATE_OPTIONS_DAISY() {
  let options = [];

  for (const item of AGGREGATE_METRICS_DAISY) {
    options.push({ value: item, label: item });
  }

  return options;
}

export function COMPARISON_DROPDOWN_OPTIONS() {
  let options = [];

  for (const item of COMPARISON_OPTIONS) {
    options.push({ value: item, label: item });
  }

  return options;
}

export const FILTER_TYPES = {
  EQUAL: -1,
  NOT_EQUAL: 0,
  NUMERIC_GREATER_THAN: 1,
  NUMERIC_GREATER_THAN_OR_EQUAL: 2,
  NUMERIC_LESS_THAN: 3,
  NUMERIC_LESS_THAN_OR_EQUAL: 4,
  NUMERIC_BETWEEN: 5,

  STRING_BEGINS_WITH: 6,
  STRING_ENDS_WITH: 7,
  STRING_CONTAINS: 8,
  STRING_DOES_NOT_CONTAIN: 9,

  DATE_AFTER: 10,
  DATE_ON_OR_AFTER: 11,
  DATE_BEFORE: 12,
  DATE_ON_OR_BEFORE: 13,
  DATE_BETWEEN: 14,
  DATE_EQUAL: 15,
  DATE_NOT_EQUAL: 16,

  CATEGORICAL_EQUAL: 17,
  CATEGORICAL_NOT_EQUAL: 18,
  CATEGORICAL_IS_ONE_OF: 19,
  CATEGORICAL_IS_NOT_ONE_OF: 20,
};

export const FILTER_LABELS = {
  "-1": "EQUAL TO",
  0: "NOT EQUAL TO",
  1: "GREATER THAN",
  2: "GREATER THAN OR EQUAL TO",
  3: "LESS THAN",
  4: "LESS THAN OR EQUAL TO",
  5: "BETWEEN",
  6: "BEGINS WITH",
  7: "ENDS WITH",
  8: "CONTAINS",
  9: "DOES NOT CONTAIN",
  10: "DATE_EQUALS",
  11: "DATE_NOT_EQUALS",
  12: "DATE_AFTER",
  13: "DATE_ON_OR_AFTER",
  14: "DATE_BEFORE",
  15: "DATE_ON_OR_BEFORE",
  16: "DATE_BETWEEN",
};

export const TABLE_DTYPES = {
  STRING : "STRING",
  NUMERIC : "NUMERIC",
  DATE : "DATE"
}

export function invertObject(obj) {
  var retobj = {};
  for (var key in obj) {
    retobj[obj[key]] = key;
  }
  return retobj;
}

export const FILTER_OPTIONS = {
  NUMERIC: [
    {
      value: FILTER_TYPES.EQUAL,
      label: "EQUAL TO",
    },
    {
      value: FILTER_TYPES.NOT_EQUAL,
      label: "NOT EQUAL TO",
    },
    {
      value: FILTER_TYPES.NUMERIC_GREATER_THAN,
      label: "GREATER THAN",
    },
    {
      value: FILTER_TYPES.NUMERIC_GREATER_THAN_OR_EQUAL,
      label: "GREATER THAN OR EQUAL TO",
    },
    {
      value: FILTER_TYPES.NUMERIC_LESS_THAN,
      label: "LESS THAN",
    },
    {
      value: FILTER_TYPES.NUMERIC_LESS_THAN_OR_EQUAL,
      label: "LESS THAN OR EQUAL TO",
    },
    {
      value: FILTER_TYPES.NUMERIC_BETWEEN,
      label: "BETWEEN",
    },
  ],
  STRING: [
    {
      value: FILTER_TYPES.EQUAL,
      label: "EQUAL TO",
    },
    {
      value: FILTER_TYPES.NOT_EQUAL,
      label: "NOT EQUAL TO",
    },
    {
      value: FILTER_TYPES.STRING_BEGINS_WITH,
      label: "BEGINS WITH",
    },
    {
      value: FILTER_TYPES.STRING_ENDS_WITH,
      label: "ENDS WITH",
    },
    {
      value: FILTER_TYPES.STRING_CONTAINS,
      label: "CONTAINS",
    },
    {
      value: FILTER_TYPES.STRING_DOES_NOT_CONTAIN,
      label: "DOES NOT CONTAIN",
    },
  ],
  DATE: [
    {
      value: FILTER_TYPES.DATE_EQUAL,
      label: "EQUAL TO",
    },
    {
      value: FILTER_TYPES.DATE_NOT_EQUAL,
      label: "NOT EQUAL TO",
    },
    {
      value: FILTER_TYPES.DATE_BEFORE,
      label: "BEFORE",
    },
    {
      value: FILTER_TYPES.DATE_ON_OR_BEFORE,
      label: "ON OR BEFORE",
    },
    {
      value: FILTER_TYPES.DATE_AFTER,
      label: "AFTER",
    },
    {
      value: FILTER_TYPES.DATE_ON_OR_AFTER,
      label: "ON OR AFTER",
    },
    {
      value: FILTER_TYPES.DATE_BETWEEN,
      label: "BETWEEN",
    },
  ],

  CATEGORICAL: [
    {
      value: FILTER_TYPES.CATEGORICAL_EQUAL,
      label: "EQUAL TO",
    },
    {
      value: FILTER_TYPES.CATEGORICAL_NOT_EQUAL,
      label: "NOT EQUAL TO",
    },
    {
      value: FILTER_TYPES.CATEGORICAL_IS_ONE_OF,
      label: "IS ONE OF",
    },
    {
      value: FILTER_TYPES.CATEGORICAL_IS_NOT_ONE_OF,
      label: "IS NOT ONE OF",
    },
  ],
};
