import styles from "./Clause.module.css";

import Select from "react-select";
import { Fragment } from "react";
import * as Constants from "../../../../constants";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import makeAnimated from "react-select/animated";

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css//
import "react-datepicker/dist/react-datepicker-cssmodules.css";

const animatedComponents = makeAnimated();

function Clause(props) {
  const handleDeleteClause = (id, parentid) => {
    props.setFilterState((prevState) => {
      prevState = {
        ...prevState,
        orClauses: prevState.orClauses.map((orClause) => {
          return orClause.id === parentid
            ? {
                ...orClause,
                clauses:
                  orClause.clauses.filter((clause) => {
                    return clause.id !== id;
                  }).length === 0
                    ? []
                    : orClause.clauses.filter((clause) => {
                        return clause.id !== id;
                      }),
              }
            : orClause;
        }),
      };

      if (
        Object.keys(
          prevState.orClauses.filter((orClause) => {
            return orClause.id === parentid;
          })[0].clauses
        ).length === 0
      ) {
        prevState = {
          ...prevState,
          orClauses: prevState.orClauses.filter((orClause) => {
            return orClause.id !== parentid;
          }),
        };
      }

      if (Object.keys(prevState.orClauses).length === 0) {
        prevState = {
          id: uuidv4(),
          name: "",
          orClauses: [
            {
              id: uuidv4(),
              clauses: [
                {
                  id: uuidv4(),
                  clause_data: {
                    field: "",
                    comparisonType: "",
                    comparisonValue: "",
                    comparisonValue2: "",
                    isValid: false,
                  },
                },
              ],
            },
          ],
        };
      }

      return prevState;
    });
  };
  const handleFieldChange = (selectedOption) => {
    props.setFilterState((prevState) => {
      return {
        ...prevState,
        orClauses: prevState.orClauses.map((orClause) => {
          return orClause.id === props.parentid
            ? {
                ...orClause,
                clauses: orClause.clauses.map((clause) => {
                  return clause.id === props.id
                    ? {
                        ...clause,
                        clause_data: {
                          field: selectedOption.value,
                          comparisonType: "",
                          comparisonValue: "",
                          comparisonValue2: "",
                          isValid: false,
                        },
                      }
                    : clause;
                }),
              }
            : orClause;
        }),
      };
    });
  };

  const handleFilterChange = (selectedOption) => {
    props.setFilterState((prevState) => {
      return {
        ...prevState,
        orClauses: prevState.orClauses.map((orClause) => {
          return orClause.id === props.parentid
            ? {
                ...orClause,
                clauses: orClause.clauses.map((clause) => {
                  return clause.id === props.id
                    ? {
                        ...clause,
                        clause_data: {
                          ...clause.clause_data,
                          comparisonType: selectedOption,

                          isValid: false,
                        },
                      }
                    : clause;
                }),
              }
            : orClause;
        }),
      };
    });
  };

  const handleORbuttonClick = () => {
    props.setFilterState((prevState) => {
      return {
        ...prevState,
        orClauses: prevState.orClauses.map((orClause) => {
          return orClause.id === props.parentid
            ? {
                ...orClause,
                clauses: [
                  ...orClause.clauses,
                  {
                    id: uuidv4(),
                    clause_data: {
                      field: "",
                      comparisonType: "",
                      comparisonValue: "",
                      comparisonValue2: "",
                      isValid: false,
                    },
                  },
                ],
              }
            : orClause;
        }),
      };
    });
  };

  useEffect(() => {
    const isValid =
      (props.clause.comparisonType.value !==
        Constants.FILTER_TYPES.NUMERIC_BETWEEN &&
        props.clause.comparisonType.value !==
          Constants.FILTER_TYPES.DATE_BETWEEN &&
        props.clause.comparisonValue !== "") ||
      (props.clause.comparisonType.value ===
        Constants.FILTER_TYPES.NUMERIC_BETWEEN &&
        props.clause.comparisonValue !== "" &&
        props.clause.comparisonValue2 !== "" &&
        Number(props.clause.comparisonValue) <=
          Number(props.clause.comparisonValue2)) ||
      (props.clause.comparisonType.value ===
        Constants.FILTER_TYPES.DATE_BETWEEN &&
        props.clause.comparisonValue !== "" &&
        props.clause.comparisonValue2 !== "" &&
        props.clause.comparisonValue <= props.clause.comparisonValue2);

    props.setFilterState((prevState) => {
      return {
        ...prevState,
        orClauses: prevState.orClauses.map((orClause) => {
          return orClause.id === props.parentid
            ? {
                ...orClause,
                clauses: orClause.clauses.map((clause) => {
                  return clause.id === props.id
                    ? {
                        ...clause,
                        clause_data: {
                          ...clause.clause_data,
                          isValid: isValid,
                        },
                      }
                    : clause;
                }),
              }
            : orClause;
        }),
      };
    });
  }, [
    props.clause.comparisonValue,
    props.clause.comparisonValue2,
    props.clause.comparisonType,
    props.clause.field,
  ]);

  const handleComparisonValueChange = (value) => {
    
    props.setFilterState((prevState) => {
      return {
        ...prevState,
        orClauses: prevState.orClauses.map((orClause) => {
          return orClause.id === props.parentid
            ? {
                ...orClause,
                clauses: orClause.clauses.map((clause) => {
                  return clause.id === props.id
                    ? {
                        ...clause,
                        clause_data: {
                          ...clause.clause_data,
                          comparisonValue: value,
                        },
                      }
                    : clause;
                }),
              }
            : orClause;
        }),
      };
    });
  };
  const handleComparisonValueChange2 = (value) => {
    props.setFilterState((prevState) => {
      return {
        ...prevState,
        orClauses: prevState.orClauses.map((orClause) => {
          return orClause.id === props.parentid
            ? {
                ...orClause,
                clauses: orClause.clauses.map((clause) => {
                  return clause.id === props.id
                    ? {
                        ...clause,
                        clause_data: {
                          ...clause.clause_data,
                          comparisonValue2: value,
                        },
                      }
                    : clause;
                }),
              }
            : orClause;
        }),
      };
    });
  };

  function OrLabel(props) {
    return (
      <div className={styles["orLabel"]}>
        <div
          style={{ width: `${props.length}px`, borderTop: "1px solid black" }}
        ></div>
        <div
          style={{
            width: `${props.length}px`,
            height: `${props.height}px`,
            borderLeft: "1px solid black",
          }}
        ></div>
        <div
          style={{
            width: `${props.length - 10}px`,
            height: `${props.height}px`,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            top: `${props.height}px`,
            right: "70%",
            color: "var(--clr-primary-dark)",
          }}
        >
          OR
        </div>
        <div
          style={{
            width: `${props.length}px`,
            height: `${props.height}px`,
            borderLeft: "1px solid black",
          }}
        ></div>
        <div
          style={{
            width: `${props.length}px`,
            borderBottom: "1px solid black",
          }}
        ></div>
      </div>
    );
  }

  return (
    <Fragment>
      <div className={styles["clause-container"]}>
        {!props.isLast && <OrLabel length={25} height={11.5} />}
        <Select
          options={generateFilterOptions(Constants.FIELD_TYPES)}
          styles={Constants.SELECT_STYLE()}
          onChange={handleFieldChange}
          placeholder={"Select a Field"}
          menuShouldScrollIntoView={false}
          menuPortalTarget={document.querySelector("body")}
          defaultValue={
            props.clause.field === ""
              ? "Select a Field"
              : { value: props.clause.field, label: props.clause.field }
          }
        />

        {props.clause.field !== "" && (
          <Select
            styles={Constants.SELECT_STYLE()}
            options={
              Constants.FIELD_TYPES[props.clause.field] === "NUMERIC"
                ? Constants.FILTER_OPTIONS.NUMERIC
                : Constants.FIELD_TYPES[props.clause.field] === "STRING"
                ? Constants.FILTER_OPTIONS.STRING
                : Constants.FIELD_TYPES[props.clause.field] === "DATE"
                ? Constants.FILTER_OPTIONS.DATE
                : Constants.FILTER_OPTIONS.CATEGORICAL
            }
            value={props.clause.comparisonType}
            menuPortalTarget={document.querySelector("body")}
            onChange={handleFilterChange}
            placeholder={"Select a Condition"}
            menuShouldScrollIntoView={false}
          />
        )}

        {props.clause.comparisonType !== "" &&
          (Constants.FIELD_TYPES[props.clause.field] === "DATE" ? (
            <div styles={{ marginLeft: "0px", marginRight: 0 }}>
              <DatePicker
                selected={props.clause.comparisonValue}
                onChange={(date) => handleComparisonValueChange(date)}
              />
            </div>
          ) : (
            props.clause.comparisonType.value !==
              Constants.FILTER_TYPES.NUMERIC_BETWEEN &&
            Constants.FIELD_TYPES[props.clause.field] !== "CATEGORICAL" && (
              <div>
                <input
                  className={styles["comparisonInput"]}
                  type={
                    Constants.FIELD_TYPES[props.clause.field] === "NUMERIC"
                      ? "number"
                      : "text"
                  }
                  value={
                    Constants.FIELD_TYPES[props.clause.field] === "NUMERIC"
                      ? props.clause.comparisonValue === ""
                        ? ""
                        : Number(props.clause.comparisonValue).toString()
                      : props.clause.comparisonValue
                  }
                  onKeyDown={(evt) => {
                    if (
                      Constants.FIELD_TYPES[props.clause.field] === "NUMERIC"
                    ) {
                      ["e", "E", "+"].includes(evt.key) && evt.preventDefault();
                    }
                  }}
                  onChange={(e) => handleComparisonValueChange(e.target.value)}
                />
              </div>
            )
          ))}

        {props.clause.comparisonType !== "" &&
        Constants.FIELD_TYPES[props.clause.field] === "CATEGORICAL" ? (
          <Select
            options={props.categoricalOptions[props.clause.field]}
            styles={Constants.SELECT_STYLE("auto")}
            onChange={(value) => {
              if (
                props.clause.comparisonType.value ===
                  Constants.FILTER_TYPES.CATEGORICAL_EQUAL ||
                props.clause.comparisonType.value ===
                  Constants.FILTER_TYPES.CATEGORICAL_NOT_EQUAL
              ) {
                handleComparisonValueChange(value.value);
              } else {
                if (value.length === 0) {
                  handleComparisonValueChange("");
                } else {
                  handleComparisonValueChange(
                    value.map((item) => {
                      return item.value;
                    })
                  );
                }
              }
            }}
            placeholder={"Select a value"}
            menuShouldScrollIntoView={false}
            menuPortalTarget={document.querySelector("body")}
            isMulti = {(
              props.clause.comparisonType.value ===
                Constants.FILTER_TYPES.CATEGORICAL_IS_ONE_OF ||
              props.clause.comparisonType.value ===
                Constants.FILTER_TYPES.CATEGORICAL_IS_NOT_ONE_OF
            )}


            defaultValue={
              props.clause.comparisonValue === ""
                ? "Select a Value"
                : (
                  props.clause.comparisonType.value ===
                    Constants.FILTER_TYPES.CATEGORICAL_EQUAL ||
                  props.clause.comparisonType.value ===
                    Constants.FILTER_TYPES.CATEGORICAL_NOT_EQUAL) ? 
                    {value: props.clause.comparisonValue, label: props.clause.comparisonValue}
                  : 
                  {value: props.clause.comparisonValue, label: props.clause.comparisonValue}
                
            }
          />
        ) : Constants.FIELD_TYPES[props.clause.field] === "DATE" ? (
          props.clause.comparisonType.value ===
            Constants.FILTER_TYPES.DATE_BETWEEN && (
            <div styles={{ marginRight: "auto", marginLeft: 0 }}>
              <DatePicker
                selected={props.clause.comparisonValue2}
                onChange={(date) => handleComparisonValueChange2(date)}
              />
            </div>
          )
        ) : (
          props.clause.comparisonType.value ===
            Constants.FILTER_TYPES.NUMERIC_BETWEEN && (
            <div>
              <input
                className={styles["comparisonInput"]}
                type="number"
                value={
                  props.clause.comparisonValue === ""
                    ? ""
                    : Number(props.clause.comparisonValue).toString()
                }
                onKeyDown={(evt) =>
                  ["e", "E", "+"].includes(evt.key) && evt.preventDefault()
                }
                onChange={(e) => {
                  handleComparisonValueChange(e.target.value);
                }}
              />
              <input
                className={styles["comparisonInput"]}
                type="number"
                value={
                  props.clause.comparisonValue2 === ""
                    ? ""
                    : Number(props.clause.comparisonValue2).toString()
                }
                onKeyDown={(evt) =>
                  ["e", "E", "+"].includes(evt.key) && evt.preventDefault()
                }
                onChange={(e) => handleComparisonValueChange2(e.target.value)}
              />
            </div>
          )
        )}

        <button
          className={
            styles.orButton + " " + (props.isLast ? "" : styles["hidden"])
          }
          onClick={handleORbuttonClick}
        >
          OR
        </button>

        <div
          className={styles.deleteClause}
          onClick={() => {
            handleDeleteClause(props.id, props.parentid);
          }}
        >
          <FontAwesomeIcon icon={faCircleXmark} />
        </div>
      </div>
    </Fragment>
  );
}

function generateFilterOptions(fields) {
  let options = [];

  for (const key of Object.keys(fields).sort()) {
    options.push({ value: key, label: key });
  }
  return options;
}

export default Clause;
