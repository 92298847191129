import styles from "./ExecutiveSummaryBox.module.css";

function ExecutiveSummaryBox({ sections }) {
  return (
    <div className={styles.executiveSummaryBox}>
      {sections.map((section) => {
        return (
          <div className={styles["section"]} key={section.title}>
            <h1 className={styles["section-title"]} style = {section.titleStyle}>{section.title}</h1>
            <ul className={styles["section-ul"]}>
              {section.bullets.map((bullet) => {
                return (
                  <li className={styles["bullet"]} key={bullet.text}>
                    <div>
                    <span className={styles["bullet-intro"]} style = {bullet.introStyle}>
                      {bullet.intro}  
                      
                    </span>
                    {bullet.text}
                    </div>

                    <div style = {{marginTop: "3px"}}>
                    {bullet.subintro && (<span className={styles["bullet-intro"]} style = {bullet.subintroStyle}>
                      {bullet.subintro}                      
                    </span>)}
                    {bullet.subtext && bullet.subtext}
                    </div>
                    {bullet.subbullets && (
                      <ul>
                        {bullet.subbullets.map((subbullet) => {
                          return (
                            <li className={styles["subbullet"]} key={subbullet.text}>
                              {subbullet.text}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
}

export default ExecutiveSummaryBox;
