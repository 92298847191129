import styles from "./PaidOrganicSelector.module.css";
import Select from "react-select";
import * as Constants from "../../../../constants";

const PaidOrganicSelector = (props) => {
  return (
    
      <div className={styles["paidOrganicSelect"]}>
        Filter Paid/Organic: 
        <Select
          options={[{value: "Both", label:"Both"},{value: "Paid", label:"Paid"},{value: "Organic", label:"Organic"}]}
          onChange={props.handlePaidOrganicChange}
          defaultValue={{ value: "Both", label: "Both" }}
          menuPortalTarget={document.querySelector('body')}
          styles={Constants.SELECT_STYLE("auto", "30px")}
        />
      </div>
    
  );
};
export default PaidOrganicSelector;
