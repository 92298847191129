import styles from "./AggregateFilterMenu.module.css";

import * as Constants from "../../../constants";


import FilterMenu from "../../AssetLibrary/Components/Settings/FilterMenu"



const AggregateFilterMenu = (props) => {
  // console.log(props)
  return (
    <div className={styles.settingsContainer}>
      <div className={styles["filter-container"]}>
        <FilterMenu {...props} FIELD_TYPES={Constants.FIELD_TYPES} headerBackgroundColor = "rgba(12,58,97,.7)" headerTitleColor = "white"/>
      </div>
    </div>
  );
};

export default AggregateFilterMenu;
