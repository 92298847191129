import styles from "./ThumbnailCard.module.css";

import Thumbnail from "./Thumbnail.js";
import ThumbnailTitle from "./ThumbnailTitle.js";
import ThumbnailIconContainer from "./ThumbnailIconContainer";

import { Fragment } from "react";
import { processDate } from "../../../../Util/util";
const formatSort = (sort, value) => {
  if (value === null) {
    return "NULL";
  } else if (
    [
      "CPM",
      "CPC",
      "Spend",
      "CPCV",
      "Cost per Follower",
      "Cost per Profile Visit",
    ].includes(sort)
  ) {
    return (
      "$" +
      value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  } else if (["CTR", "VCR"].includes(sort)) {
    return (
      (100 * value).toLocaleString("en-US", {
        minimumFractionDigits: 5,
        maximumFractionDigits: 5,
      }) + "%"
    );
  } else if (["Length"].includes(sort)) {
    return value.toString() + "s";
  } 
  else if (['Start Date', 'End Date'].includes(sort)){
    return processDate(value,  true)
  }
  else {
    return value.toLocaleString("en-US");
  }
};
const ThumbnailCard = (props) => {
  const cardSizeStyle = {
    flex: `0 0 calc(100% / ${props.thumbnailsPerPage})`,
    maxWidth: `calc(100% / ${props.thumbnailsPerPage}))`,
    border: props.border ? `.5px black solid` : "none",
    height: "100%",
  };

  return (
    props.asset && (
      <div className={styles["thumbnailCard"]} style={cardSizeStyle}>
        {!props.hidden && (
          <Fragment>
            <ThumbnailTitle {...props.asset} fontSize={props.fontSize} hideTitleIcons = {props.hideTitleIcons}/>

            <Thumbnail
              {...props.asset}
              onClick={props.onClick}
              maxHeight={props.height}
            />

            <div className={styles["thumbnail-info"]}>
              <div className={styles["sortFields"]}>
                {props.sort[0][0] !== "None" && (
                  <div>
                    <span>{props.sort[0][0]}: </span>
                    {formatSort(
                      props.sort[0][0],
                      props.asset[props.sort[0][0]]
                    )}
                  </div>
                )}
                {props.sort[1][0] !== "None" && (
                  <div>
                    <span>{props.sort[1][0]}: </span>
                    {formatSort(
                      props.sort[1][0],
                      props.asset[props.sort[1][0]]
                    )}
                  </div>
                )}
              </div>
              {!props.hideIcons && <ThumbnailIconContainer {...props.asset} />}
            </div>
          </Fragment>
        )}
      </div>
    )
  );
};

export default ThumbnailCard;
