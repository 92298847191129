import { FILTER_TYPES } from "../constants/";

export function evaluateClause(item, clause) {
  let clause_value = false;

  switch (clause.comparisonType.value) {
    case FILTER_TYPES.NOT_EQUAL:
      clause_value = item[clause.field] != clause.comparisonValue;
      break;
    case FILTER_TYPES.EQUAL:
      clause_value = item[clause.field] === clause.comparisonValue;
      break;
    case FILTER_TYPES.NUMERIC_GREATER_THAN:
      clause_value = item[clause.field] > clause.comparisonValue;
      break;
    case FILTER_TYPES.NUMERIC_GREATER_THAN_OR_EQUAL:
      clause_value = item[clause.field] >= clause.comparisonValue;
      break;
    case FILTER_TYPES.NUMERIC_LESS_THAN:
      clause_value = item[clause.field] < clause.comparisonValue;
      break;
    case FILTER_TYPES.NUMERIC_LESS_THAN_OR_EQUAL:
      clause_value = item[clause.field] <= clause.comparisonValue;
      break;
    case FILTER_TYPES.NUMERIC_BETWEEN:
      clause_value =
        item[clause.field] >= clause.comparisonValue &&
        item[clause.field] <= clause.comparisonValue2;

      break;
    case FILTER_TYPES.STRING_BEGINS_WITH:
      clause_value = item[clause.field]
        .toString()
        .startsWith(clause.comparisonValue);
      break;
    case FILTER_TYPES.STRING_ENDS_WITH:
      clause_value = item[clause.field]
        .toString()
        .endsWith(clause.comparisonValue);
      break;

    case FILTER_TYPES.STRING_CONTAINS:
      clause_value = item[clause.field]
        .toString()
        .includes(clause.comparisonValue);
      break;
    case FILTER_TYPES.STRING_DOES_NOT_CONTAIN:
      clause_value = !item[clause.field]
        .toString()
        .includes(clause.comparisonValue);
      break;

    case FILTER_TYPES.CATEGORICAL_EQUAL:
      clause_value = item[clause.field] === clause.comparisonValue;
      break;

    case FILTER_TYPES.CATEGORICAL_NOT_EQUAL:
      clause_value = item[clause.field] !== clause.comparisonValue;
      break;

    case FILTER_TYPES.CATEGORICAL_IS_ONE_OF:
      clause_value = 
        clause.comparisonValue.includes(item[clause.field]);
      break;

    case FILTER_TYPES.CATEGORICAL_IS_NOT_ONE_OF:
      clause_value = !clause.comparisonValue.includes(item[clause.field]);
      break;
    case FILTER_TYPES.DATE_EQUAL: {
      const temp_date = new Date(item[clause.field]);
      clause_value =
        temp_date.getFullYear() === clause.comparisonValue.getFullYear() &&
        temp_date.getMonth() === clause.comparisonValue.getMonth() &&
        temp_date.getDate() === clause.comparisonValue.getDate();
      break;
    }
    case FILTER_TYPES.DATE_NOT_EQUAL: {
      const temp_date = new Date(item[clause.field]);
      clause_value = !(
        temp_date.getFullYear() === clause.comparisonValue.getFullYear() &&
        temp_date.getMonth() === clause.comparisonValue.getMonth() &&
        temp_date.getDate() === clause.comparisonValue.getDate()
      );
      break;
    }

    case FILTER_TYPES.DATE_AFTER: {
      const temp_date = new Date(item[clause.field]);
      clause_value = temp_date > clause.comparisonValue;
      break;
    }
    case FILTER_TYPES.DATE_ON_OR_AFTER: {
      const temp_date = new Date(item[clause.field]);
      clause_value = temp_date >= clause.comparisonValue;
      break;
    }
    case FILTER_TYPES.DATE_BEFORE: {
      const temp_date = new Date(item[clause.field]);
      clause_value = temp_date < clause.comparisonValue;
      break;
    }
    case FILTER_TYPES.DATE_ON_OR_BEFORE: {
      const temp_date = new Date(item[clause.field]);
      clause_value = temp_date <= clause.comparisonValue;
      break;
    }

    case FILTER_TYPES.DATE_BETWEEN: {
      const temp_date = new Date(item[clause.field]);
      clause_value =
        temp_date >= clause.comparisonValue &&
        temp_date <= clause.comparisonValue2;
      break;
    }
  }

  return clause_value;
}

export function evaluateFilter(item, filters) {
  return filters.every((filter) =>
    filter.orClauses.every((orClause) =>
      orClause.clauses.some((clause) =>
        evaluateClause(item, clause.clause_data)
      )
    )
  );
}
