import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell,Legend, ResponsiveContainer, Tooltip } from 'recharts';
import styles from './PieChartWithLabel.module.css'
import { formatMetric } from '../../Util/util';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.65;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" className = 'text-xs' textAnchor="middle" dominantBaseline = "central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CustomTooltip = ({ active, payload, label, metric }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles["customTooltipContainer"]}>        
        <p className={styles["customTooltipLabel"]}>
          {" "}
          {`${payload[0].name}: ${formatMetric(payload[0].value, metric)}`}
        </p>
      </div>
    );
  }
};

function PieChartWithLabel({data, metric}) {
  
    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart >
          <Pie
            data={data}
            cx="25%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            

          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend align='right' layout='veritcal' verticalAlign='middle' height = {36} />
          <Tooltip
            content={<CustomTooltip metric = {metric}/>}
            cursor={{ fill: "rgba(0,0,0,.05)" }}
          />
        </PieChart>
        
      </ResponsiveContainer>
    );
  
}

export default PieChartWithLabel;