import styles from "./CreativeAnalysis.module.css";

import Carousel from "./Components/AssetCarousel/Carousel";
import AssetModal from "./Components/Asset/AssetModal";
import SettingsMenu from "./Components/Settings/SettingsMenu.js";
import AddFilterModal from "./Components/Settings/AddFilterModal.js";

import { groupBy } from "../../Util/groups";
import { evaluateFilter } from "../../Util/filter";
import { dynamicSortMultiple } from "../../Util/sort.js";
import * as Constants from "../../constants";

import { v4 as uuidv4 } from "uuid";

import { useState, useMemo, useEffect } from "react";

import "@aws-amplify/ui-react/styles.css";
import { withAuthenticator } from "@aws-amplify/ui-react";

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { isSortValueNull } from "../../Util/util";
import Select from "react-select";
import ExecutiveSummaryBox from "../Daisy/Components/ExecutiveSummaryBox";
import { aggregate, getAggregateBarData } from "../../Util/aggregate.js";
import BarChartContainer from "../ExecutiveSummary/Components/BarChartContainer.js";

const topSummary = [
  {
    title: "Executive Summary",
    titleStyle: {
      fontSize: "2rem",
      color: "var(--clr-primary-dark)",
      fontWeight: "800",
    },
    bullets: [
      {
        intro: "Language and Spend: ",
        text: "There are no significant performance differences found among the assets across EN and FR. The best performing assets do well in both languages and vice-versa for the lower-performing ones. Overall, we didn not include assets where the spend is not significant enough to be included in the analysis (little time for the platforms to optimize).",
        subintro: "Recommendation: ",
        subintroStyle: { fontStyle: "italic", fontWeight: "400" },
        subtext:
          "As we did not find significant performance differences among the assets across EN and FR, it suggests that regional differences are less relevant than overall creative impact when making decisions about assets.",
      },
      {
        intro: "Style: ",
        text: "In shorter assets, the digital-first gaming/milk carton-style creative performed well and captured attention. Where gaming/milk carton assets were not present, the 'reverscipes'-style approach (a narrative hook and pulling you back to the start drove best results overall) was a top performer.",
        subintro: "Recommendation: ",
        subintroStyle: { fontStyle: "italic", fontWeight: "400" },
        subtext:
          "Explore more digital-centric (gaming, animation) style content at both 6 and 15s range to drive maximum results vs. more people/individuals.",
      },

      {
        intro: "People: ",
        text: "Charisma/Likeability drive results - Paul Toussaint and Eric Chong drove better results, noting they are paired as an 'odd couple' with farmers. In creative featuring farmers, Matt and Alain do better than others. But as the gaming/milk carton assets show, people are not required to be featured in creative in order to achieve great results.",
        subintro: "Recommendation: ",
        subintroStyle: { fontStyle: "italic", fontWeight: "400" },
        subtext:
          "Use compelling characters to drive affinity, but in lieu of these people, use eye-catching animation.",
      },

      {
        intro: "Message: ",
        text: "Regenerative Agriculture / Net Zero in the shorter video, food and/or likeable farmers in the longer form video.",
        subintro: "Recommendation: ",
        subintroStyle: { fontStyle: "italic", fontWeight: "400" },
        subtext:
          "N/A",
      },
    ],
  },
];

const execSummary = {
  "0 - 6s": [
    {
      title: "0s - 6s",
      bullets: [
        {
          intro: "CTR: ",
          text: "The milk carton was a particularly effective ad at driving clicks. Where it didn't run, it was Reverscipes.",
        },
        {
          intro: "CPM: ",
          text: "Milk Carton and Digital Farm drove low CPM on TT and YT; Reverscipes and Holidairy assets drove low CPM on other platforms where they were not present.",
        },
        {
          intro: "VCR: ",
          text: "In terms of VCR, the non-forced view platforms show an overwhelming preference for animated/non human-centric videos (e.g. Q2 Regen Ag or Gaming).",
        },
      ],
    },
  ],

  "7 - 15s": [
    {
      title: "7s - 15s",
      bullets: [
        {
          intro: "CTR: ",
          text: "Overall, Reverscipes was most effective at driving clicks across the board. Holidairy had some strong performance on the Meta platforms.",
        },
        {
          intro: "CPM: ",
          text: "DFOT Launch performed well on Meta/YT platforms. Reversipes/Net Zero on TT/Snap. Although clear takeaways are not easy to find, this looks like an issue of seasonality (many Q2 campaigns).",
        },
        {
          intro: "VCR: ",
          text: "In terms of VCR where Regen Ag animated posts were not shown, Reverscipes performed best but farmer-centric assets did well in more recent content.",
        },
      ],
    },
  ],

  "15+s": [
    {
      title: "15s+",
      bullets: [
        {
          intro: "N/A - Sample is too small.",
          text: "",
        },
      ],
    },
  ],
};

const CA_FILTERS = [
  {
    id: uuidv4(),
    name: "Spend >= 1000",
    orClauses: [
      {
        id: uuidv4(),
        clauses: [
          {
            id: uuidv4(),
            clause_data: {
              field: "Spend",
              comparisonType: {
                value: Constants.FILTER_TYPES.NUMERIC_GREATER_THAN_OR_EQUAL,
                label: "GREATER THAN OR EQUAL TO",
              },
              comparisonValue: "1500",
              comparisonValue2: "",
              isValid: true,
            },
          },
        ],
      },
    ],
  },

  {
    id: uuidv4(),
    name: "Platform Filter",
    orClauses: [
      {
        id: uuidv4(),
        clauses: [
          {
            id: uuidv4(),
            clause_data: {
              field: "Platform",
              comparisonType: {
                value: Constants.FILTER_TYPES.CATEGORICAL_IS_ONE_OF,
                label: "IS ONE OF",
              },
              comparisonValue: [
                "Meta",
                "facebook",
                "instagram",
                "snapchat",
                "tiktok",
                "youtube",
              ],
              comparisonValue2: "",
              isValid: true,
            },
          },
        ],
      },
    ],
  },
];
function CreativeAnalysis({ assetData, categoricalOptions }) {
  const [showAssetModal, setShowAssetModal] = useState(false);

  const [lengthRange, setLengthRange] = useState("0 - 6s");
  const [language, setLanguage] = useState("English");
  const [metric, setMetric] = useState("CPM");

  const [currentAsset, setCurrentAsset] = useState(null);
  const [group, setGroup] = useState("Platform");
  const [sort, setSort] = useState([
    ["CPM", 1],
    ["None", 1],
  ]);

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [initialFilterId, setInitialFilterId] = useState(null);
  const [filters, setFilters] = useState([
    /* {
      id: uuidv4(),
      name: "Spend >= 1500",
      orClauses: [
        {
          id: uuidv4(),
          clauses: [
            {
              id: uuidv4(),
              clause_data: {
                field: "Spend",
                comparisonType: {
                  value: Constants.FILTER_TYPES.NUMERIC_GREATER_THAN_OR_EQUAL,
                  label: "GREATER THAN OR EQUAL TO",
                },
                comparisonValue: "1500",
                comparisonValue2: "",
                isValid: true,
              },
            },
            
          ],
        },

      ],
    },
    {
      id: uuidv4(),
      name: "Audience = M2M",
      orClauses: [
        {
          id: uuidv4(),
          clauses: [
            {
              id: uuidv4(),
              clause_data: {
                field: "Audience",
                comparisonType: {
                  value: Constants.FILTER_TYPES.CATEGORICAL_EQUAL,
                  label: "EQUAL TO",
                },
                comparisonValue: "M2M",
                comparisonValue2: "",
                isValid: true,
              },
            },
          ],
        },
      ],
    },*/
  ]);

  const [thumbnailsPerPage, setThumbnailsPerPage] = useState(5);

  const [showSettings, setShowSettings] = useState(false);

  const groups = useMemo(() => groupBy(assetData, group), [assetData, group]);

  function closeAssetModal() {
    setShowAssetModal(false);
  }
  function onAssetClick(data) {
    setCurrentAsset(data);
    setShowAssetModal(true);
  }

  function closeFilterModal() {
    setInitialFilterId(null);
    setShowFilterModal(false);
  }

  const handleGroupChange = (selectedOption) => {
    setGroup(selectedOption.value);
  };

  const handlePrimarySortChange = (selectedOption) => {
    setSort((prevState) => {
      if (selectedOption.value === "None") {
        return [prevState[1], ["None", 1]];
      } else {
        return [[selectedOption.value, prevState[0][1]], prevState[1]];
      }
    });
  };
  const handleLanguageChange = (selectedOption) => {
    setLanguage(selectedOption.value);
  };

  const handleLengthRangeChange = (selectedOption) => {
    setLengthRange(selectedOption.value);
  };

  const handleMetricChange = (selectedOption) => {
    setMetric(selectedOption.value);
    setSort([
      [selectedOption.value, selectedOption.value === "CPM" ? 1 : -1],
      ["None", 1],
    ]);
  };

  const handleSecondarySortChange = (selectedOption) => {
    setSort((prevState) => {
      return [prevState[0], [selectedOption.value, prevState[1][1]]];
    });
  };

  const handleThumbnailChange = (e) => {
    setThumbnailsPerPage(e.target.value);
  };

  const handlePrimarySortDirection = (selectedOption) => {
    setSort((prevState) => {
      return [[prevState[0][0], 1 - prevState[0][1]], prevState[1]];
    });
  };

  const handleSecondarySortDirection = (selectedOption) => {
    setSort((prevState) => {
      return [prevState[0], [prevState[1][0], 1 - prevState[1][1]]];
    });
  };

  const handleRemoveFilter = (id) => {
    setFilters((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });
  };

  const handleToggleMenu = () => {
    setShowSettings((prevState) => {
      return !prevState;
    });
  };

  const handleAddFilter = (name, newFilter) => {
    if (filters.filter((filter) => newFilter.id === filter.id).length > 0) {
      setFilters((prevState) => {
        return prevState.map((filter) => {
          return filter.id === newFilter.id
            ? { ...newFilter, name: name }
            : filter;
        });
      });
    } else {
      setFilters((prevState) => {
        return [...prevState, { ...newFilter, name: name }];
      });
    }
  };

  const handleEditFilter = (id) => {
    setInitialFilterId(id);
    setShowFilterModal(true);
  };

  useEffect(() => {
    if (showAssetModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showAssetModal]);

  useEffect(() => {
    if (showFilterModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showFilterModal]);

  useEffect(() => {
    let newFilter = [
      {
        id: uuidv4(),
        name: "Spend >= 1500",
        orClauses: [
          {
            id: uuidv4(),
            clauses: [
              {
                id: uuidv4(),
                clause_data: {
                  field: "Spend",
                  comparisonType: {
                    value: Constants.FILTER_TYPES.NUMERIC_GREATER_THAN_OR_EQUAL,
                    label: "GREATER THAN OR EQUAL TO",
                  },
                  comparisonValue: "1500",
                  comparisonValue2: "",
                  isValid: true,
                },
              },
            ],
          },
        ],
      },

      {
        id: uuidv4(),
        name: "Platform Filter",
        orClauses: [
          {
            id: uuidv4(),
            clauses: [
              {
                id: uuidv4(),
                clause_data: {
                  field: "Platform",
                  comparisonType: {
                    value: Constants.FILTER_TYPES.CATEGORICAL_IS_ONE_OF,
                    label: "IS ONE OF",
                  },
                  comparisonValue: [
                    "Meta",
                    "facebook",
                    "instagram",
                    "snapchat",
                    "tiktok",
                    "youtube",
                  ],
                  comparisonValue2: "",
                  isValid: true,
                },
              },
            ],
          },
        ],
      },
    ];

    newFilter.push({
      id: uuidv4(),
      name: `Language = ${language}`,
      orClauses: [
        {
          id: uuidv4(),
          clauses: [
            {
              id: uuidv4(),
              clause_data: {
                field: "Language",
                comparisonType: {
                  value: Constants.FILTER_TYPES.CATEGORICAL_EQUAL,
                  label: "EQUAL TO",
                },
                comparisonValue: language,
                comparisonValue2: "",
                isValid: true,
              },
            },
          ],
        },
      ],
    });

    if (lengthRange === "0 - 6s") {
      newFilter.push({
        id: uuidv4(),
        name: lengthRange,
        orClauses: [
          {
            id: uuidv4(),
            clauses: [
              {
                id: uuidv4(),
                clause_data: {
                  field: "Length",
                  comparisonType: {
                    value: Constants.FILTER_TYPES.NUMERIC_BETWEEN,
                    label: "BETWEEN",
                  },
                  comparisonValue: 0,
                  comparisonValue2: 6,
                  isValid: true,
                },
              },
            ],
          },
        ],
      });
    } else if (lengthRange === "7 - 15s") {
      newFilter.push({
        id: uuidv4(),
        name: lengthRange,
        orClauses: [
          {
            id: uuidv4(),
            clauses: [
              {
                id: uuidv4(),
                clause_data: {
                  field: "Length",
                  comparisonType: {
                    value: Constants.FILTER_TYPES.NUMERIC_BETWEEN,
                    label: "BETWEEN",
                  },
                  comparisonValue: 7,
                  comparisonValue2: 15,
                  isValid: true,
                },
              },
            ],
          },
        ],
      });
    } else {
      newFilter.push({
        id: uuidv4(),
        name: lengthRange,
        orClauses: [
          {
            id: uuidv4(),
            clauses: [
              {
                id: uuidv4(),
                clause_data: {
                  field: "Length",
                  comparisonType: {
                    value: Constants.FILTER_TYPES.NUMERIC_GREATER_THAN,
                    label: "GREATER THAN",
                  },
                  comparisonValue: 15,
                  comparisonValue2: null,
                  isValid: true,
                },
              },
            ],
          },
        ],
      });
    }
    setFilters(newFilter);
  }, [lengthRange, language]);
  return (
    <div className={styles["main"]}>
      <div className={styles.header}>
        <FontAwesomeIcon
          data-tooltip-id="toggle-settings"
          icon={faGear}
          onClick={handleToggleMenu}
          size="1x"
          className={styles["settingsIcon"]}
        />

        <Tooltip id="toggle-settings">
          {showSettings ? "Hide Settings" : "Show Settings"}
        </Tooltip>
      </div>

      {showSettings && (
        <SettingsMenu
          groupByOptions={Constants.GROUPBY_OPTIONS()}
          handleGroupChange={handleGroupChange}
          onShowFilterModal={() => setShowFilterModal(true)}
          handlePrimarySortChange={handlePrimarySortChange}
          handlePrimarySortDirection={handlePrimarySortDirection}
          handleSecondarySortChange={handleSecondarySortChange}
          handleSecondarySortDirection={handleSecondarySortDirection}
          handleThumbnailChange={handleThumbnailChange}
          thumbnailsPerPage={thumbnailsPerPage}
          sort={sort}
          filters={filters}
          setFilter={setFilters}
          onRemoveFilter={handleRemoveFilter}
          onAdd={handleAddFilter}
          hidden={showSettings}
          onEdit={handleEditFilter}
          showCarouselSize={true}
        />
      )}
      <div className={styles["executivesummary"]}>
        <ExecutiveSummaryBox sections={topSummary} />
        <div
          style={{
            width: "100%",
            height: "2px",
            backgroundColor: "black",
            marginTop: "5px",
            marginBottom: "5px",
          }}
        ></div>
        <div className={styles.presetContainer}>
          <div className={styles["presetSelector"]}>
            <span>Language</span>
            <Select
              options={[
                { value: "English", label: "English" },
                { value: "French", label: "French" },
              ]}
              onChange={handleLanguageChange}
              defaultValue={{ value: "English", label: "English" }}
              menuPortalTarget={document.querySelector("body")}
              styles={Constants.SELECT_STYLE("auto", "40px", "1rem", "1rem")}
            />
          </div>

          <div className={styles["presetSelector"]}>
            <span>Length Range</span>
            <Select
              options={[
                { value: "0 - 6s", label: "0 - 6s" },
                { value: "7 - 15s", label: "7 - 15s" },
                { value: "15+s", label: "15+s" },
              ]}
              onChange={handleLengthRangeChange}
              defaultValue={{ value: "0 - 6s", label: "0 - 6s" }}
              menuPortalTarget={document.querySelector("body")}
              styles={Constants.SELECT_STYLE("auto", "40px", "1rem", "1rem")}
            />
          </div>

          <div className={styles["presetSelector"]}>
            <span>Metric</span>
            <Select
              options={[
                { value: "CPM", label: "CPM" },
                { value: "CTR", label: "CTR" },
                { value: "VCR", label: "VCR" },
              ]}
              onChange={handleMetricChange}
              defaultValue={{ value: "CPM", label: "CPM" }}
              menuPortalTarget={document.querySelector("body")}
              styles={Constants.SELECT_STYLE("auto", "40px", "1rem", "1rem")}
            />
          </div>
        </div>
        <ExecutiveSummaryBox sections={execSummary[lengthRange]} />
      </div>
      {showAssetModal && (
        <AssetModal
          data={currentAsset}
          onClose={closeAssetModal}
          filteredData={assetData.filter((asset) =>
            evaluateFilter(asset, filters)
          )}
        />
      )}

      {showFilterModal && (
        <AddFilterModal
          FIELD_TYPES={Constants.FIELD_TYPES}
          FILTER_TYPES={Constants.FILTER_TYPES}
          onClose={closeFilterModal}
          onAdd={handleAddFilter}
          initialFilter={
            initialFilterId === null
              ? null
              : filters.filter((filter) => {
                  return filter.id === initialFilterId;
                })[0]
          }
          categoricalOptions={categoricalOptions}
        />
      )}
      <div className={styles["carousel-container"]}>
        {groups.map((item) => (
          <Carousel
            assetData={item.data
              .filter((asset) => isSortValueNull(asset, sort[0][0]))
              .filter((asset) => isSortValueNull(asset, sort[1][0]))
              .sort(dynamicSortMultiple(...sort))
              .filter((asset) => evaluateFilter(asset, filters))}
            sort={sort}
            group={group}
            title={item.group}
            key={item.id + sort + filters + Math.random()}
            onClick={onAssetClick}
            thumbnailsPerPage={thumbnailsPerPage}
            height={"500px"}
          />
        ))}
      </div>

      <div className={styles.aggregates}>
        <div
          style={{
            width: "100%",
            height: "2px",
            backgroundColor: "black",
            marginBottom: "5px",
          }}
        ></div>
        <h1>Length Range Comparison</h1>

        <div className={styles.barCharts}>
          {["CPM", "CTR", "VCR"].map((aggregateMetric) => {
            return (
              <div style={{ width: "45%" }}>
                <BarChartContainer
                  setCarouselFilter={null}
                  setShowCarouselModal={null}
                  clickable={false}
                  title={`${aggregateMetric} by Length Range`}
                  data={getAggregateBarData(
                    aggregate(
                      assetData
                        .filter((asset) => evaluateFilter(asset, CA_FILTERS))
                        .filter((asset) => isSortValueNull(asset, sort[0][0]))
                        .filter((asset) => isSortValueNull(asset, sort[1][0])),
                      "Length Range for CA"
                    ).sort(
                      dynamicSortMultiple([
                        aggregateMetric,
                        Constants.AGGREGATE_ORDER[aggregateMetric],
                      ])
                    ),
                    aggregateMetric,
                    "Length Range for CA"
                  )}
                  key={aggregateMetric + "Length Range for CA"}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default withAuthenticator(CreativeAnalysis, { hideSignUp: true });
