import styles from "./MetricIcon.module.css";
import { formatMetric } from "../../../../Util/util";

function MetricIcon({metric, value, comparisonData, benchmark = null, title = ""}){

    return (
        <div className = {styles['metricBox']}>
            <span className = {styles.title}>{title ? title : metric}</span>
            <span className = {styles.value}>{formatMetric(value, metric) ? formatMetric(value, metric) : "-"}</span>
        </div>
    )
}

export default MetricIcon;