import styles from "./ProgressSlider.module.css";

const ProgressBar = (props) => {
  const handleSliderChange = (e) => {
    props.setCurrentPage(Math.round(e.target.value * 1));
  };

  return (
    <div className={styles.progressContainer}>
      <div className={styles["progressSlider"]}>
        <fieldset className={styles["progressFieldset"]}>
          <legend align="center">
            {props.currentPage * props.thumbnailsPerPage + 1}-
            {props.currentPage + 1 === props.num_pages
              ? props.numAssets
              : (props.currentPage + 1) * props.thumbnailsPerPage}{" "}
            of {props.numAssets} Assets
          </legend>
          <input
            type="range"
            min="0"
            max={props.num_pages - 1}
            value={props.currentPage}
            onChange={handleSliderChange}
            step="1"
            className={styles["slider"]}
          />
        </fieldset>
      </div>
      
    </div>
  );
};

export default ProgressBar;
