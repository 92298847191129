import styles from "./ChartTest.module.css";

import BarChartContainer from "../ExecutiveSummary/Components/BarChartContainer";
import { getAggregateBarData, aggregate } from "../../Util/aggregate";
import { dynamicSortMultiple } from "../../Util/sort";
import HorizontalBarChart from "../../Components/Charts/HorizontalBarChart";

function ChartTest({ assetData }) {
  
  return (
    assetData.length > 0 && 
    <div className={styles.main}>
    <div className={styles.wrapper}>
      <HorizontalBarChart
        data={getAggregateBarData(
          aggregate(assetData, "Platform").sort(
            dynamicSortMultiple(["CPM", 1])
          ),
          "CPM",
          "Platform",
          "v2"
        )}
        group = {"Platform"}
        xAccessor = {"CPM"}
        xType = {"number"}
        yAccesor = {"Platform"}
        yType = {"category"}
        barFillColor = {"var(--clr-primary)"}
        title = {"CPM by Platform"}
        
      />
      

    </div>
    </div>
  );
}

export default ChartTest;
