import { withAuthenticator } from "@aws-amplify/ui-react";
import { Outlet } from "react-router-dom";
import { Fragment } from "react";

import MainNavigation from "../Components/Navigation/MainNavigation";

const Root = ({ signOut, user }) => {
  return (
    <Fragment>
      <MainNavigation />
      <Outlet />
    </Fragment>
  );
};

export default withAuthenticator(Root, { hideSignUp: true });
