import React from "react";
//import { formatData, getPctChange } from "../../utils/utils";
import clsx from "clsx";
import { faUpLong, faDownLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const getPctChange = (current, previous) => {
    if (current === previous) {
      return 0;
    }
  
    return (current - previous) / previous;
  };
  
  
  export function formatData(data, format = "", precision = 2, returnNA = false) {
    if (data) {
      return (
        (format === "$" ? "$" : "") +
        Number((format ==="%%" ? 100 : 1)*data).toLocaleString("en-US", {
          minimumFractionDigits: precision,
          maximumFractionDigits: precision,
        }) +
        ((format === "%" || format === "%%") ? "%" : "")
      );
    } else {
      return returnNA ? "N/A" : null;
    }
  }

const ScoreCard = ({
  title,
  content,
  comparison = null,
  invertColor = false,
  format = null,
  precision = 2,
  width = "8rem",
  height = "4rem",
}) => {
  let percentChange;
  if (typeof content === "number" && comparison) {
    percentChange = getPctChange(content, comparison) * 100;
  }
  return (
    <div
      className={`relative shadow-1 flex flex-col items-center justify-center rounded-xl px-2 py-2 bg-white`}
      style = {{"width": width, "height": height}}
    >
      <div className="flex flex-col items-center">
        <div className="text-sm text-primary text-gray-400">{title}</div>
        <div className="mt-1 text-2xl font-thin text-dark">
          {typeof content === "number" ? formatData(content, format = format, precision = precision) : content}
        </div>
      </div>
      {percentChange && (
        <div
          className={clsx(
            " absolute bottom-2 font-primary mb-1 flex justify-center gap-1 text-sm font-black",
            {
              "text-green":
                (percentChange > 0 && !invertColor) ||
                (percentChange < 0 && invertColor),
              "text-red-400":
                (percentChange < 0 && !invertColor) ||
                (percentChange > 0 && invertColor),
            },
          )}
        >
          <FontAwesomeIcon icon={faUpLong} size="sm" />
          {formatData(percentChange, "%")}
        </div>
      )}
    </div>
  );
};

export default ScoreCard;
