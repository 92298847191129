import styles from "./Journeys.module.css";

import DataTable from "./Components/DataTable.js";

import { withAuthenticator } from "@aws-amplify/ui-react";
import FunnelCard from "./Components/FunnelCard";
import BarChartContainer from "./Components/BarChartContainer";
import StackedBarChartContainer from "./Components/StackedBarChartContainer";
import TimeSeriesContainer from "./Components/TimeSeriesContainer";
import ReactPlayer from "react-player";
import SourcePerformance from "./Components/SourcePerformance";
import { useState, useEffect } from "react";
import { getObjectUrl } from "../../Util/s3";
import { read, utils } from "xlsx";
import { formatMetric, processDate, breakAroundSpace } from "../../Util/util";
import Select from "react-select";
import * as Constants from "../../constants";

import StackedBarChart from "../../Components/Charts/StackedBarChart";
import TimeSeriesChart from "../../Components/Charts/TimeSeriesChart";

function processAudienceData(data) {
  return [
    {
      label: "Fragile Identified",
      current_delta:
        data[0]["Fragile Identified"] - data[1]["Fragile Identified"],
      previous_value: data[1]["Fragile Identified"],
      current_date: processDate(data[0]["Date"], false),
      previous_date: processDate(data[1]["Date"], false),
    },
    {
      label: "Fragile Nurtured",
      current_delta: data[0]["Fragile Nurtured"] - data[1]["Fragile Nurtured"],
      previous_value: data[1]["Fragile Nurtured"],
      current_date: processDate(data[0]["Date"], false),
      previous_date: processDate(data[1]["Date"], false),
    },
    {
      label: "Fragile Engaged",
      current_delta: data[0]["Fragile Engaged"] - data[1]["Fragile Engaged"],
      previous_value: data[1]["Fragile Engaged"],
      current_date: processDate(data[0]["Date"], false),
      previous_date: processDate(data[1]["Date"], false),
    },
    {
      label: "Total Canadians in Our Dairy Journey Ecosystem",
      current_delta: data[0]["Total"] - data[1]["Total"],
      previous_value: data[1]["Total"],
      current_date: processDate(data[0]["Date"], false),
      previous_date: processDate(data[1]["Date"], false),
    },
  ];
}

const journeyRules = [
  {
    title: "Journey Targeting Parameters and rules",
    titleStyle: {
      fontSize: "1.5rem",
      color: "var(--clr-primary-dark)",
      fontWeight: "800",
    },
    bullets: [
      {
        intro: "",
        text: "Users who completed Q1 Journeys ( All 3 clicks) are excluded from acquisition phase of Q2 Journey. However, they will be targeted in Q3 journey starts.",
      },
      {
        intro: "",
        text: "User who Interacted with Q1 but didn’t completed Q1 journey ( All 3 clicks) will be targeted in Q2 as they are yet to show advocate behaviour.",
      },
      {
        intro: "",
        text: "Users who have interacted with Q1 journeys will be targeted with Partnership campaign ( Bio Enterprise). Their performance will be evaluated compared to user who have only see partnership campaign, this will help us measure the lift achieved.",
      },
      {
        intro: "",
        text: "Users from Bio Enterprise (video viewers + website visitors) will be used in the acquisition phase of Q3 journey.",
      },

      {
        intro: "Style: ",
        text: "In shorter assets, the digital-first gaming/milk carton-style creative performed well and captured attention. Where gaming/milk carton assets were not present, the 'reverscipes'-style approach (a narrative hook and pulling you back to the start drove best results overall) was a top performer.",
        subintro: "Recommendation: ",
        subintroStyle: { fontStyle: "italic", fontWeight: "400" },
        subtext:
          "Short-form video (6s) must immediately grab attention, while longer form video (15s+) must grab AND sustain attention.",
      },

      {
        intro: "People: ",
        text: "Charisma/Likeability drive results - Paul Toussaint and Eric Chong drove better results, noting they are paired as an 'odd couple' with farmers. In creative featuring farmers, Matt and Alain do better than others. But as the gaming/milk carton assets show, people are not required to be featured in creative in order to achieve great results.",
        subintro: "Recommendation: ",
        subintroStyle: { fontStyle: "italic", fontWeight: "400" },
        subtext:
          "Use compelling characters to drive affinity, but in lieu of these people, use eye-catching animation.",
      },

      {
        intro: "Message: ",
        text: "Regenerative Agriculture / Net Zero in the shorter video, food and/or likeable farmers in the longer form video.",
        subintro: "Recommendation: ",
        subintroStyle: { fontStyle: "italic", fontWeight: "400" },
        subtext: "N/A",
      },
    ],
  },
];

function Journeys({ signOut, user }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [lessThan9, setLessThan9] = useState(true);
  const [bottleUrl, setBottleUrl] = useState(null);

  useEffect(() => {
    getObjectUrl("bottle.webm").then(setBottleUrl).catch(console.error);
  }, []);

  const [journeysData, setJourneysData] = useState(null);
  const [sourceData, setSourceData] = useState(null);
  const [audienceData, setAudienceData] = useState(null);
  const [audienceData2, setAudienceData2] = useState(null);
  const [funnelData, setFunnelData] = useState(null);
  const [journeyData, setJourneyData] = useState([]);
  const [selectedJourney, setSelectedJourney] = useState(null);
  // const [questionData, setQuestionData] = useState(null);
  const [completedJourneysData, setCompletedJourneysData] = useState([null]);
  const [platformAudienceData, setPlatformAudienceData] = useState([]);
  const [headerImageUrl, setHeaderImageUrl] = useState(null);
  const JOURNEY_FILES = {
    "2023 Q1 - Renewable Energy": {
      filename: "data/journeys/journey_data_2023_Q1.xlsx",
      header: "assets/Journeys/2023Q1_Header.png",
    },
    "2023 Q2 - Regenerative Agriculture": {
      filename: "data/journeys/journey_data_2023_Q2.xlsx",
      header: "assets/Journeys/2023Q2_Header.png",
    },
  };
  useEffect(() => {
    if (selectedJourney) {
      getObjectUrl(JOURNEY_FILES[selectedJourney].header)
        .then(setHeaderImageUrl)
        .catch(console.error);
    }
  }, [selectedJourney]);
  
  useEffect(() => {
    getObjectUrl("data/journeys/journey_audience_data.xlsx").then(
      async (url) => {
        const f = await (await fetch(url)).arrayBuffer();
        const wb = read(f, {
          cellText: false,
          cellDates: true,
        });
        setAudienceData(
          utils
            .sheet_to_json(wb.Sheets["audience_growth"])
            .sort(function (a, b) {
              return Date.parse(b.Date) - Date.parse(a.Date);
            })
        );
        setAudienceData2(
          processAudienceData(utils
            .sheet_to_json(wb.Sheets["audience_growth"])
            .sort(function (a, b) {
              return Date.parse(b.Date) - Date.parse(a.Date);
            }))
        );

        setPlatformAudienceData(
          utils
            .sheet_to_json(wb.Sheets["platform_audiences"], {
              raw: false,
            })
            .sort(function (a, b) {
              return Date.parse(b.Date) - Date.parse(a.Date);
            })[0]
        );
      }
    );

    for (const [journeyIndex, journeyFile] of Object.keys(
      JOURNEY_FILES
    ).entries()) {
      let newJourney = {
        title: journeyFile,
        data: {
          sourceData: null,
          funnelData: null,
          journeyData: null,
          completedJourneysData: null,
        },
      };
      getObjectUrl(JOURNEY_FILES[journeyFile].filename).then(async (url) => {
        const f = await (await fetch(url)).arrayBuffer();
        const wb = read(f, {
          cellText: false,
          cellDates: true,
        });

        newJourney.data.sourceData = utils.sheet_to_json(
          wb.Sheets["source_performance"],
          {
            raw: false,
          }
        );

        const tempJourneyCompletionData = utils
          .sheet_to_json(wb.Sheets["completed_journeys_timeseries"])
          .sort(function (a, b) {
            return Date.parse(a.Date) - Date.parse(b.Date);
          });

        newJourney.data.completedJourneysData = {
          dates: tempJourneyCompletionData
            .map((item) => {
              return processDate(item["Date"], false)
                .split(" ")
                .reverse()
                .join(" ");
            })
            .slice(0, -1),
          m2m: tempJourneyCompletionData
            .map((item) => {
              return Math.round(item["M2M Journeys Completed"]);
            })
            .map(
              (
                (sum) => (value) =>
                  (sum += value)
              )(0)
            )
            .slice(0, -1),
          core: tempJourneyCompletionData
            .map((item) => {
              return Math.round(item["Core Journeys Completed"]);
            })
            .map(
              (
                (sum) => (value) =>
                  (sum += value)
              )(0)
            )
            .slice(0, -1),
        };

        const tempJourneyComps = [];
        for(let i = 0; i <newJourney.data.completedJourneysData.dates.length; i++){
          tempJourneyComps.push({date:newJourney.data.completedJourneysData.dates[i],
          m2m: newJourney.data.completedJourneysData.m2m[i],
          core: newJourney.data.completedJourneysData.core[i] })
        }
        newJourney.data.completedJourneysData = tempJourneyComps;
        const funnelSheet = utils.sheet_to_json(wb.Sheets["funnel_data"]);

        let tempFunnelData = [];

        for (const row of funnelSheet) {
          const date = processDate(row["Date"]);

          let found = false;
          for (const funnel of tempFunnelData) {
            if (funnel["Date"] === date) {
              funnel.data.push(row);
              found = true;

              break;
            }
          }

          if (!found) {
            tempFunnelData.push({
              Date: date,
              data: [row],
            });
          }
        }

        newJourney.data.funnelData = tempFunnelData.sort(function (a, b) {
          return Date.parse(b.Date) - Date.parse(a.Date);
        });

        const journeySheetNames = wb.SheetNames.filter((name) => {
          return name.startsWith("Journey");
        });

        let tempJourneyData = [];

        for (const journey of journeySheetNames) {
          const tempJourney = {
            platformName: journey.split("_").at(-1),
            adsets: [],
          };

          const tempJourneySheet = utils.sheet_to_json(wb.Sheets[journey]);

          for (const layer of tempJourneySheet) {
            tempJourney.adsets.push({
              name: layer["Layer"],
              data: {
                CPM: {
                  Benchmark: layer["Benchmark_CPM"],
                  Actual: layer["Actual_CPM"],
                },
                CPC: {
                  Benchmark: layer["Benchmark_CPC"],
                  Actual: layer["Actual_CPC"],
                },
                CTR: {
                  Benchmark: layer["Benchmark_CTR"],
                  Actual: layer["Actual_CTR"],
                },
              },
            });
          }

          tempJourneyData.push(tempJourney);
        }

        newJourney.data.journeyData = tempJourneyData;

        setJourneyData((prevState) => {
          prevState[newJourney.title] = newJourney.data;
          return prevState;
        });

        if (journeyIndex === 0) {
          setSelectedJourney(journeyFile);
        }
      });
    }
  }, []);

  const totalCoords = { x: 120, y: 250 };
  const engagedCoords = { x: 1045, y: 355 };
  const nurturedCoords = { x: -50, y: 535 };
  const identifiedCoords = { x: 1050, y: 660 };
  const mvsCoords = { x: 980, y: 170 };
  const fragileWidth = 350;
  const slant = { x: 60, y: 50 };
  const [seconds, setSeconds] = useState(0);
  //console.log(journeyData[selectedJourney] ? journeyData[selectedJourney].funnelData : null)
  return (
    <div className={styles.main}>
      <div className={styles.audienceAnalyticsContainer}>
        <div className={styles.audienceTitle}>JOURNEY AUDIENCE ANALYTICS</div>
        {audienceData && (
          <div className={styles.bottle}>
            {bottleUrl && (
              <ReactPlayer
                width="972px"
                height="590px"
                playing={seconds < 15}
                autoPlay
                url={bottleUrl}
                onProgress={(e) => {
                  setSeconds(e.playedSeconds);
                }}
                muted
                config={{
                  file: {
                    attributes: {
                      autoPlay: true,
                      muted: true,
                    },
                  },
                }}
                onStart={() => {
                  setIsPlaying(true);
                }}
              />
            )}
            <svg className={styles.milksvg} viewBox="0 0 1000 1000">
              <text
                className={
                  isPlaying
                    ? `${styles.milkBold} ${styles.totalText}`
                    : styles.hidden
                }
                textAnchor="middle"
                x={totalCoords.x}
                y={totalCoords.y - 90}
              >
                TOTAL CANADIANS IN OUR
              </text>
              <text
                className={
                  isPlaying
                    ? `${styles.milkBold} ${styles.totalText}`
                    : styles.hidden
                }
                textAnchor="middle"
                x={totalCoords.x}
                y={totalCoords.y - 50}
              >
                DAIRY ECOSYSTEM JOURNEY
              </text>
              <text
                className={
                  isPlaying
                    ? `${styles.milkNormal} ${styles.totalText}`
                    : styles.hidden
                }
                textAnchor="middle"
                x={totalCoords.x}
                y={totalCoords.y - 10}
              >
                {formatMetric(audienceData[0]["Total"])} CANADIANS
              </text>
              <path
                className={
                  isPlaying ? `${styles.path} ${styles.mvsPath}` : styles.hidden
                }
                fill="none"
                d={`M${mvsCoords.x + 140} ${mvsCoords.y} L${
                  mvsCoords.x - fragileWidth
                } ${mvsCoords.y} L${mvsCoords.x - fragileWidth - slant.x} ${
                  mvsCoords.y + slant.y
                }`}
              />
              <text
                className={
                  isPlaying
                    ? `${styles.milkBold} ${styles.mvsText}`
                    : styles.hidden
                }
                textAnchor="start"
                x={mvsCoords.x - fragileWidth + 20}
                y={mvsCoords.y - 10}
              >
                JOURNEY COMPLETERS (MVS)
              </text>
              <text
                className={
                  isPlaying
                    ? `${styles.milkNormal} ${styles.mvsText}`
                    : styles.hidden
                }
                textAnchor="start"
                x={mvsCoords.x - fragileWidth + 100}
                y={mvsCoords.y + 30}
              >
                {formatMetric(audienceData[0]["Fragile Engaged"])} CANADIANS
              </text>

              <text
                className={
                  isPlaying
                    ? `${styles.milkNormal} ${styles.fragileEngagedText}`
                    : styles.hidden
                }
                textAnchor="start"
                x={engagedCoords.x - fragileWidth + 20}
                y={engagedCoords.y + 30}
              >
                {formatMetric(audienceData[0]["Fragile Engaged"])} CANADIANS
              </text>

              <path
                className={
                  isPlaying
                    ? `${styles.path} ${styles.fragileEngagedPath}`
                    : styles.hidden
                }
                fill="none"
                d={`M${engagedCoords.x} ${engagedCoords.y} L${
                  engagedCoords.x - fragileWidth
                } ${engagedCoords.y} L${
                  engagedCoords.x - fragileWidth - slant.x
                } ${engagedCoords.y - slant.y}`}
              />
              <text
                className={
                  isPlaying
                    ? `${styles.milkBold} ${styles.fragileEngagedText}`
                    : styles.hidden
                }
                textAnchor="start"
                x={engagedCoords.x - fragileWidth + 20}
                y={engagedCoords.y - 10}
              >
                FRAGILE ENGAGED
              </text>
              <text
                className={
                  isPlaying
                    ? `${styles.milkNormal} ${styles.fragileEngagedText}`
                    : styles.hidden
                }
                textAnchor="start"
                x={engagedCoords.x - fragileWidth + 20}
                y={engagedCoords.y + 30}
              >
                {formatMetric(audienceData[0]["Fragile Engaged"])} CANADIANS
              </text>

              <path
                className={
                  isPlaying
                    ? `${styles.path} ${styles.fragileNurturedPath}`
                    : styles.hidden
                }
                fill="none"
                d={`M${nurturedCoords.x} ${nurturedCoords.y} L${
                  nurturedCoords.x + fragileWidth
                } ${nurturedCoords.y} L${
                  nurturedCoords.x + fragileWidth + slant.x
                } ${nurturedCoords.y - slant.y}`}
              />

              <text
                className={
                  isPlaying
                    ? `${styles.milkBold} ${styles.fragileNurturedText}`
                    : styles.hidden
                }
                textAnchor="start"
                x={nurturedCoords.x + 20}
                y={nurturedCoords.y - 10}
              >
                FRAGILE NURTURED
              </text>
              <text
                className={
                  isPlaying
                    ? `${styles.milkNormal} ${styles.fragileNurturedText}`
                    : styles.hidden
                }
                textAnchor="start"
                x={nurturedCoords.x + +20}
                y={nurturedCoords.y + 30}
              >
                {formatMetric(audienceData[0]["Fragile Nurtured"])} CANADIANS
              </text>

              <path
                className={
                  isPlaying
                    ? `${styles.path} ${styles.fragileIdentifiedPath}`
                    : styles.hidden
                }
                fill="none"
                d={`M${identifiedCoords.x} ${identifiedCoords.y} L${
                  identifiedCoords.x - fragileWidth
                } ${identifiedCoords.y} L${
                  identifiedCoords.x - fragileWidth - slant.x
                } ${identifiedCoords.y - slant.y}`}
              />
              <text
                className={
                  isPlaying
                    ? `${styles.milkBold} ${styles.fragileIdentifiedText}`
                    : styles.hidden
                }
                textAnchor="start"
                x={identifiedCoords.x - fragileWidth + 20}
                y={identifiedCoords.y - 10}
              >
                FRAGILE IDENTIFIED
              </text>
              <text
                className={
                  isPlaying
                    ? `${styles.milkNormal} ${styles.fragileIdentifiedText}`
                    : styles.hidden
                }
                textAnchor="start"
                x={identifiedCoords.x - fragileWidth + 20}
                y={identifiedCoords.y + 30}
              >
                {formatMetric(audienceData[0]["Fragile Identified"])} CANADIANS
              </text>
            </svg>
          </div>
        )}
        <div className={styles.audienceSummary}>
          <p className={styles.fragileTitle}>
            TOTAL 2023 ADDRESSABLE FRAGILE AUDIENCE
          </p>
          <div className={styles.fragileText}>
            <p className={styles.statText}>3.3MM Canadians</p>
          </div>
          <p className={styles.platformAudiences}>PLATFORM AUDIENCES</p>
          <table className={styles.audienceTable}>
            <tbody>
              <tr>
                <td className={styles.platformTitle}>GOOGLE</td>
                <td className={styles.platformNumber}>
                  {formatMetric(platformAudienceData["Google"])}
                </td>
              </tr>
              <tr>
                <td className={styles.platformTitle}>TIKTOK</td>
                <td className={styles.platformNumber}>
                  {formatMetric(platformAudienceData["TikTok"])}
                </td>
              </tr>
              <tr>
                <td className={styles.platformTitle}>META</td>
                <td className={styles.platformNumber}>
                  {formatMetric(platformAudienceData["Meta"])}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      { false && <div style={{ width: "70%", marginTop: "20px", marginInline: "auto" }}>
        <img style={{ marginLeft: "auto" }} src="targetingrules.png" />
      </div>}
      

      
       {audienceData2 && (
        <div className={styles.audienceGrowthContainer}>
          <StackedBarChart
          xDataKey = {"label"}
            title={"Audience Growth"}
            data={audienceData2}
            dataKeys = {[{name: audienceData2[0].previous_date ,key: "previous_value", fill:"rgba(159, 197, 232,0.8)" },{name: audienceData2[0].current_date,key: "current_delta", fill:"rgba(43, 121, 190,0.8)" }]}
            
          />
        </div>
      )}

      <div className={styles.journeyTitleContainer}>
        <div className={styles.journeySelector}>
          <span style={{ fontWeight: 800, marginRight: "5px" }}>Select a Journey: </span>
          <Select
            options={Object.keys(journeyData).map((journey) => {
              return { value: journey, label: journey };
            })}
            onChange={(selectedOption) => {
              setSelectedJourney(selectedOption.value);
            }}
            value={{ label: selectedJourney, value: selectedJourney }}
            menuPortalTarget={document.querySelector("body")}
            styles={Constants.SELECT_STYLE("500px", "40px", "1rem", "1rem")}
          />
        </div>
        <div className={styles.journeyHeader}>
          <div className={styles.journeyTitle}>{selectedJourney}</div>
          <img style={{ width: "60%" }} src={headerImageUrl} />
        </div>
      </div>
      <div className={styles.tableContainer}>
        {journeyData[selectedJourney] &&
          journeyData[selectedJourney].journeyData.map((journey) => {
            return <DataTable data={journey} key={journey.platformName} />;
          })}
      </div>

      {journeyData[selectedJourney] && (
        <div className={styles.funnelContainer}>
          {journeyData[selectedJourney].funnelData[0].data.map((item) => {
            return (
              <FunnelCard
                {...item}
                key={item.Name}
                prevData={journeyData[selectedJourney].funnelData[1]}
              />
            );
          })}
        </div>
      )}

      {/*questionData && false && (
        <div className={styles.barContainer}>
          {questionData.map((question) => {
            return (
              <BarChartContainer
                title={question.title}
                data={question.data}
                yMax={18}
                key={question.title}
              />
            );
          })}
        </div>
        )*/}

      {journeyData[selectedJourney] && (
        <div className={styles.completedJourneysContainer}>
        <TimeSeriesChart
          title={"Core vs M2M Journeys Completed"}
          data={journeyData[selectedJourney].completedJourneysData}
          dataKeys = {[{dataKey:"m2m", stroke: "rgb(53, 162, 235)"}, {dataKey:"core", stroke:"rgb(255, 99, 132)"}]}
        />
        </div>
      )}

      {journeyData[selectedJourney] && (
        <SourcePerformance
          sourceData={journeyData[selectedJourney].sourceData}
        />
      )}
    </div>
  );
}

export default withAuthenticator(Journeys, { hideSignUp: true });
