import styles from "./AssetLibrary.module.css";

import Carousel from "./Components/AssetCarousel/Carousel";
import AssetModal from "./Components/Asset/AssetModal";
import SettingsMenu from "./Components/Settings/SettingsMenu.js";
import AddFilterModal from "./Components/Settings/AddFilterModal.js";

import { groupBy } from "../../Util/groups";
import { evaluateFilter } from "../../Util/filter";
import { dynamicSortMultiple } from "../../Util/sort.js";
import * as Constants from "../../constants";

import { v4 as uuidv4 } from "uuid";

import { useState, useMemo, useEffect } from "react";

import "@aws-amplify/ui-react/styles.css";
import { withAuthenticator } from "@aws-amplify/ui-react";

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { isSortValueNull } from "../../Util/util";
import PaidOrganicSelector from "./Components/Settings/PaidOrganicSelector";

function AssetLibrary({ assetData, categoricalOptions }) {
  const [showAssetModal, setShowAssetModal] = useState(false);
  const [paidOrganic, setPaidOrganic] = useState("Both");

  const [currentAsset, setCurrentAsset] = useState(null);
  const [group, setGroup] = useState("Campaign");
  const [sort, setSort] = useState([
    ["CPM", 1],
    ["None", 1],
  ]);

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [initialFilterId, setInitialFilterId] = useState(null);
  const [filters, setFilters] = useState([
    /* {
      id: uuidv4(),
      name: "Spend >= 1500",
      orClauses: [
        {
          id: uuidv4(),
          clauses: [
            {
              id: uuidv4(),
              clause_data: {
                field: "Spend",
                comparisonType: {
                  value: Constants.FILTER_TYPES.NUMERIC_GREATER_THAN_OR_EQUAL,
                  label: "GREATER THAN OR EQUAL TO",
                },
                comparisonValue: "1500",
                comparisonValue2: "",
                isValid: true,
              },
            },
            
          ],
        },

      ],
    },
    {
      id: uuidv4(),
      name: "Audience = M2M",
      orClauses: [
        {
          id: uuidv4(),
          clauses: [
            {
              id: uuidv4(),
              clause_data: {
                field: "Audience",
                comparisonType: {
                  value: Constants.FILTER_TYPES.CATEGORICAL_EQUAL,
                  label: "EQUAL TO",
                },
                comparisonValue: "M2M",
                comparisonValue2: "",
                isValid: true,
              },
            },
          ],
        },
      ],
    },*/
  ]);

  const [thumbnailsPerPage, setThumbnailsPerPage] = useState(5);

  const [showSettings, setShowSettings] = useState(true);

  const groups = useMemo(() => groupBy(assetData, group), [assetData, group]);

  function closeAssetModal() {
    setShowAssetModal(false);
  }
  function onAssetClick(data) {
    setCurrentAsset(data);
    setShowAssetModal(true);
  }

  function closeFilterModal() {
    setInitialFilterId(null);
    setShowFilterModal(false);
  }

  const handleGroupChange = (selectedOption) => {
    setGroup(selectedOption.value);
  };

  const handlePrimarySortChange = (selectedOption) => {
    setSort((prevState) => {
      if (selectedOption.value === "None") {
        return [prevState[1], ["None", 1]];
      } else {
        return [[selectedOption.value, prevState[0][1]], prevState[1]];
      }
    });
  };
  const handlePaidOrganicChange = (selectedOption) => {
    setPaidOrganic((prevState) => {
      if (selectedOption.value === "Organic"){
        setSort([["Start Date", 1], ["None",1]])
      }
      return selectedOption.value;
    });
  };

  const handleSecondarySortChange = (selectedOption) => {
    setSort((prevState) => {
      return [prevState[0], [selectedOption.value, prevState[1][1]]];
    });
  };

  const handleThumbnailChange = (e) => {
    setThumbnailsPerPage(e.target.value);
  };

  const handlePrimarySortDirection = (selectedOption) => {
    setSort((prevState) => {
      return [[prevState[0][0], 1 - prevState[0][1]], prevState[1]];
    });
  };

  const handleSecondarySortDirection = (selectedOption) => {
    setSort((prevState) => {
      return [prevState[0], [prevState[1][0], 1 - prevState[1][1]]];
    });
  };

  const handleRemoveFilter = (id) => {
    setFilters((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });
  };

  const handleToggleMenu = () => {
    setShowSettings((prevState) => {
      return !prevState;
    });
  };

  const handleAddFilter = (name, newFilter) => {

    if (filters.filter((filter) => newFilter.id === filter.id).length > 0) {
      

      setFilters((prevState) => {
        return prevState.map((filter) => {
          return filter.id === newFilter.id
            ? { ...newFilter, name: name }
            : filter;
        });
      });
    } else {
      
      
      setFilters((prevState) => {
        
        return [...prevState, { ...newFilter, name: name }];
      });
    }
  };

  const handleEditFilter = (id) => {
    setInitialFilterId(id);
    setShowFilterModal(true);
  };

  useEffect(() => {
    if (showAssetModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showAssetModal]);

  useEffect(() => {
    if (showFilterModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showFilterModal]);


  
  
  return (
    <div className={styles["main"]}>
      <div className={styles.header}>
        <PaidOrganicSelector
          handlePaidOrganicChange={handlePaidOrganicChange}
        />
        <FontAwesomeIcon
          data-tooltip-id="toggle-settings"
          icon={faGear}
          onClick={handleToggleMenu}
          size="2x"
          className={styles["settingsIcon"]}
        />

        <Tooltip id="toggle-settings">
          {showSettings ? "Hide Settings" : "Show Settings"}
        </Tooltip>
      </div>

      {showSettings && (
        <SettingsMenu
          groupByOptions={Constants.GROUPBY_OPTIONS()}
          handleGroupChange={handleGroupChange}
          onShowFilterModal={() => setShowFilterModal(true)}
          handlePrimarySortChange={handlePrimarySortChange}
          handlePrimarySortDirection={handlePrimarySortDirection}
          handleSecondarySortChange={handleSecondarySortChange}
          handleSecondarySortDirection={handleSecondarySortDirection}
          handleThumbnailChange={handleThumbnailChange}
          thumbnailsPerPage={thumbnailsPerPage}
          sort={sort}
          filters={filters}
          setFilter={setFilters}
          onRemoveFilter={handleRemoveFilter}
          onAdd={handleAddFilter}
          hidden={showSettings}
          onEdit={handleEditFilter}
          showCarouselSize={true}
        />
      )}

      {showAssetModal && (
        <AssetModal
          data={currentAsset}
          onClose={closeAssetModal}
          filteredData={assetData
            .filter((asset) => {
              if (paidOrganic === "Both") return true;
              else return asset["Paid/Organic"] === paidOrganic;
            })
            .filter((asset) => evaluateFilter(asset, filters))}
        />
      )}

      {showFilterModal && (
        <AddFilterModal
          FIELD_TYPES={Constants.FIELD_TYPES}
          FILTER_TYPES={Constants.FILTER_TYPES}
          onClose={closeFilterModal}
          onAdd={handleAddFilter}
          initialFilter={
            initialFilterId === null
              ? null
              : filters.filter((filter) => {
                  return filter.id === initialFilterId;
                })[0]
          }
          categoricalOptions={categoricalOptions}
        />
      )}
      <div className={styles["carousel-container"]}>
        {groups.map((item) => (
          <Carousel
            assetData={item.data
              .filter((asset) => {
                if (paidOrganic === "Both") return true;
                else return asset["Paid/Organic"] === paidOrganic;
              })
              .filter((asset) => isSortValueNull(asset, sort[0][0]))
              .filter((asset) => isSortValueNull(asset, sort[1][0]))
              .sort(dynamicSortMultiple(...sort))
              .filter((asset) => evaluateFilter(asset, filters))}
            
            sort={sort}
            group = {group}
            title={item.group}
            key={item.id + sort + filters + Math.random()}
            onClick={onAssetClick}
            thumbnailsPerPage={thumbnailsPerPage}
            height = {"500px"}
          />
        ))}
      </div>
    </div>
  );
}

export default withAuthenticator(AssetLibrary, { hideSignUp: true });
