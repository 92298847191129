import styles from "./CarouselModal.module.css";
import Carousel from "../../AssetLibrary/Components/AssetCarousel/Carousel";

import { Fragment } from "react";
import { isSortValueNull } from "../../../Util/util";
import { dynamicSort } from "../../../Util/sort";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
function CarouselModal(props) {
  return (
    <Fragment>
      <div className={styles["backdrop"]} onClick={props.onClose}></div>
      <div className={styles["modal"]}>
        <div className={styles["close-button"]}>
          <FontAwesomeIcon
            icon={faXmark}
            size="2x"
            onClick={props.onClose}
            data-tooltip-id="close-asset-modal"
            data-tooltip-content="Close"
          />
          <Tooltip id="close-asset-modal" />
        </div>

        {props.carouselFilter.group && (
          <Carousel
            assetData={props.assetData
              .filter((asset) => {
                return (
                  asset[props.carouselFilter.group] ===
                  props.carouselFilter.value
                );
              })
              .filter((asset) =>
                isSortValueNull(asset, props.carouselFilter.metric)
              )
              .sort(dynamicSort([props.carouselFilter.metric, 1]))}
            sort={[
              [props.carouselFilter.metric, 1],
              ["None", null],
            ]}
            title={`${props.carouselFilter.group} = ${props.carouselFilter.value}`}
            onClick={props.onAssetClick}
            thumbnailsPerPage={5}
            maxHeight={"300px"}
          />
        )}
      </div>
    </Fragment>
  );
}

export default CarouselModal;
