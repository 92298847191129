import styles from "./ThumbnailTitle.module.css";
import PlatformIcon from "./Icons/PlatformIcon";
const ThumbnailTitle = (props) => {
  return (
    <div className={styles["title-container"]}>
      {!props.hideTitleIcons && <div
        style={{
          width: "100%",
          position: "absolute",
          top: "0",
          left: "0",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          
        }}
      >
        <div className={styles.platformIcon} title={props["Platform"]}>
          <PlatformIcon
            platform={props.Platform}
            width="1.25rem"
            height="1.25rem"
          />
        </div>
        <div
          className={styles.organicPaidLabel}
          style={{ maxHeight: `${props.maxHeight}` }}
          title={props["Paid/Organic"]}
        >
          {props["Paid/Organic"] === "Paid" ? "$" : "O"}
        </div>
      </div>}
      <div style={{ marginInline: "auto", width: "70%", height: "100%"}}>
        <h1 style={{ fontSize: props.fontSize ? `${props.fontSize}` : "1rem" }}>
          <span
            className={
              props.Language === "English"
                ? styles["language-english"]
                : styles["language-french"]
            }
          >
            ({props.Language === "English" ? "EN" : "FR"})
          </span>{" "}
          {props.Name.trim()}
        </h1>
        <h2
          style={{ fontSize: props.fontSize2 ? `${props.fontSize2}` : ".7rem" }}
        >
          {props.Campaign}
        </h2>
      </div>
    </div>
  );
};

export default ThumbnailTitle;
