import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import styles from "./PieChart.module.css"


import ChartDataLabels from "chartjs-plugin-datalabels";


ChartJS.register(ArcElement, Tooltip, Legend);


 function PieChart(props) {

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                display: true,
                color: "white",
                formatter: function(value, context){
                    return value + " " + context.chart.data.labels[context.dataIndex] + "\n" + "(" + Math.round(value/context.dataset.data.reduce((sum, a) => a + sum, 0)*100) + "%)";
                }
              },
          legend: {
            display: false,
            position: "bottom",
            reverse: true,
          },
          title: {
            display: true,
            text: props.title,
            font: {
              size: 12,
            },
          },
        },
      };

      

  return (
    <div className={styles["pieChartContainer"]}>
      <Pie options = {options} data={props.data} plugins={[ChartDataLabels]}/>
    </div>
  );
}

export default PieChart;

