export function sumMetric(assetData, metric, precision = 0, dollar = false) {
  return (
    (dollar ? "$" : "") +
    assetData
      .reduce((sum, asset) => {
        return sum + asset[metric];
      }, 0)
      .toLocaleString("en-US", {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      })
  );
}

export function getDaisySummaryData(data, dimension = "None", value = "None", name = "Total") {
  
  const filteredData =
    dimension === "None"
      ? data
      : data.filter((item) => item[dimension] === value);
  
  const numPosts = filteredData.length;
  const numViews = sumMetric(filteredData, "Views");
  const numLikes = sumMetric(filteredData, "Likes");
  const numComments = sumMetric(filteredData, "Comments");
  const numShares = sumMetric(filteredData, "Shares");
  const numProfileVisits = sumMetric(filteredData, "Paid Profile Visits");
  const numFollowers = sumMetric(filteredData, "Paid Followers");
  const totalSpend = sumMetric(filteredData, "Spend", 2, true);


  return {
    name: name,
    numPosts: numPosts,
    numViews: numViews,
    numLikes: numLikes,
    numComments: numComments,
    numShares: numShares,
    numProfileVisits: numProfileVisits,
    numFollowers: numFollowers,
    totalSpend: totalSpend,
  }
}
