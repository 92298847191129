import styles from "./Q3Media.module.css";

import { useState, useEffect } from "react";

import useGoogleSheets from "use-google-sheets";
import DatePicker from "react-datepicker";
import { v4 as uuidv4 } from "uuid";
import SortableFormatTable from "../../Components/SortableFormattedTable/SortableFormatTable";
import MediaReport from "./Components/MediaReport";
import PlatformIcon from "../AssetLibrary/Components/Thumbnail/Icons/PlatformIcon";
import { formatData } from "../../Util/util";
const sheetsOptions = [
  { id: "Meta - Awareness" },
  { id: "Meta - Engagement" },
  { id: "TikTok - Awareness" },
  { id: "TikTok - Video Views" },
];

const DATA_COLUMNS = {
  meta: {
    date: "Date",
    campaign_name: "Campaign name",
    campaign_id: "Campaign ID",
    ad_set_name: "Ad set name",
    ad_set_id: "Ad set ID",
    reach: "Reach",
    impressions: "Impressions",
    spend: "Cost",
    clicks: "Clicks (all)",
    video_views: "Three-second video views",
    video_completions: "Video watches at 100%",
  },
  tiktok: {
    date: "Date",
    campaign_name: "Campaign name",
    campaign_id: "Campaign ID",
    ad_set_name: "Ad group name",
    ad_set_id: "Ad group ID",
    reach: "Reach",
    impressions: "Impressions",
    spend: "Cost",
    clicks: "Clicks",
    video_views: "2-second video views",
    video_completions: "Video views at 100%",
  },
};
const columns = [
  {
    label: ["Ad Set Name"],
    accessor: "group",
    callback: (name) => {return name.split('DFC Q3 2023 ')[1]},
    sortable: true,
    style: { fontSize: ".5rem", minWidth: "100%" },
  },
  {
    label:  ["Spend"],
    accessor: "Spend",
    sortable: true,
    format: { format: "$", precision: 2 },
  },
  {
    label:  ["Impressions"],
    accessor: "Impressions",
    sortable: true,
    format: { format: "", precision: 0 },
  },
  {
    label:  ["Clicks"],
    accessor: "Link Clicks",
    sortable: true,
    format: { format: "", precision: 0 },
  },
  {
    label:  ["CPM"],
    accessor: "CPM",
    sortable: true,
    format: { format: "$", precision: 2 },
  },
  {
    label:  ["CTR"],
    accessor: "CTR",
    sortable: true,
    format: { format: "%%", precision: 4 },
  },
  {
    label:  ["Video Views","(3s Meta - 2s TT)"],
    accessor: "Video Views",
    sortable: true,
    format: { format: "", precision: 0 },
  },
  {
    label:  ["Video Completions"],
    accessor: "Video Completions",
    sortable: true,
    format: { format: "", precision: 0 },
  },
  {
    label:  ["VCR"],
    accessor: "VCR",
    sortable: true,
    format: { format: "%%", precision: 4 },
  },
  {
    label:  ["CPCV"],
    accessor: "CPCV",
    sortable: true,
    format: { format: "$", precision: 2 },
  },
  
];

function groupBy(data, group, platform) {
  const unique = Array.from(
    new Set(data.map((item) => item[DATA_COLUMNS[platform][group]]))
  )
    .sort()
    .reverse();

  let groups = [];

  for (const key of unique) {
    groups.push({
      [group]: key ? key : "N/A",
      data: data.filter((item) => item[DATA_COLUMNS[platform][group]] === key),
      id: uuidv4(),
    });
  }
  return groups;
}

function aggregate(data, group, platform) {
  const unique = Array.from(
    new Set(data.map((item) => item[DATA_COLUMNS[platform][group]]))
  )
    .sort()
    .reverse();

  let groups = [];

  for (const key of unique) {
    groups.push({
      group: key ? key : "N/A",
      data: data.filter((item) => item[DATA_COLUMNS[platform][group]] === key),
      id: uuidv4(),
    });
  }

  groups = groups.map((group) => ({
    ...group,
    Spend: group.data.reduce((sum, row) => {
      return sum + Number(row[DATA_COLUMNS[platform].spend]);
    }, 0),
    "Link Clicks": group.data.reduce((sum, row) => {
      return sum + Number(row[DATA_COLUMNS[platform].clicks]);
    }, 0),
    Impressions: group.data.reduce((sum, row) => {
      return sum + Number(row[DATA_COLUMNS[platform].impressions]);
    }, 0),
    "Video Views": group.data.reduce((sum, row) => {
      return sum + Number(row[DATA_COLUMNS[platform].video_views]);
    }, 0),
    "Video Completions": group.data.reduce((sum, row) => {
      return sum + Number(row[DATA_COLUMNS[platform].video_completions]);
    }, 0),
  }));
  groups = groups.map((group) => ({
    ...group,
    CPM:
      group["Impressions"] > 0
        ? (group["Spend"] / group["Impressions"]) * 1000
        : null,
    CPC:
      group["Link Clicks"] > 0 ? group["Spend"] / group["Link Clicks"] : null,
    CTR:
      group["Impressions"] > 0
        ? group["Link Clicks"] / group["Impressions"]
        : null,
    VCR:
      group["Impressions"] > 0
        ? group["Video Completions"] / group["Video Views"]
        : null,
    CPCV:
      group["Video Completions"] > 0
        ? group["Spend"] / group["Video Completions"]
        : null,
  }));

  return groups;
}

const Q3Media = (props) => {
  const [startDate, setStartDate] = useState(new Date("October 23, 2023"));
  const [endDate, setEndDate] = useState(new Date());
  const [q3Data, setQ3Data] = useState(null);
  const [filteredData, setFilteredData] = useState(null);

  const [tiktokCPM, setTiktokCPM] = useState(null);
  const [metaCPM, setMetaCPM] = useState(null);
  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const { data, loading, error } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_Q3_GOOGLE_SHEETS_ID,
    sheetsOptions,
  });

  useEffect(() => {
    if (!loading && startDate && endDate) {
      const q3 = {};
      for (const obj of data) {
        const newData = [];
        for (const row of obj.data) {
          const newRow = { ...row, Date: new Date(row["Date"]) };
          if (
            newRow["Date"].toISOString().split("T")[0] >=
              startDate.toISOString().split("T")[0] &&
            newRow["Date"].toISOString().split("T")[0] <=
              endDate.toISOString().split("T")[0]
          ) {
            newData.push(newRow);
          }
        }
        q3[obj.id] = newData;
      }
      
      setQ3Data(q3);
    }
  }, [loading, startDate, endDate]);
  const platformCPMs = {
    tiktok: null,
    meta: null,
  };
  if (q3Data) {
    
    const metaCost = q3Data["Meta - Awareness"].reduce((sum, row) => {
      return sum + Number(row[DATA_COLUMNS["meta"].spend]);
    }, 0);
    const metaImpressions = q3Data["Meta - Awareness"].reduce((sum, row) => {
      return sum + Number(row[DATA_COLUMNS["meta"].impressions]);
    }, 0);

    platformCPMs.meta = (metaCost / metaImpressions) * 1000;

    const tiktokCost = q3Data["TikTok - Awareness"].reduce((sum, row) => {
      return sum + Number(row[DATA_COLUMNS["tiktok"].spend]);
    }, 0);
    const tiktokImpressions = q3Data["TikTok - Awareness"].reduce(
      (sum, row) => {
        return sum + Number(row[DATA_COLUMNS["tiktok"].impressions]);
      },
      0
    );

    platformCPMs.tiktok = (tiktokCost / tiktokImpressions) * 1000;
  }

  /*  useEffect(() => {
    if(q3Data && startDate && endDate){
      
      setFilteredData(q3Data["Meta - Ad Set"].filter((row) => {     
                
        return row['Date'].toISOString().split("T")[0] >= startDate.toISOString().split("T")[0] && 
        row['Date'].toISOString().split("T")[0] <= endDate.toISOString().split("T")[0] 
      }));
    }
  }, [startDate, endDate]
  )*/
  /* if (q3Data) {
    console.log("Q3 Data");
    console.log(q3Data);
    console.log("\n Aggregate Test");
    console.log(aggregate(q3Data["Meta - Ad Set"], "ad_set_name", "meta"));
    filteredData = q3Data["Meta - Ad Set"].filter((row) => {
      console.log(row['Date'].toISOString().split("T")[0] )
      console.log(startDate.toISOString().split("T")[0] )
      return row['Date'].toISOString().split("T")[0] >= startDate.toISOString().split("T")[0] && 
      row['Date'].toISOString().split("T")[0] <= endDate.toISOString().split("T")[0] 
    });
  }*/

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error!</div>;
  }

  

  return (
    <div className={styles.main}>
      {q3Data && (
        <div className={styles.awarenessContainer}>
          <h1>Q3 2023 AWARENESS SAVINGS CALCULATIONS</h1>
          <table className={styles.cpmTable}>
            <tbody>
              <tr>
                <th>PLATFORM</th>
                <th>TIMEFRAME</th>
                <th>AWARENESS (CPM) COST</th>
                <th>Q3 CURRENT (CPM) COST</th>
                <th>PERCENTAGE SAVINGS</th>
              </tr>

              <tr>
                <td className={styles.platformCell} rowSpan="1">
                  <div className={styles.platformContainer}>
                    <PlatformIcon
                      width="3rem"
                      height="3rem"
                      platform={"TikTok"}
                    />
                    {"TikTok"}
                  </div>
                </td>
                <td>HISTORICAL AVERAGE</td>
                <td>
                  <span className={styles.lightred}>{"$4.33"}</span>
                </td>
                <td>
                  <span className={styles.lightgreen}>
                    {formatData(platformCPMs.tiktok, "$", 2)}
                  </span>
                </td>
                <td>
                  <span className={styles.brightgreen}>
                    {formatData((platformCPMs.tiktok - 4.33) / 4.33, "%%", 0)}
                  </span>
                </td>
              </tr>
              <tr>
                <td className={styles.platformCell} rowSpan="1">
                  <div className={styles.platformContainer}>
                    <PlatformIcon
                      width="3rem"
                      height="3rem"
                      platform={"TikTok"}
                    />
                    {"TikTok"}
                  </div>
                </td>
                <td>Q3 2022</td>
                <td>
                  <span className={styles.lightred}>{"$4.16"}</span>
                </td>
                <td>
                  <span className={styles.lightgreen}>
                    {formatData(platformCPMs.tiktok, "$", 2)}
                  </span>
                </td>
                <td>
                  <span className={styles.brightgreen}>
                    {formatData((platformCPMs.tiktok - 4.16) / 4.16, "%%", 0)}
                  </span>
                </td>
              </tr>

              <tr>
                <td className={styles.platformCell} rowSpan="1">
                  <div className={styles.platformContainer}>
                    <PlatformIcon
                      width="3rem"
                      height="3rem"
                      platform={"Meta"}
                    />
                    {"Meta"}
                  </div>
                </td>
                <td>HISTORICAL AVERAGE</td>
                <td>
                  <span className={styles.lightred}>{"$7.29"}</span>
                </td>
                <td>
                  <span className={styles.lightgreen}>
                    {formatData(platformCPMs.meta, "$", 2)}
                  </span>
                </td>
                <td>
                  <span className={styles.brightgreen}>
                    {formatData((platformCPMs.meta - 7.29) / 7.29, "%%", 0)}
                  </span>
                </td>
              </tr>

              <tr>
                <td className={styles.platformCell} rowSpan="1">
                  <div className={styles.platformContainer}>
                    <PlatformIcon
                      width="3rem"
                      height="3rem"
                      platform={"Meta"}
                    />
                    {"Meta"}
                  </div>
                </td>
                <td>Q3 2022</td>
                <td>
                  <span className={""}>N/A</span>
                </td>
                <td>
                  <span className={""}>
                    {formatData(platformCPMs.meta, "$", 2)}
                  </span>
                </td>
                <td>{"N/A"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {false && <div className={styles.datePickerWrapper}>
        <DatePicker
          selected={startDate}
          onChange={onDateChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          className={styles.datePicker}
        />
      </div>}

      <div className={styles.tableContainer}>
        {q3Data && (
          <div className={styles.tableColumn}>
            <MediaReport
              data={aggregate(
                q3Data["Meta - Awareness"],
                "ad_set_name",
                "meta"
              )}
              timeseriesData={groupBy(
                q3Data["Meta - Awareness"],
                "ad_set_name",
                "meta"
              )}
              title="Meta - Awareness"
              columns={columns}
              lastRow= {aggregate(q3Data["Meta - Awareness"], 'campaign_name', 'meta')}
              lastRowTitle = {"TOTAL"}
            />
             <MediaReport
              data={aggregate(
                q3Data["Meta - Engagement"],
                "ad_set_name",
                "meta"
              )}
              timeseriesData={groupBy(
                q3Data["Meta - Engagement"],
                "ad_set_name",
                "meta"
              )}
              title="Meta - Engagement"
              columns={columns}
              lastRow= {aggregate(q3Data["Meta - Engagement"], 'campaign_name', 'meta')}
              lastRowTitle = {"TOTAL"}
            />
          </div>
        )}
        {q3Data && (
          <div className = {styles.tableColumn}>
          <MediaReport
            data={aggregate(
              q3Data["TikTok - Awareness"],
              "ad_set_name",
              "tiktok"
            )}
            timeseriesData={groupBy(
              q3Data["TikTok - Awareness"],
              "ad_set_name",
              "tiktok"
            )}
            title="TikTok - Awareness"
            columns={columns}
            lastRow= {aggregate(q3Data["TikTok - Awareness"], 'campaign_name', 'tiktok')}
            lastRowTitle = {"TOTAL"}
          />
           <MediaReport
            data={aggregate(
              q3Data["TikTok - Video Views"],
              "ad_set_name",
              "tiktok"
            )}
            timeseriesData={groupBy(
              q3Data["TikTok - Video Views"],
              "ad_set_name",
              "tiktok"
            )}
            title="TikTok - Video Views"
            columns={columns}
            lastRow= {aggregate(q3Data["TikTok - Video Views"], 'campaign_name', 'tiktok')}
            lastRowTitle = {"TOTAL"}
          />
        </div>
        )}
      </div>

      
    </div>
  );
};

export default Q3Media;
