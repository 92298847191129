import styles from "./ChartTest2.module.css";

import BarChartContainer from "../ExecutiveSummary/Components/BarChartContainer";
import { getAggregateBarData, aggregate } from "../../Util/aggregate";
import { dynamicSortMultiple } from "../../Util/sort";

function ChartTest2({ assetData }) {
  return (
    <div className={styles.aggregates}>
      <div className={styles.main}>
        <div className={styles.container}>
          <BarChartContainer
            setCarouselFilter={null}
            setShowCarouselModal={null}
            clickable={false}
            title={`CPM by Platform`}
            data={getAggregateBarData(
              aggregate(assetData, "Platform").sort(
                dynamicSortMultiple(["CPM", 1])
              ),
              "CPM",
              "Platform"
            )}
            key={1}
          />
           <BarChartContainer
            setCarouselFilter={null}
            setShowCarouselModal={null}
            clickable={false}
            title={`CPM by Platform`}
            data={getAggregateBarData(
              aggregate(assetData, "Platform").sort(
                dynamicSortMultiple(["CPM", 1])
              ),
              "CPM",
              "Platform"
            )}
            key={132}
          />
           <BarChartContainer
            setCarouselFilter={null}
            setShowCarouselModal={null}
            clickable={false}
            title={`CPM by Platform`}
            data={getAggregateBarData(
              aggregate(assetData, "Platform").sort(
                dynamicSortMultiple(["CPM", 1])
              ),
              "CPM",
              "Platform"
            )}
            key={12}
          />
          <BarChartContainer
            setCarouselFilter={null}
            setShowCarouselModal={null}
            clickable={true}
            title={`CPM by Platform`}
            data={getAggregateBarData(
              aggregate(assetData, "Platform").sort(
                dynamicSortMultiple(["CPM", 1])
              ),
              "CPM",
              "Platform"
            )}
            key={2}
          />
        </div>
      </div>
    </div>
  );
}

export default ChartTest2;