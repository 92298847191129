import { withAuthenticator } from "@aws-amplify/ui-react";
import { v4 as uuidv4 } from "uuid";

import styles from "./RegAg.module.css";
import { useState, useEffect, useMemo, useRef } from "react";

import { getObjectUrl } from "../../Util/s3";
import { read, utils } from "xlsx";
import {
  numberToK,
  formatMetric,
  processDate,
  breakAroundSpace,
} from "../../Util/util";

import PieChart from "../Daisy/Components/PieChart";
import { groupBy } from "../../Util/groups";
import * as Constants from "../../constants";
import TimeSeriesContainer from "../Daisy/Components/TimeSeriesContainer";
import AssetModal from "../AssetLibrary/Components/Asset/AssetModal.js";
import TopAssetCard from "../ExecutiveSummary/Components/TopAssetCard.js";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import Carousel from "../AssetLibrary/Components/AssetCarousel/Carousel";

import SettingsMenu from "../AssetLibrary/Components/Settings/SettingsMenu.js";
import AddFilterModal from "../AssetLibrary/Components/Settings/AddFilterModal.js";
import { dynamicSortMultiple } from "../../Util/sort";
import { evaluateFilter } from "../../Util/filter";
import ThumbnailCard from "../AssetLibrary/Components/Thumbnail/ThumbnailCard";
import Select from "react-select";

import BarChartContainer from "../ExecutiveSummary/Components/BarChartContainer";
import { aggregate, getAggregateBarData } from "../../Util/aggregate.js";
import DaisySummary from "../Daisy/Components/DaisySummary";
import { getDaisySummaryData } from "../Daisy/Util/daisyUtil";
import SortableTable from "../Daisy/Components/SortableTable/SortableTable";
import CarouselModal from "../ExecutiveSummary/Components/CarouselModal";
import ExecutiveSummaryBox from "../Daisy/Components/ExecutiveSummaryBox";
import DatePicker from "react-datepicker";
import AudienceSelector from "./AudienceSelector";
import HorizontalBarChart from "../../Components/Charts/HorizontalBarChart";
function RegAg({ assetData, categoricalOptions }) {
  const [currentAsset, setCurrentAsset] = useState(null);
  const [showAssetModal, setShowAssetModal] = useState(false);
  const [audience, setAudience] = useState("M2M");
  const filteredData = assetData.filter((asset) => {
    if (audience === "Overall") {
      return true;
    } else {
      return asset["Audience"] === audience;
    }
  });
  const channelPerformance = {
    M2M: [
      {
        title: "CPM vs CTR",
        filename: "/2023Q2/M2M_CPM_CTR.png",
      },
      {
        title: "CPM vs VCR",
        filename: "/2023Q2/M2M_CPM_VCR.png",
      },
      {
        title: "CPM vs Avg. Session Duration",
        filename: "/2023Q2/M2M_CPM_SessionDuration.png",
      },
    ],
    CORE: [
      {
        title: "CPM vs CTR",
        filename: "/2023Q2/CORE_CPM_CTR.png",
      },
      {
        title: "CPM vs VCR",
        filename: "/2023Q2/CORE_CPM_VCR.png",
      },
      {
        title: "CPM vs Avg. Session Duration",
        filename: "/2023Q2/CORE_CPM_SessionDuration.png",
      },
    ],
  };
  const execSummary = {
    M2M: [
      {
        title: "M2M - Executive Summary",
        bullets: [
          {
            intro: "Social: ",
            text: "Overall, both the platforms are garnering strong performance. On TikTok, the Top View activation achieved a blended CTR of 3.80% which is ~3x times higher than any other tactic for this campaign. Interest based audiences have generated a 90% more efficient CPC ($0.37) against the benchmark ($3.77). The retargeting audience continues to build momentum, as the audience continues to generate scale off of consumers who interacted/engaged with the Top View takeover. Currently the retargeting tactic has a blended CPC of $0.48 which is 87% lower than the benchmark of $3.93. The EN influencer activation successfully reached over 1.1M people with a very cost efficient CPM of $2.38. The FR influencer campaign reached ~726k consumers with a $3.01 CPM. Daisy_FR was the top performing FR influencer with the most cost efficient CPM of $2.76, achieving the highest level of impressions (1.3M) followed closely by Audrey Ferlattez. The Reddit awareness activation is pacing well in both EN and FR markets with a ~7% cost efficient blended CPM of $5.59 against benchmark of $6.00.",
          },

          {
            intro: "Cluep: ",
            text: "The campaign is off to a strong start with overall performance well above the 1.1% CTR benchmark, currently averaging 1.35% CTR for this reporting period. While both Display and Video placements are generating strong awareness among qualified audiences, the Video placements are currently resonating more strongly in comparison to display, generating nearly 1.4% CTR. Comparing market to market engagement, FR Auduences having conversations about Dairy being OK are currently the top performing target at 1.52% CTR. Meanwhile, the EN market is seeing a different trend in performance; sustainability advocates are the most engaged at 1.34% CTR.",
          },

          {
            intro: "Matterkind: ",
            text: "Overall, the campaign is pacing slightly ahead at 56%. The campaign is meeting the CPM goals and is seeing a CPM of $12.33 vs the average of $19.50. Between Affinity and Category targeting across the OM/PMP buy, affinity targeting segments are seeing more efficient CPMs, but category targeting segments have a higher CTR and VCR. Overall the 6s OM/PMP buy is exceeding VCR benchmarks (70%), however the 15s are underperforming vs. benchmark. The YouTube Masthead ran from July 21 - 25, and delivered to 60% of the planned spend up until July 23. Mastheads paced and confirmed to deliver in full on the 25th.",
          },
        ],
      },
      {
        title: "M2M - Creative Insights",
        bullets: [
          {
            intro: "Social: ",
            text: "On Reddit, creative performance was very even across all EN and FR creatives. However, looking at the performance on granular level, 'Digital Farm EN 6s' was the top performing creative with highest impressions, clicks, CTR, video completions and lowest CPC & CPCV. Despite 'Perennials' generating the most cost efficient CPM, it is not the top performing creative when we look at impressions and click levels. On TikTok, Core 15s EN creative generated over 3M impressions, 25k clicks with a $3.17 CPM which clearly shows that the TikTok UI favoured that creative over the others.",
          },
          {
            intro: "Matterkind: ",
            text: "Across the OM/PMP buy, Milk Tillage 6s EN had the best performing VCR at 84%. Top performing FR creative was Perennials and Crop Rotation at 75% VCR. However, We Do That EN/FR was most efficient in terms of VCR.",
          },
        ],
      },

      {
        title: "M2M - Recommended Optimizations",
        bullets: [
          {
            intro: "Social: ",
            text: "For future campaigns, consider running at least 2 influencers per language at the same time to avoid creative fatigue as we only had one influencer live on the FR portion of the campaign.",
          },
          {
            intro: "Cluep: ",
            text: "No optimizations currently. Pacing is on track, performance is being monitored week over week.",
          },
          {
            intro: "Matterkind: ",
            text: "No optimizations currently. Pacing is on track, performance is being monitored week over week.",
          },
        ],
      },
    ],
    CORE: [
      {
        title: "CORE - Media Insights",
        bullets: [
          {
            intro: "Social",
            text: "Pinterest Awareness campaigns are pacing well, generating cost effective and efficient CPMs surpassing the benchmarks ($5.46) by ~27%. Meanwhile, the Consideration campaign continues to drive strong levels of performance, resulting in 17.5k clicks and a 77% lower CPC then benchmark ($8.22). Overall Meta is generating consistent performance, with the exception of Retargeting as it's pacing behind due to limited audience size. We've broadened the audience by adding retargeting off of 3 sec video views. We anticipate this adjustment to increase spending and scalability. Additionally, we have shifted 20% of the budget from retargeting and reallocated it to English Fragile target audience as this segment is generating the strongest overall performance. By adding in the incremental budget we anticipate to see an increase in impressions and incremental reach. Majority of the influencer ads were flagged and rejected multiple times on Meta due to Special Category policies specifically Social policy and issues. The continuous rejections prompted the ads to relaunch into the learning phase, directly affecting the campaign health by increasing costs. 'OURBARNESYARD' was the top influencer creative with most efficient CPM ($5.27) because it was the only EN influencer ad which was not rejected by the Meta UI from the beginning.",
          },

          {
            intro: "Digital",
            text: "Campaign is currently pacing at 28%, which is slightly behind. This is primarily the result of Zefr pacing behind. The average goal CPM is $20 and we are currently at $16.63. Overall, Affinity audiences have the lowest CPMs, but Category audiences are seeing the highest CTRs. We are meeting the CPM benchmarks and testing different strategies to improve VCRs. We are seeing slightly higher CPMs with Fragile and Unquestioning tactics as they are using 1P audiences.",
          },
        ],
      },
      {
        title: "CORE - Creative Insights",
        bullets: [
          {
            intro: "Social",
            text: "On Reddit, creative performance was very even across all EN and FR creatives. However, looking at the performance on granular level, 'Digital Farm EN 6s' was the top performing creative with highest impressions, clicks, CTR, video completions and lowest CPC & CPCV. Despite 'Perennials' generating the most cost efficient CPM, it is not the top performing creative when we look at impressions and click levels. On TikTok, Core 15s EN creative generated over 3M impressions, 25k clicks with a $3.17 CPM which clearly shows that the TikTok UI favoured that creative over the others.",
          },
          {
            intro: "Digital",
            text: "Tremor CTV is currently delivering the highest VCR due to the non-skippable nature of the ad format. In terms of skippable ads, Zefr is leading the way at 86% VCR. Top performing 6s ad is Healthy Soil at 96% VCR.",
          },
        ],
      },

      {
        title: "CORE -  Recommended Optimizations",
        bullets: [
          {
            intro: "Social",
            text: "If pacing issues continue for Meta retargeting we will pause the audience and shift budget, this decisions will be made by August 3rd. Budget from Meta retargeting can be allocated to Pinterest specifically the consideration portion of the campaign. Majority of the influencer ads were flagged and rejected multiple times on Meta due to Special Category policies specifically Social policy and issues. The continuous rejections prompted the ads to relaunch into the learning phase, directly affecting the campaign health by increasing costs. There was an underspend on Meta for this wave of Influencer creative, the under delivery occurred due to multiple rejections, manual reviews and re-launched learning phases. The $1,574.12 underspend can be reallocated to the upcoming influencers on Meta.",
          },
          {
            intro: "Digital",
            text: "Continue to leverage long and short form creative. Currently pacing at 28% which is slightly behind. We have connected with Zefr and confirmed they are working with their ad ops team to increase pacing over the weekend to ensure we are on track to deliver in full. As of July 25, pacing has already increased to 30% delivery overall, which is an improvement week over week. We will continue to monitor this for the remainder of the campaign.",
          },
        ],
      },
    ],
  };
  const [showCarouselModal, setShowCarouselModal] = useState(false);

  const [carouselFilter, setCarouselFilter] = useState({
    group: null,
    metric: null,
    value: null,
  });

  function closeModalHandler() {
    setShowAssetModal(false);
  }

  function onAssetClick(data) {
    setCurrentAsset(data);
    setShowAssetModal(true);
  }
  const handleAudienceChange = (selectedOption) => {
    setAudience((prevState) => {
      return selectedOption.value;
    });
  };
  const barMetrics = ["CPM", "CPC", "VCR", "CPC", "CPCV", "Spend"];

  
  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <AudienceSelector handleAudienceChange={handleAudienceChange} />
      </div>
      {showCarouselModal && (
        <CarouselModal
          onClose={() => setShowCarouselModal(false)}
          assetData={filteredData}
          carouselFilter={carouselFilter}
          onAssetClick={onAssetClick}
        />
      )}

      {showAssetModal && (
        <AssetModal
          data={currentAsset}
          onClose={closeModalHandler}
          filteredData={filteredData}
        />
      )}

      {(audience === "M2M") | (audience === "CORE") && (
        <div className={styles["executivesummary"]}>
          <h1>Executive Summary</h1>
          <ExecutiveSummaryBox sections={execSummary[audience]} />
        </div>
      )}

      <div className={styles.aggregates}>
        <h1>Aggregate Analysis</h1>
        <div className={styles.barCharts}>
          {barMetrics.map((metric) => {
             return (
              <div className={styles.barChartWrapper}>
                <HorizontalBarChart
                  data={getAggregateBarData(
                    aggregate(filteredData, "Platform").sort(
                      dynamicSortMultiple([
                        metric,
                        Constants.AGGREGATE_ORDER[metric],
                      ])
                    ),
                    metric,
                    "Platform",
                    "v2"
                  )}
                  group={"Platform"}
                  xAccessor={metric}
                  xType={"number"}
                  
                  yType={"category"}
                  barFillColor={"var(--clr-primary)"}
                  title={`${metric} by Platform`}
                  key={metric }
                  setCarouselFilter = {setCarouselFilter}
                  setShowCarouselModal = {setShowCarouselModal}
                  yAxisWidth = { 50}
                  rightMargin = {metric === "Spend" ? 50 : 25}
                />
              </div>
            )
            
          })}
        </div>
      </div>

      {(audience === "CORE") | (audience === "M2M") && (
        <div className={styles.channelperformance}>
          <h1>Channel Performance</h1>

          {channelPerformance[audience].map((chart) => {
            return (
              <div className={styles.quadrantChartContainer} key = {chart.filename}>
                <h1>{chart.title}</h1>
                <img src = {chart.filename}/>
              </div>
            );
          })}
        </div>
      )}

      <div className={styles.topAssets}>
        <h1>Best Performing Assets</h1>
        <div className={styles.topAssetsContainer}>
          <TopAssetCard
            title={"Lowest CPM (EN)"}
            data={filteredData}
            metric={"CPM"}
            language="English"
            onClick={onAssetClick}
            lowest={true}
          />
          <TopAssetCard
            title={"Lowest CPC (EN)"}
            data={filteredData}
            metric={"CPC"}
            language="English"
            onClick={onAssetClick}
            lowest={true}
          />
          <TopAssetCard
            title={"Highest CTR (EN)"}
            data={filteredData}
            metric={"CTR"}
            language="English"
            onClick={onAssetClick}
            lowest={false}
          />
          <TopAssetCard
            title={"Highest VCR (EN)"}
            data={filteredData}
            metric={"VCR"}
            language="English"
            onClick={onAssetClick}
            lowest={false}
          />

          <TopAssetCard
            title={"Lowest CPM (FR)"}
            data={filteredData}
            metric={"CPM"}
            language="French"
            onClick={onAssetClick}
            lowest={true}
          />
          <TopAssetCard
            title={"Lowest CPC (FR)"}
            data={filteredData}
            metric={"CPC"}
            language="French"
            onClick={onAssetClick}
            lowest={true}
          />
          <TopAssetCard
            title={"Highest CTR (FR)"}
            data={filteredData}
            metric={"CTR"}
            language="French"
            onClick={onAssetClick}
            lowest={false}
          />

          <TopAssetCard
            title={"Highest VCR (FR)"}
            data={filteredData}
            metric={"VCR"}
            language="French"
            onClick={onAssetClick}
            lowest={false}
          />
        </div>
      </div>

      <div className={styles.topAssets}>
        <h1>Worst Performing Assets</h1>
        <div className={styles.topAssetsContainer}>
          <TopAssetCard
            title={"Highest CPM (EN)"}
            data={filteredData}
            metric={"CPM"}
            language="English"
            onClick={onAssetClick}
            lowest={false}
          />
          <TopAssetCard
            title={"Highest CPC (EN)"}
            data={filteredData}
            metric={"CPC"}
            language="English"
            onClick={onAssetClick}
            lowest={false}
          />
          <TopAssetCard
            title={"Lowest CTR (EN)"}
            data={filteredData}
            metric={"CTR"}
            language="English"
            onClick={onAssetClick}
            lowest={true}
          />
          <TopAssetCard
            title={"Lowest VCR (EN)"}
            data={filteredData}
            metric={"VCR"}
            language="English"
            onClick={onAssetClick}
            lowest={true}
          />

          <TopAssetCard
            title={"Highest CPM (FR)"}
            data={filteredData}
            metric={"CPM"}
            language="French"
            onClick={onAssetClick}
            lowest={false}
          />
          <TopAssetCard
            title={"Highest CPC (FR)"}
            data={filteredData}
            metric={"CPC"}
            language="French"
            onClick={onAssetClick}
            lowest={false}
          />
          <TopAssetCard
            title={"Lowest CTR (FR)"}
            data={filteredData}
            metric={"CTR"}
            language="French"
            onClick={onAssetClick}
            lowest={true}
          />

          <TopAssetCard
            title={"Lowest VCR (FR)"}
            data={filteredData}
            metric={"VCR"}
            language="French"
            onClick={onAssetClick}
            lowest={true}
          />
        </div>
      </div>
    </div>
  );
}

export default withAuthenticator(RegAg, { hideSignUp: true });
