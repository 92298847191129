import styles from "./SortableTable.module.css";

import { TABLE_DTYPES } from "../../../../constants";
import { useState } from "react";
import { dynamicSortMultiple } from "../../../../Util/sort";
const UPARROW = "\u{25B2}";
const DOWNARROW = "\u{25BC}";
function SortableTable({ columns, data, backgroundColors, rowKeyAccessors, defaultSort = {accessor:null, order: 1}}) {
  

  const [sort, setSort] = useState(defaultSort);

  const handleSortChange = (accessor) => {
    if (accessor === sort.accessor) {
      setSort((prevState) => {
        return {
          ...prevState,
          order: -1 * prevState.order,
        };
      });
    } else {
      setSort({ accessor: accessor, order: 1 });
    }
  };


  const sortedData = sort.accessor !== null ? data.sort(dynamicSortMultiple([sort.accessor, sort.order])) : data;
  
  return (
    <table className={styles.table} key = {sort.accessor + sort.order}>
      <thead>
        <tr>
          {columns.map(({ label, accessor, sortable }) => {
            return (
              <th
                key={accessor}
                className={sortable ? styles.sortable : ""}
                onClick={sortable ? () => {handleSortChange(accessor)} : null}
                style = {sort.accessor === accessor ? {color: "var(--clr-primary-dark)"} : null}
              >
                {label}<span style = {{verticalAlign: "-1px", marginLeft: "2px"}}>{sort.accessor === accessor ? (sort.order === 1 ? UPARROW : DOWNARROW) : " "}</span>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className={styles.tbody}>
        {sortedData.map((row,index) => (
          <tr
            key={rowKeyAccessors
              .map((accessor) => row[accessor])
              .join(" ") + index}
            style={{ backgroundColor: backgroundColors[index] }}
          >
            {columns.map(({ label, accessor, callback, keys, style }) => {
              return (
                <td key={accessor + label } style={style ? style : {}}>
                  {callback ? callback(row) : row[accessor]}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default SortableTable;
