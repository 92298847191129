import styles from "./MediaReport.module.css";
import SortableFormatTable from "../../../Components/SortableFormattedTable/SortableFormatTable";
import React from "react";
import { useState } from "react";

const TABLE_COLORS = ["#FFF", "#DCDCDC"];

const TIMESERIES_METRICS = ['Spend', 'Impressions', 'Clicks', 'CPM', 'CTR', 'VCR']

const MediaReport = ({ data, timeseriesData, columns, title, lastRow, lastRowTitle }) => {

    const [timeSeriesMetric, setTimeSeriesMetric] = useState('CPM');

    
  return (
    <div className={styles.mediareportcontainer}>
      <h1 className = "font-extrabold">{title}</h1>
      <SortableFormatTable
        columns={columns}
        data={data}
        backgroundColors={data.map((asset, index) => TABLE_COLORS[index % 2])}
        defaultSort={{ accessor: "group", order: 1 }}
        rowKeyAccessors={["group"]}
        lastRow = {lastRow}
        lastRowTitle = {lastRowTitle}
      />
    </div>
  );
};

export default MediaReport;
