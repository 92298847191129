import styles from "./VideoWrapper.module.css";

import { ClipLoader } from "react-spinners";
import { getObjectUrl, getObject, getSignedUrl } from "../../../../Util/s3";
import { useState, useEffect } from "react";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const VideoWrapper = (props) => {
  const [Url, setUrl] = useState(null);
  
  useEffect(() => {
    if (props["Filename"].includes("youtu.be")) {
      setUrl("http://www.youtube.com/embed/" + props.Filename.split("/").pop());
    } else {
      getObjectUrl("assets/" + props.LOB + "/"+ props.Campaign + "/" + props.Filename)
        .then(setUrl)
        .catch(console.error);
    }
  }, [props]);

  const handleDownload = () => {
    getSignedUrl(
      "assets/"  + props.LOB + "/" + props.Campaign + "/" + props.Filename,
      props.Filename
    ).then((signedUrl) => {
      const a = document.createElement("a");
      a.href = signedUrl;
      a.download = signedUrl;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };
  return (
    <div className={styles["videoContainer"]}>
      <div className={styles["videoWrapper"]}>
        {Url ? (
          props["Asset Type"] === "Video" ? (
            props["Filename"].includes("youtu.be") ? (
              <iframe className={styles["assetVideo"]} src={Url}></iframe>
            ) : (
              <video
                className={styles["assetVideo"]}
                controls
                src={Url}
                preload="metadata"
                type="video/mp4"
                controlsList="nodownload" 
              />
            )
          ) : (
            <img src={Url} className={styles["assetImage"]} alt={props.Name} />
          )
        ) : (
          <ClipLoader
            color={"#32b0f0"}
            loading={!Url}
            cssOverride={{
              display: "block",
              margin: "0 auto",
            }}
            size={70}
            aria-label="Loading Spinner"
            data-testid="loader"
            speedMultiplier={0.5}
          />
        )}
      </div>

       <button className={styles.download} onClick={handleDownload} disabled = {Url}>
        Download Asset
      </button>
    </div>
  );
};

export default VideoWrapper;
