import styles from "./TopAssetCard.module.css";
import { dynamicSort, dynamicSortMultiple } from "../../../Util/sort";
import ThumbnailCard from "../../AssetLibrary/Components/Thumbnail/ThumbnailCard";

function TopAssetCard({ data, title, language, metric, lowest, onClick }) {
  return (
    <div className={styles.topAssetContainer}>
      <header
        className={`${styles.header} ${
          language === "English" ? styles.english : styles.french
        }`}
      >
        {title}
      </header>
      <div style= {{height: "90%"}}>
      {data.length != 0 && (
        <ThumbnailCard
          asset={
            data
              .filter((item) => {
                return item.Language.includes(language);
              })
              .filter((item) => {
                return item[metric] || item[metric] === 0;
              })
              .sort(dynamicSortMultiple([metric, lowest ? 1 : -1]))[0]
          }
          sort={[
            [metric, 1],
            ["None", 1],
          ]}
          onClick={onClick}
          thumbnailsPerPage={1}
          hidden={false}
          height={"500px"}
          fontSize={".75rem"}
        />
      )}
      </div>
    </div>
  );
}

export default TopAssetCard;
