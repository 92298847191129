import styles from "./Funnel.module.css";

const FUNNEL_COLORS = [
  "rgb(186, 221, 250)",
  "rgb(44, 150, 243)",
  "rgb(29, 100, 190)",
];

function FunnelLayer(props) {
  const b = 10;
  
   
  return (
    <div
      className={`${styles.layer}`}
      style={{
        backgroundColor: FUNNEL_COLORS[props.index],
        clipPath: `polygon(${5 + props.index * b}% ${5}%, ${
          95 - props.index * b
        }% ${5}%, ${85 - props.index * b}% ${95}%, ${
          15 + props.index * b
        }% ${95}%)`,
        
      }}
    >
      <div>
        <span className = {styles.pct}>{`${((props.number / props.total) * 100).toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })}%`}</span>{" "}
        <span>{`(${props.number.toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })})`}</span>
      </div>
      <p>{props.name}</p>
    </div>
  );
}
function Funnel(props) {
  return (
    <div className={styles.funnelContainer}>
      {props.layers.map((layer, index) => {
        return (
          <div className={styles.layerContainer} key = {layer.number + layer.layerName + index}>
            <FunnelLayer
              index={index}
              number={Number(layer.number)}
              total={Number(props.total)}
              name={layer.layerName}
              key = {layer.number + layer.layerName}
            />

            {index + 1 !== props.layers.length && (
              <div className={styles.verticalLine} key = {layer.layerName + index + Math.random()}/>
            )}
          </div>
        );
      })}
    </div>
  );
}
export default Funnel;
