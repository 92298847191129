import styles from "./FilterMenu.module.css";

import FilterMenuItem from "./FilterMenuItem";

const FilterMenu = (props) => {
  return (
    <div className={styles["filterMenu"]}>
      <div style = {{backgroundColor: props.headerBackgroundColor, color: props.headerTitleColor }}>
      <span className = {styles.title}>Filter</span>
      </div>
      {props.filters.map((item, idx) => (
        <FilterMenuItem
          text={item.name}
          key={item.id}
          id={item.id}
          onRemove={props.onRemoveFilter}
          isLast={idx + 1 === props.filters.length}
          onEdit={props.onEdit}
        />
      ))}
      <div className={styles["addFilterContainer"]}>
        <button className={styles["addFilter"]} onClick={props.onShowFilterModal}>
          Add New Filter
        </button>
      </div>
    </div>
  );
};

export default FilterMenu;
