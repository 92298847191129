import styles from "./Thumbnail.module.css";
import ClipLoader from "react-spinners/ClipLoader";
import { useState, useEffect } from "react";
import { getObjectUrl } from "../../../../Util/s3";
import PlatformIcon from "./Icons/PlatformIcon";
const Thumbnail = (props) => {
  const [Url, setUrl] = useState(null);

  useEffect(() => {
    if (props["Filename"].includes("youtu.be")) {
      setUrl(
        "https://img.youtube.com/vi/" +
          props["Filename"].split("/").pop() +
          "/0.jpg"
      );
    } else if (props["Asset Type"] === "Static") {
      getObjectUrl(
        "assets/" + props.LOB + "/" + props.Campaign + "/" + props.Filename
      )
        .then(setUrl)
        .catch(console.error);
    } else {
      getObjectUrl(
        "assets/" +
          props.LOB +
          "/" +
          props.Campaign +
          "/thumbnails/_" +
          props.Filename.slice(0, -4) +
          ".png"
      )
        .then(setUrl)
        .catch(console.error);
    }
  }, [props]);

  return (
    <div className={styles["thumbnail"]} onClick={() => props.onClick(props)}>
      {Url ? (
        <div
        className={styles["thumbnail-image"]}
        
        style = {{backgroundImage:`url(${Url})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "100%", height: "100%"}}
        alt={props.Name}
      ></div>
      ) : (
        <ClipLoader
          color={"#32b0f0"}
          loading={!Url}
          cssOverride={{
            display: "block",
            margin: "0 auto",
          }}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
          speedMultiplier={0.5}
        />
      )}
    </div>
  );
};

export default Thumbnail;

/*
 <img
          className={styles["thumbnail-image"]}
          style={{ maxHeight: props.maxHeight ? props.maxHeight : "100%", marginInline: "auto" }}
          src={Url}
          alt={props.Name}
        ></img>
        */
