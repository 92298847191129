import { aggregate } from "./aggregate";
import { getUniqueValues } from "./groups";
export function getComparisonValue(
  asset,
  filteredData,
  metric,
  comparisonGroup
) {
  const aggregates = aggregate(filteredData, comparisonGroup);

  switch (comparisonGroup) {
    case "Overall":
      return aggregates[0][metric];
    default:
      return aggregates.filter(
        (agg) => agg.group === asset[comparisonGroup]
      )[0][metric];
  }
}

export function computeComparisonBound(assetValue, comparisonValue, rand = 12) {
  let max = Math.max(assetValue, comparisonValue);

  return (rand * max) / 10;
}
export function getMetricFormat(metric) {
  if (
    [
      "Spend",
      "CPM",
      "CPC",
      "CPCV",
      "Cost per Follower",
      "Cost per Profile Visit",
    ].includes(metric)
  ) {
    return ["$", 2];
  } else if (
    [
      "Impressions",
      "Link Clicks",
      "Video Completions",
      "Engagements/Video Views",
    ].includes(metric)
  ) {
    return ["", 0];
  } else if (["CTR", "VCR", "Eng Rate/View Rate", "ER"].includes(metric)) {
    return ["%", 3];
  } else {
    return ["", 0];
  }
}

export function processDate(date, showYear = true) {
  const temp = new Date(date);
  const options = showYear
    ? {
        month: "short",
        year: "numeric",
        day: "numeric",
      }
    : {
        month: "short",
        day: "numeric",
      };
  return temp.toLocaleString("en-US", options);
}


export function yyyymmddToLocalDate(isoString) {
  const [year, month, day] = isoString.split('-');
  return new Date(year, month - 1, day);
}


export function breakAroundSpace(str, size = 15) {
  var parts = [];

  var regexp = new RegExp("^[\\s\\S]{1," + size + "}\\S*");

  for (var match; (match = str.match(regexp)); ) {
    var prefix = match[0];
    parts.push(prefix);
    // Strip leading space.
    str = str.substring(prefix.length).replace(/^\s+/, "");
  }
  if (str) {
    parts.push(str);
  }
  return parts;
}

export function formatMetric(data, metric = "", returnNA = false) {
  return formatData(
    data,
    getMetricFormat(metric)[0],
    getMetricFormat(metric)[1],
    returnNA
  );
}
export const cumulativeSum = (
  (sum) => (value) =>
    (sum += value)
)(0);

export function formatData(data, format = "", precision = 2, returnNA = false) {
  if (data) {
    return (
      (format === "$" ? "$" : "") +
      Number((format ==="%%" ? 100 : 1)*data).toLocaleString("en-US", {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      }) +
      ((format === "%" || format === "%%") ? "%" : "")
    );
  } else {
    return returnNA ? "N/A" : null;
  }
}

export function numberToK(num) {
  if (num > 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num > 1000) {
    return (num / 1000).toFixed(1) + "K";
  } else {
    return num;
  }
}

const BUCKET_SIZE = 15;

export function getLengthBucket(asset) {
  const length = asset["Length"];

  if (asset["Asset Type"] === "Static") {
    return "STATIC";
  } else {
    let bucket_number =
      length % BUCKET_SIZE === 0
        ? Math.floor(length / BUCKET_SIZE) - 1
        : Math.floor(length / BUCKET_SIZE);

    return bucket_number === 0
      ? `${0}-${BUCKET_SIZE}`
      : `${bucket_number * BUCKET_SIZE + 1}-${
          (bucket_number + 1) * BUCKET_SIZE
        }`;
  }
}

export function getLengthBucket2(asset) {
  const length = asset["Length"];

  if (asset["Asset Type"] === "Static" || length <= 6) {
    return "0 - 6s";
  } else if (length <= 15) {
    return "7 - 15s";
  } else {
    return "15+s";
  }
}

export function isSortValueNull(asset, sortField) {
  if (sortField === "None") {
    return true;
  } else {
    return asset[sortField] || asset[sortField] === 0;
  }
}

export function selectOptionsFromArray(options){
  return options.map((option) => {return {label: option, value: option}});
}

export function omit(obj, omitKey) {
  
  return Object.keys(obj).filter(key => key != omitKey).reduce((result, key) => ({...result, [key]: obj[key]}), {});
    
}

export function getValueCounts(obj, key){
  
  const counts = {};
  for(const item of obj){
    
    if(item[key]){
      if(item[key] in counts){
        counts[item[key]] = counts[item[key]]+1;
      }else{
        counts[item[key]] = 1;
      }
    }
    else{
      if("null" in item[key]){
        counts["null"] =  counts["null"] + 1
      }else{
        counts["null"] = 1
      }
      
    }
  }
  return counts;
}