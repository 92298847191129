import { groupBy } from "./groups";
import { v4 as uuidv4 } from "uuid";

function sumGroupNonzero(group, field, metric) {
  return group.data
    .filter((asset) => {
      return asset[metric] || asset[metric] === 0;
    })
    .reduce((sum, asset) => {
      return sum + asset[field];
    }, 0);
}
export function aggregate(data, group) {
  
  let groups =
    group === "Overall"
      ? [{ group: "Overall", data: data, id: uuidv4() }]
      : groupBy(data, group);

  groups = groups.map((group) => ({
    ...group,
    Spend: group.data.reduce((sum, asset) => {
      return sum + asset.Spend;
    }, 0),
    "Link Clicks": group.data.reduce((sum, asset) => {
      return sum + asset["Link Clicks"];
    }, 0),
    Impressions: group.data.reduce((sum, asset) => {
      return sum + asset["Impressions"];
    }, 0),
    "Video Completions": group.data.reduce((sum, asset) => {
      return sum + asset["Video Completions"];
    }, 0),

    "Paid Profile Visits": group.data.reduce((sum, asset) => {
      return sum + asset["Paid Profile Visits"];
    }, 0),

    "Paid Followers": group.data.reduce((sum, asset) => {
      return sum + asset["Paid Followers"];
    }, 0),

    Likes: group.data.reduce((sum, asset) => {
      return sum + asset["Likes"];
    }, 0),

    Shares: group.data.reduce((sum, asset) => {
      return sum + asset["Shares"];
    }, 0),

    Comments: group.data.reduce((sum, asset) => {
      return sum + asset["Comments"];
    }, 0),
  }));
  groups = groups.map((group) => ({
    ...group,
    CPM:
      group["Impressions"] > 0
        ? (group["Spend"] / group["Impressions"]) * 1000
        : null,
    CPC:
      group["Link Clicks"] > 0 ? group["Spend"] / group["Link Clicks"] : null,
    CTR:
      group["Impressions"] > 0
        ? group["Link Clicks"] / group["Impressions"]
        : null,
    VCR:
      group["Impressions"] > 0
        ? group["Video Completions"] / group["Impressions"]
        : null,
    CPCV:
      group["Video Completions"] > 0
        ? group["Spend"] / group["Video Completions"]
        : null,

    "Cost per Follower":
      group["Paid Followers"] > 0
        ? group["Spend"] / group["Paid Followers"]
        : null,
    "Cost per Profile Visit":
      group["Paid Profile Visits"] > 0
        ? group["Spend"] / group["Paid Profile Visits"]
        : null,
    AvgSpend: group["Spend"] / group.length,
    AvgImpressions: group["Impressions"] / group.length,
  }));

  return groups;
}

export function metricLabelFormatter(val, context, metric) {
  if (val === null || val === undefined) {
    return null;
  } else if (
    [
      "CPM",
      "CPC",
      "Spend",
      "CPCV",
      "Cost per Profile Visit",
      "Cost per Follower",
    ].includes(metric)
  ) {
    return `$${val.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  } else if (["CTR", "VCR"].includes(metric)) {
    return `${(val * 100).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}%`;
  } else {
    return val.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }
}

export function   getAggregateBarData(aggregates, metric, groupName, version = "v1") {
  
  aggregates = aggregates.filter((agg) => { return agg[metric] || agg[metric] === 0})
  
  if(version === "v2"){
    return aggregates;
  }
  const labels = Array.from(new Set(aggregates.map((item) => item.group)));
  
  const data = {
    labels: labels,
    datasets: [
      {
        label: metric,
        "group": groupName,
        data: aggregates.map((agg) => {
          return agg[metric];
        }),
        size: aggregates.map((agg) => {
          return agg.data.length;
        }),
        backgroundColor: "rgba(83,135,226, 0.85)",
        datalabels: {
          color: "rgba(0,0,0,1)",

          display: true,
          formatter: (val, context) => {
            return metricLabelFormatter(val, context, metric);
          },
          font: {
            weight: "bold",
            size: "12px",
          },
        },
      },
    ],
  };
  
  return data;
}
