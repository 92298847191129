import styles from "./Admin.module.css";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { useState } from "react";
import { uploadObject } from "../../Util/s3";

function Admin({ user, signOut }) {
  const session = user.getSignInUserSession();
  if (!session) throw new Error("SignInSession is empty!");
  const accessToken = session.getAccessToken();
  const groups = accessToken.payload["cognito:groups"] || [];
  const isAdmin = groups.includes("admin");
  const [journeyFile, setJourneyFile] = useState(null);
  const [oldJourneyFile, setOldJourneyFile] = useState(null);
  const [journeySuccess, setJourneySuccess] = useState(false);

  const handleJourneyDataSelect = (e) => {
    setJourneySuccess(false);
    setJourneyFile(e.target.files[0]);
  };

  const handleJourneyUploadClick = () => {
    uploadObject(`data/journeys/${journeyFile.name}`, journeyFile)
      .then(setOldJourneyFile(journeyFile.name))
      .then(setJourneyFile(null))
      .then(setJourneySuccess(true));
      
  };

  const [daisyExecSummaryFile, setDaisyExecSummaryFile] = useState(null);
  const [daisyExecSummarySuccess, setDaisyExecSummarySuccess] = useState(false);

  const handleDaisyExecSummaryDataSelect = (e) => {
    setDaisyExecSummarySuccess(false);
    setDaisyExecSummaryFile(e.target.files[0]);
  };

  const handleDaisyExecSummaryUploadClick = () => {
    
    uploadObject(`data/${daisyExecSummaryFile.name}`, daisyExecSummaryFile)
      .then(setDaisyExecSummaryFile(null))
      .then(setDaisyExecSummarySuccess(true));
  };

  return (
    <div className={styles.main}>
      <div className={styles.uploadContainer}>
        <h1>Update Journey Files (journey_audience_data.xlsx or journey_data_*.xlsx)</h1>
        <input
          type="file"
          onChange={handleJourneyDataSelect}
          accept="journey_data*.xlsx, journey_audience_data.xlsx"
        />
        {journeyFile && (
          <div style={{ marginTop: "10px" }}>
            <button onClick={handleJourneyUploadClick}>Upload</button>
          </div>
        )}
        {journeySuccess && (
        <div className={styles.success}>{oldJourneyFile} successfully uploaded!</div>
      )}
      </div>

      

      <div className={styles.uploadContainer}>
        <h1>Update daisy_executive_summary.xlsx</h1>
        <input
          type="file"
          onChange={handleDaisyExecSummaryDataSelect}
          accept="daisy_executive_summary.xlsx"
        />
        {daisyExecSummaryFile && (
          <div style={{ marginTop: "10px" }}>
            <button onClick={handleDaisyExecSummaryUploadClick}>Upload</button>
          </div>
        )}
        {daisyExecSummarySuccess && (
          <div className={styles.success}>
            daisy_execituve_summary.xlsx successfully uploaded!
          </div>
        )}
      </div>
    </div>
  );
}

export default withAuthenticator(Admin, { hideSignUp: true });
