import styles from "./SourcePerformance.module.css";
import { useMemo } from "react";

function formatSourceData(data, column) {
  
  switch (column.toLowerCase()) {
    case "users":
    case "total users":
    case "sessions":
    case "engaged sessions":
      return `${Number(data).toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`;
      break;
    case "number of sessions per user":
    case "sessions per user":
      return `${Number(data).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    case "avg. session duration":
    case "average session duration":
      return new Date(data * 1000).toISOString().slice(11, 19);
    case "2+ pageviews":
      return `${(100 * Number(data)).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}%`;

    default:
      return data;
  }
}
const SourcePerformance = ({ sourceData }) => {
  if (!sourceData || sourceData.length === 0) {
    return null;
  }

  const COLUMNS = Object.keys(sourceData[0]);

  return (
    <div className={styles.sourceContainer}>
      <h1>Page Performance by Source</h1>
      {
        <table className={styles.dataTable}>
          <tbody>
            <tr>
              {COLUMNS.map((column) => {
                return (
                  <th className={styles.header} key={column}>
                    {column}
                  </th>
                );
              })}
            </tr>

            {sourceData.map((row, index) => {
              return (
                <tr key={index}>
                  {COLUMNS.map((column) => {
                    return (
                      <td key={row[column]}>
                        {formatSourceData(row[column], column)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      }
      
    </div>
  );
};

export default SourcePerformance;
