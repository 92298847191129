import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AssetLibrary from "./pages/AssetLibrary/AssetLibrary";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { withAuthenticator } from "@aws-amplify/ui-react";
import ErrorPage from "./pages/ErrorPage";
import Root from "./pages/Root";
import ExecutiveSummary from "./pages/ExecutiveSummary/ExecutiveSummary";

import Journeys from "./pages/Journeys/Journeys";
import Daisy from "./pages/Daisy/Daisy"
import RegAg from "./pages/RegAg/RegAg"
import { useState, useEffect } from "react";

import { getObjectUrl } from "./Util/s3";
import { read, utils } from "xlsx";
import Admin from "./pages/Admin/Admin";
import { generateCategoricalOptions } from "./Util/groups";
import { getLengthBucket, getLengthBucket2} from "./Util/util";
import CreativeAnalysis from "./pages/AssetLibrary/CreativeAnalysis"
import ChartTest from "./pages/ChartTest/ChartTest";
import ChartTest2 from "./pages/ChartTest/ChartTest2";
import Q3Media from "./pages/Q3Media/Q3Media";
import LiveJourneys from "./pages/LiveJourneys/LiveJourneys";
import DataTest from "./pages/DataTest/DataTest";

Amplify.configure(awsconfig);

const MAX_DATA = -1;
const IGNORE_MISSING_ASSETS = true;



function App() {
  const [assetData, setAssetData] = useState([]);

  useEffect(() => {
    getObjectUrl("data/asset_data.xlsx").then(async (url) => {
      const f = await (await fetch(url)).arrayBuffer();
      const wb = read(f, {
        cellText: false,
        cellDates: true,
      });

      let data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
        raw: true,
        defval: null,
      });

      if (IGNORE_MISSING_ASSETS) {
        data = data.filter(
          (item) => item["Filename"] != null && item["Filename"].trim() != ""
        );
      }

      for(const asset of data){
        asset['Length Range'] = getLengthBucket(asset);
        asset['Length Range for CA'] = getLengthBucket2(asset);
        asset['Cost per Profile Visit'] = asset['Paid Profile Visits'] > 0 ? asset['Spend']/asset['Paid Profile Visits'] : null;
        asset['Cost per Follower'] = asset['Paid Followers'] > 0 ? asset['Spend']/asset['Paid Followers'] : null;
      }
      
     

      if (MAX_DATA >= 0) {
        setAssetData(data.slice(0, MAX_DATA));
      } else {
        setAssetData(data);
      }
    });
  }, []);
  const categoricalOptions = generateCategoricalOptions(assetData);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,

      children: [
        {
          path: "/",
          element: <ExecutiveSummary assetData={assetData} categoricalOptions={categoricalOptions} />,
        },
        
        {
          path: "/creativeanalysis",
          element: <CreativeAnalysis assetData={assetData} categoricalOptions={categoricalOptions} />,
        },
        {
          path: "/charttest",
          element: <ChartTest assetData={assetData} categoricalOptions={categoricalOptions} />,
        },
        {
          path: "/charttest2",
          element: <ChartTest2 assetData={assetData} categoricalOptions={categoricalOptions} />,
        },
        {
          path: "/assetlibrary",
          element: <AssetLibrary assetData={assetData} categoricalOptions={categoricalOptions} />,
        },
        { path: "/journeys", element: <Journeys />},
        { path: "/daisy", element: <Daisy fullDaisyData={assetData.filter((item)=> {return item['LOB']==="Daisy"})} categoricalOptions={categoricalOptions}/>  },
        { path: "/regenerativeag", element: <RegAg assetData={assetData.filter((item)=> {return item['Campaign']==="2023 Q2 - Regenerative Agriculture"})} categoricalOptions={categoricalOptions}/>  },
        { path: "/admin", element: <Admin /> },
        {path :"/Q3Media", element: <Q3Media/>},
        {path :"/livejourneys", element: <LiveJourneys/>},
        {path :"/datatest", element: <DataTest/>},
      ],
    },
  ]);
  return <RouterProvider router={router} />;
}




export default withAuthenticator(App, { hideSignUp: true });

