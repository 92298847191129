import { withAuthenticator } from "@aws-amplify/ui-react";
import { v4 as uuidv4 } from "uuid";
import AddFilterModal from "../AssetLibrary/Components/Settings/AddFilterModal.js";

import { useState, useEffect } from "react";

import { evaluateFilter } from "../../Util/filter";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

import * as Constants from "../../constants";

import styles from "./ExecutiveSummary.module.css";
import { Tooltip } from "react-tooltip";

import AggregateFilterMenu from "./Components/AggregateFilterMenu.js";

import BarChartContainer from "./Components/BarChartContainer.js";
import { dynamicSortMultiple } from "../../Util/sort.js";

import AssetModal from "../AssetLibrary/Components/Asset/AssetModal.js";
import TopAssetCard from "./Components/TopAssetCard.js";

import Select from "react-select";

import { aggregate, getAggregateBarData } from "../../Util/aggregate.js";
import CarouselModal from "./Components/CarouselModal.js";
import HorizontalBarChart from "../../Components/Charts/HorizontalBarChart.js";

function ExecutiveSummary({ assetData, categoricalOptions }) {
  const [showSettings, setShowSettings] = useState(true);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showCarouselModal, setShowCarouselModal] = useState(false);
  const [carouselFilter, setCarouselFilter] = useState({
    group: null,
    metric: null,
    value: null,
  });

  const [initialFilterId, setInitialFilterId] = useState(null);
  const [currentAsset, setCurrentAsset] = useState(null);
  const [showAssetModal, setShowAssetModal] = useState(false);
  const [aggregateMetric, setAggregateMetric] = useState("CPM");
  const [filters, setFilters] = useState([
    {
      id: uuidv4(),
      name: "Spend >= 1500",
      orClauses: [
        {
          id: uuidv4(),
          clauses: [
            {
              id: uuidv4(),
              clause_data: {
                field: "Spend",
                comparisonType: {
                  value: Constants.FILTER_TYPES.NUMERIC_GREATER_THAN_OR_EQUAL,
                  label: "GREATER THAN OR EQUAL TO",
                },
                comparisonValue: "1500",
                comparisonValue2: "",
                isValid: true,
              },
            },
          ],
        },
      ],
    },
    {
      id: uuidv4(),
      name: "Audience = M2M",
      orClauses: [
        {
          id: uuidv4(),
          clauses: [
            {
              id: uuidv4(),
              clause_data: {
                field: "Audience",
                comparisonType: {
                  value: Constants.FILTER_TYPES.EQUAL,
                  label: "EQUAL TO",
                },
                comparisonValue: "M2M",
                comparisonValue2: "",
                isValid: true,
              },
            },
          ],
        },
      ],
    },
    {
      id: uuidv4(),
      name: "Paid",
      orClauses: [
        {
          id: uuidv4(),
          clauses: [
            {
              id: uuidv4(),
              clause_data: {
                field: "Paid/Organic",
                comparisonType: {
                  value: Constants.FILTER_TYPES.EQUAL,
                  label: "EQUAL TO",
                },
                comparisonValue: "Paid",
                comparisonValue2: "",
                isValid: true,
              },
            },
          ],
        },
      ],
    },
  ]);

  const handleToggleMenu = () => {
    setShowSettings((prevState) => {
      return !prevState;
    });
  };

  const handleCarouselToggle = () => {
    setShowCarouselModal((prevState) => {
      return !prevState;
    });
  };
  function onAssetClick(data) {
    setCurrentAsset(data);
    setShowAssetModal(true);
  }
  const handleAddFilter = (name, newFilter) => {
    if (filters.filter((filter) => newFilter.id === filter.id).length > 0) {
      setFilters((prevState) => {
        return prevState.map((filter) => {
          return filter.id === newFilter.id
            ? { ...newFilter, name: name }
            : filter;
        });
      });
    } else {
      setFilters((prevState) => {
        return [...prevState, { ...newFilter, name: name }];
      });
    }
  };

  function closeModalHandler() {
    setShowAssetModal(false);
  }

  useEffect(() => {
    if (showAssetModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showAssetModal]);

  const handleRemoveFilter = (id) => {
    setFilters((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });
  };

  const handleEditFilter = (id) => {
    setInitialFilterId(id);
    setShowFilterModal(true);
  };

  useEffect(() => {
    if (showFilterModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showFilterModal]);

  function closeFilterModalHandler() {
    setInitialFilterId(null);
    setShowFilterModal(false);
  }

  function onAddFilterClick() {
    setShowFilterModal(true);
  }

  const filteredData = assetData.filter((asset) =>
    evaluateFilter(asset, filters)
  );

  return (
    <div className={styles["main"]}>
      <div style={{ width: "100%", position: "absolute" }}>
        <FontAwesomeIcon
          data-tooltip-id="toggle-settings"
          icon={faGear}
          onClick={handleToggleMenu}
          size="2x"
          className={styles["settingsIcon"]}
        />

        <Tooltip id="toggle-settings">
          {showSettings ? "Hide Settings" : "Show Settings"}
        </Tooltip>
      </div>

      {showSettings && (
        <AggregateFilterMenu
          onShowFilterModal={() => setShowFilterModal(true)}
          filters={filters}
          setFilter={setFilters}
          onRemoveFilter={handleRemoveFilter}
          onAdd={handleAddFilter}
          hidden={showSettings}
          onEdit={handleEditFilter}
        />
      )}

      {showCarouselModal && (
        <CarouselModal
          onClose={() => setShowCarouselModal(false)}
          assetData={filteredData}
          carouselFilter={carouselFilter}
          onAssetClick={onAssetClick}
        />
      )}

      {showFilterModal && (
        <AddFilterModal
          FIELD_TYPES={Constants.FIELD_TYPES}
          FILTER_TYPES={Constants.FILTER_TYPES}
          onClose={closeFilterModalHandler}
          onAdd={handleAddFilter}
          initialFilter={
            initialFilterId === null
              ? null
              : filters.filter((filter) => {
                  return filter.id === initialFilterId;
                })[0]
          }
          categoricalOptions={categoricalOptions}
        />
      )}

      {showAssetModal && (
        <AssetModal
          data={currentAsset}
          onClose={closeModalHandler}
          filteredData={assetData.filter((asset) =>
            evaluateFilter(asset, filters)
          )}
        />
      )}
      <div className={styles.topAssets}>
        <h1>Top Performing Assets</h1>
        <div className={styles.topAssetsContainer}>
          <TopAssetCard
            title={"Lowest CPM (EN)"}
            data={filteredData}
            metric={"CPM"}
            language="English"
            onClick={onAssetClick}
            lowest={true}
          />
          <TopAssetCard
            title={"Lowest CPC (EN)"}
            data={filteredData}
            metric={"CPC"}
            language="English"
            onClick={onAssetClick}
            lowest={true}
          />
          <TopAssetCard
            title={"Highest CTR (EN)"}
            data={filteredData}
            metric={"CTR"}
            language="English"
            onClick={onAssetClick}
            lowest={false}
          />

          <TopAssetCard
            title={"Lowest CPM (FR)"}
            data={filteredData}
            metric={"CPM"}
            language="French"
            onClick={onAssetClick}
            lowest={true}
          />
          <TopAssetCard
            title={"Lowest CPC (FR)"}
            data={filteredData}
            metric={"CPC"}
            language="French"
            onClick={onAssetClick}
            lowest={true}
          />
          <TopAssetCard
            title={"Highest CTR (FR)"}
            data={filteredData}
            metric={"CTR"}
            language="French"
            onClick={onAssetClick}
            lowest={false}
          />
        </div>
      </div>
      <div className={styles.aggregates}>
        <h1>Aggregate Analysis</h1>
        <div className={styles.aggregateGroup}>
          <Select
            options={Constants.AGGREGATE_OPTIONS()}
            onChange={(selectedOption) => {
              setAggregateMetric(selectedOption.value);
            }}
            defaultValue={{ value: "CPM", label: "CPM" }}
            styles={Constants.SELECT_STYLE()}
          />
        </div>
        <div className={styles.barCharts}>
          {Constants.AGGREGATE_GROUPS.map((group) => {
            return (
              <div className={styles.barChartWrapper}>
                <HorizontalBarChart
                  data={getAggregateBarData(
                    aggregate(filteredData, group).sort(
                      dynamicSortMultiple([
                        aggregateMetric,
                        Constants.AGGREGATE_ORDER[aggregateMetric],
                      ])
                    ),
                    aggregateMetric,
                    group,
                    "v2"
                  )}
                  group={group}
                  xAccessor={aggregateMetric}
                  xType={"number"}
                  yAccesor={group}
                  yType={"category"}
                  barFillColor={"var(--clr-primary)"}
                  title={`${aggregateMetric} by ${group}`}
                  key={aggregateMetric + group}
                  setCarouselFilter = {setCarouselFilter}
                setShowCarouselModal = {setShowCarouselModal}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default withAuthenticator(ExecutiveSummary, { hideSignUp: true });

/*<BarChartContainer
                setCarouselFilter = {setCarouselFilter}
                setShowCarouselModal = {setShowCarouselModal}
                clickable = {true}
                title={`${aggregateMetric} by ${group}`}
                data={getAggregateBarData(
                  aggregate(filteredData, group).sort(
                    dynamicSortMultiple([
                      aggregateMetric,
                      Constants.AGGREGATE_ORDER[aggregateMetric],
                    ])
                  ),
                  aggregateMetric, group
                )}
                key={aggregateMetric + group}
              />*/
