import { withAuthenticator } from "@aws-amplify/ui-react";
import { v4 as uuidv4 } from "uuid";

import styles from "./Daisy.module.css";
import { useState, useEffect, useMemo, useRef } from "react";

import { getObjectUrl } from "../../Util/s3";
import { read, utils } from "xlsx";
import {
  numberToK,
  formatMetric,
  processDate,
  breakAroundSpace,
} from "../../Util/util";

import PieChart from "./Components/PieChart";
import { groupBy } from "../../Util/groups";
import * as Constants from "../../constants";
import TimeSeriesContainer from "./Components/TimeSeriesContainer";
import AssetModal from "../AssetLibrary/Components/Asset/AssetModal.js";
import TopAssetCard from "../ExecutiveSummary/Components/TopAssetCard.js";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import Carousel from "../AssetLibrary/Components/AssetCarousel/Carousel";

import SettingsMenu from "../AssetLibrary/Components/Settings/SettingsMenu.js";
import AddFilterModal from "../AssetLibrary/Components/Settings/AddFilterModal.js";
import { dynamicSortMultiple } from "../../Util/sort";
import { evaluateFilter } from "../../Util/filter";
import ThumbnailCard from "../AssetLibrary/Components/Thumbnail/ThumbnailCard";
import Select from "react-select";

import BarChartContainer from "../ExecutiveSummary/Components/BarChartContainer";
import { aggregate, getAggregateBarData } from "../../Util/aggregate.js";
import DaisySummary from "./Components/DaisySummary";
import { getDaisySummaryData } from "./Util/daisyUtil";
import SortableTable from "./Components/SortableTable/SortableTable";
import CarouselModal from "../ExecutiveSummary/Components/CarouselModal";
import ExecutiveSummaryBox from "./Components/ExecutiveSummaryBox";
import DatePicker from "react-datepicker";
import HorizontalBarChart from "../../Components/Charts/HorizontalBarChart";
import TimeSeriesChart from "../../Components/Charts/TimeSeriesChart";
function Daisy({ fullDaisyData, categoricalOptions }) {
  const [daisyAccountData, setDaisyAccountData] = useState([]);
  const [daisyAvatarUrl, setDaisyAvatarUrl] = useState([]);
  const [followersData, setFollowersData] = useState([]);
  const [currentAsset, setCurrentAsset] = useState(null);
  const [showAssetModal, setShowAssetModal] = useState(false);
  const [group, setGroup] = useState("Campaign");
  const [aggregateMetric, setAggregateMetric] = useState("CPM");
  const [sort, setSort] = useState([
    ["CPM", 1],
    ["None", 1],
  ]);

  const tableColors = ["#FFF", "#AAA"];
  const [showCarouselModal, setShowCarouselModal] = useState(false);
  const [carouselFilter, setCarouselFilter] = useState({
    group: null,
    metric: null,
    value: null,
  });
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [initialFilterId, setInitialFilterId] = useState(null);

  const [daisyDateFilter, setDaisyDateFilter] = useState(null);

  const [filters, setFilters] = useState([]);

  const [thumbnailsPerPage, setThumbnailsPerPage] = useState(5);

  const [showSettings, setShowSettings] = useState(false);

  const [execSummaryData, setExecSummaryData] = useState([]);

  const daisyData = daisyDateFilter
    ? fullDaisyData.filter((asset) => {
        return (
          asset["Start Date"].getMonth() === daisyDateFilter.getMonth() &&
          asset["Start Date"].getYear() === daisyDateFilter.getYear()
        );
      })
    : fullDaisyData;



  const paidData = daisyData.filter((item) => {
    return item["Paid/Organic"] === "Paid" && item["Spend"] > 0;
  });
  const organicData = daisyData.filter((item) => {
    return item["Paid/Organic"] === "Organic";
  });
  useEffect(() => {
    daisyPostTableRef.current.scrollTo({ top: 0, behavior: "smooth" });
  }, [daisyDateFilter]);
  useEffect(() => {
    getObjectUrl("data/daisy_account_data.xlsx").then(async (url) => {
      const f = await (await fetch(url)).arrayBuffer();
      const wb = read(f, {
        cellText: false,
        cellDates: true,
      });

      let data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
        raw: true,
        defval: null,
      });

      setDaisyAccountData(data);
    });

    getObjectUrl("data/daisy_executive_summary.xlsx").then(async (url) => {
      const f = await (await fetch(url)).arrayBuffer();
      const wb = read(f, {
        cellText: false,
        cellDates: true,
      });

      let data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
        raw: true,
        defval: null,
      });

      setExecSummaryData(data);
    });

    getObjectUrl("assets/Daisy/daisy_avatar.jpeg")
      .then(setDaisyAvatarUrl)
      .catch(console.error);
  }, []);

  let followersTimeSeries = {
    label: "Followers",

    dates: daisyAccountData
      .map((item) => {
        return processDate(item["scrape_date"], false)
          .split(" ")
          .reverse()
          .join(" ");
      })
      .slice(0, -1),
    values: daisyAccountData.map((item) => {
      return item["followers_count"];
    }),
  };
  const tempFollowers = [];

  for(let i = 0; i <= followersTimeSeries.dates.length; i++){
    tempFollowers.push({
      date: followersTimeSeries.dates[i],
      followers: followersTimeSeries.values[i]
    })
  }
  
  followersTimeSeries = tempFollowers;


  let likesTimeSeries = {
    label: "Likes",
    borderColor: "rgb(120,158,97)",
    backgroundColor: "rgba(120,158,97,.5)",
    dates: daisyAccountData
      .map((item) => {
        return processDate(item["scrape_date"], false)
          .split(" ")
          .reverse()
          .join(" ");
      })
      .slice(0, -1),
    values: daisyAccountData.map((item) => {
      return item["likes_count"];
    }),
  };

  const tempLikes = [];

  for(let i = 0; i <= likesTimeSeries.dates.length; i++){
    tempLikes.push({
      date: likesTimeSeries.dates[i],
      likes: likesTimeSeries.values[i]
    })
  }
  
  likesTimeSeries = tempLikes;
  
  const handleCarouselToggle = () => {
    setShowCarouselModal((prevState) => {
      return !prevState;
    });
  };
  function closeModalHandler() {
    setShowAssetModal(false);
  }

  function onAssetClick(data) {
    setCurrentAsset(data);
    setShowAssetModal(true);
  }

  function closeFilterModal() {
    setInitialFilterId(null);
    setShowFilterModal(false);
  }

  const handleGroupChange = (selectedOption) => {
    setGroup(selectedOption.value);
  };

  const handlePrimarySortChange = (selectedOption) => {
    setSort((prevState) => {
      if (selectedOption.value === "None") {
        return [prevState[1], ["None", 1]];
      } else {
        return [[selectedOption.value, prevState[0][1]], prevState[1]];
      }
    });
  };

  const handleSecondarySortChange = (selectedOption) => {
    setSort((prevState) => {
      return [prevState[0], [selectedOption.value, prevState[1][1]]];
    });
  };

  const handleThumbnailChange = (e) => {
    setThumbnailsPerPage(e.target.value);
  };

  const handlePrimarySortDirection = (selectedOption) => {
    setSort((prevState) => {
      return [[prevState[0][0], 1 - prevState[0][1]], prevState[1]];
    });
  };

  const handleSecondarySortDirection = (selectedOption) => {
    setSort((prevState) => {
      return [prevState[0], [prevState[1][0], 1 - prevState[1][1]]];
    });
  };

  const handleRemoveFilter = (id) => {
    setFilters((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });
  };

  const handleToggleMenu = () => {
    setShowSettings((prevState) => {
      return !prevState;
    });
  };

  const handleAddFilter = (name, newFilter) => {
    if (filters.filter((filter) => newFilter.id === filter.id).length > 0) {
      setFilters((prevState) => {
        return prevState.map((filter) => {
          return filter.id === newFilter.id
            ? { ...newFilter, name: name }
            : filter;
        });
      });
    } else {
      setFilters((prevState) => {
        return [...prevState, { ...newFilter, name: name }];
      });
    }
  };

  const handleEditFilter = (id) => {
    setInitialFilterId(id);
    setShowFilterModal(true);
  };

  useEffect(() => {
    if (showAssetModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showAssetModal]);

  useEffect(() => {
    if (showFilterModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showFilterModal]);

  const lastAccountScrape = daisyAccountData.slice(-1)[0];

  const daisySummaryData = useMemo(
    () =>
      daisyData.length > 0
        ? [
            getDaisySummaryData(daisyData, "Language", "English", "English"),

            getDaisySummaryData(daisyData, "Language", "French", "French"),
            getDaisySummaryData(daisyData, "None", "None", "Total"),
          ]
        : null,
    [daisyData, daisyDateFilter]
  );


  const daisySummaryColumns = [
    { label: "", accessor: "name" },
    { label: "Post Count", accessor: "numPosts" },
    { label: "Views", accessor: "numViews" },
    { label: "Likes", accessor: "numLikes" },
    { label: "Comments", accessor: "numComments" },
    { label: "Shares", accessor: "numShares" },
    { label: "Paid Profile Visits", accessor: "numProfileVisits" },
    { label: "Paid Followers", accessor: "numFollowers" },
    { label: "Spend", accessor: "totalSpend" },
  ];
  const daisyPostTableRef = useRef(null);

  const daisyPostColumns = [
    {
      label: "Date",
      accessor: "Start Date",
      callback: (asset) => {
        return processDate(asset["Start Date"]);
      },
      sortable: true,
    },
    {
      label: "Post",
      accessor: "Name",
      callback: (asset) => {
        return (
          <ThumbnailCard
            asset={asset}
            sort={[
              ["None", 1],
              ["None", 1],
            ]}
            onClick={onAssetClick}
            thumbnailsPerPage={1}
            hidden={false}
            height={"500px"}
            fontSize={".75rem"}
            hideIcons={true}
            hideTitleIcons={true}
          />
        );
      },
      style: { width: "auto", height: "225px" },
    },
    {
      label: "URL",
      accessor: "TikTok Video ID",
      callback: (asset) => {
        return (
          <a
            href={`http://www.tiktok.com/@moo_daisy/video/${asset["TikTok Video ID"]}`}
            target="_blank"
          >
            link
          </a>
        );
      },
    },
    { label: "Content Bucket", accessor: "Campaign", sortable: true },
    { label: "Paid/Organic", accessor: "Paid/Organic", sortable: true },
    { label: "Views", accessor: "Views", sortable: true },
    { label: "Likes", accessor: "Likes", sortable: true },
    { label: "Comments", accessor: "Comments", sortable: true },
    { label: "Shares", accessor: "Shares", sortable: true },
    {
      label: "Paid Profile Visits",
      accessor: "Paid Profile Visits",
      sortable: true,
    },
    { label: "Paid Followers", accessor: "Paid Followers", sortable: true },
  ];

  const execSummaryText = [
    {
      title: "Key Milestones - Q2 2023",
      bullets: [
        {
          text: "In Q2 we continued the efforts of Q1 with strong results.",
        },
        {
          text: "In Q2, we spent less than 50% of our media budget, but reached:",
          subbullets: [
            { text: "54% of the impression objective" },
            { text: "51% of the views objective" },
            { text: "39% of the follower objective." },
          ],
        },
        { text: "We posted a total of 58 posts (an average of 17 a month)." },
        { text: "We gained 4,054 followers." },
        {
          text: "We continued boosting all non trend posts to keep increasing our reach and grow our followers.",
        },
        {
          text: "We collaborated with 5 influencers (Recipes and M2M support).",
        },
        {
          text: "Daisy supported multiple campaigns in Q2, such as WMD, Regenerative Agriculture, Smoke’s partnership.",
        },
      ],
    },
  ];

  const postCountData = {
    labels: ["EN", "FR"], //["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "posts", //"# of Votes",
        data: [
          daisyData.filter((item) => item["Language"] == "English").length,
          daisyData.filter((item) => item["Language"] == "French").length,
        ], //[12, 19, 3, 5, 2, 3],
        backgroundColor: ["rgb(83,135,226)", "rgb(0, 68, 184)"],
        borderColor: ["rgb(83,135,226)", "rgb(0, 68, 184)"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className={styles.main}>
      <div style={{ width: "100%", position: "absolute" }}>
        <FontAwesomeIcon
          data-tooltip-id="toggle-settings"
          icon={faGear}
          onClick={handleToggleMenu}
          size="2x"
          className={styles["settingsIcon"]}
        />

        <Tooltip id="toggle-settings">
          {showSettings ? "Hide Settings" : "Show Settings"}
        </Tooltip>
      </div>

      {showSettings && (
        <SettingsMenu
          groupByOptions={Constants.GROUPBY_OPTIONS()}
          handleGroupChange={handleGroupChange}
          onShowFilterModal={() => setShowFilterModal(true)}
          handlePrimarySortChange={handlePrimarySortChange}
          handlePrimarySortDirection={handlePrimarySortDirection}
          handleSecondarySortChange={handleSecondarySortChange}
          handleSecondarySortDirection={handleSecondarySortDirection}
          handleThumbnailChange={handleThumbnailChange}
          thumbnailsPerPage={thumbnailsPerPage}
          sort={sort}
          filters={filters}
          setFilter={setFilters}
          onRemoveFilter={handleRemoveFilter}
          onAdd={handleAddFilter}
          hidden={showSettings}
          onEdit={handleEditFilter}
        />
      )}

      {showFilterModal && (
        <AddFilterModal
          FIELD_TYPES={Constants.FIELD_TYPES}
          FILTER_TYPES={Constants.FILTER_TYPES}
          onClose={closeFilterModal}
          onAdd={handleAddFilter}
          initialFilter={
            initialFilterId === null
              ? null
              : filters.filter((filter) => {
                  return filter.id === initialFilterId;
                })[0]
          }
          categoricalOptions={categoricalOptions}
        />
      )}
      {showCarouselModal && (
        <CarouselModal
          onClose={() => setShowCarouselModal(false)}
          assetData={daisyData}
          carouselFilter={carouselFilter}
          onAssetClick={onAssetClick}
        />
      )}

      {showAssetModal && (
        <AssetModal
          data={currentAsset}
          onClose={closeModalHandler}
          filteredData={paidData}
        />
      )}

      <div className={styles["header"]}>
        <div className={styles["daisyAccountSummaryContainer"]}>
          <div className={styles["daisyAvatarContainer"]}>
            {daisyAvatarUrl && (
              <img className={styles["daisyAvatar"]} src={daisyAvatarUrl}></img>
            )}{" "}
            <div>
              <span>@Moo_Daisy</span>
              {lastAccountScrape && (
                <div className={styles["lastUpdateHeader"]}>
                  <span className={styles["lastUpdateLabel"]}>
                    Last Updated:
                  </span>
                  <span className={styles["lastUpdateDate"]}>
                    {lastAccountScrape["scrape_date"].toLocaleString(
                      "default",
                      {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                      }
                    )}
                  </span>
                </div>
              )}
            </div>
          </div>
          {lastAccountScrape && (
            <div className={styles["daisyAccountStats"]}>
              <div className={styles["statContainer"]}>
                <span className={styles["statNumber"]}>
                  {numberToK(lastAccountScrape["following_count"])}
                </span>
                <span className={styles["statLabel"]}>Following</span>
              </div>

              <div className={styles["statContainer"]}>
                <span className={styles["statNumber"]}>
                  {numberToK(lastAccountScrape["followers_count"])}
                </span>
                <span className={styles["statLabel"]}>Followers</span>
              </div>

              <div className={styles["statContainer"]}>
                <span className={styles["statNumber"]}>
                  {numberToK(lastAccountScrape["likes_count"])}
                </span>
                <span className={styles["statLabel"]}>Likes</span>
              </div>
            </div>
          )}
          <div className={styles["daisyDescription"]}>
            Daisy — Canadian dairy cow｜vache laitière canadienne 🐄🇨🇦
          </div>
        </div>

        {daisySummaryData && (
          <div className={styles.daisyDateWrapper}>
            <div className={styles.daisyDatePickerWrapper}>
              <DatePicker
                isClearable
                selected={daisyDateFilter}
                onChange={(date) => setDaisyDateFilter(date)}
                dateFormat="MMMM yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                className={styles.daisyDatePicker}
                placeholderText="Filter by Month"
              />
            </div>
            {
              <SortableTable
                columns={daisySummaryColumns}
                data={daisySummaryData}
                backgroundColors={["#FFF", "#FFF", "#AAA"]}
                key={daisyDateFilter}
                rowKeyAccessors={["name"]}
              />
            }
          </div>
        )}
      </div>

      <div className={styles["timeSeriesContainer"]}>
        
        {followersTimeSeries &&  (
          <div className = {styles.timeSeries}>
            <TimeSeriesChart 
            title = {"@Moo_Daisy Follower Count"}
            data={followersTimeSeries}
            dataKeys = {[{dataKey:"followers", stroke: "rgb(220,158,197)"}]}
            key = {"followers"}/>
            </div>
        )}
        {likesTimeSeries &&  (
          <div className = {styles.timeSeries}>
            <TimeSeriesChart 
            title = {"@Moo_Daisy Likes Count"}
            data={likesTimeSeries}
            dataKeys = {[{dataKey:"likes", stroke: "rgb(120,158,97)"}]}
            key = {"likes"}/>
            </div>
        )}
      </div>
      {execSummaryText.length > 0 && (
        <ExecutiveSummaryBox sections={execSummaryText} />
      )}
      <div className={styles.topAssets}>
        <h1>Top Performing Paid Assets</h1>
        <div className={styles.topAssetsContainer}>
          <TopAssetCard
            title={"Lowest CPM (EN)"}
            data={paidData}
            metric={"CPM"}
            language="English"
            onClick={onAssetClick}
            lowest={true}
          />

          <TopAssetCard
            title={"Most Likes (EN)"}
            data={paidData}
            metric={"Likes"}
            language="English"
            onClick={onAssetClick}
            lowest={false}
          />

          <TopAssetCard
            title={"Lowest Cost per Profile Visit (EN)"}
            data={paidData}
            metric={"Cost per Profile Visit"}
            language="English"
            onClick={onAssetClick}
            lowest={true}
          />

          <TopAssetCard
            title={"Lowest Cost per Follower (EN)"}
            data={paidData}
            metric={"Cost per Follower"}
            language="English"
            onClick={onAssetClick}
            lowest={true}
          />

          <TopAssetCard
            title={"Lowest CPM (FR)"}
            data={paidData}
            metric={"CPM"}
            language="French"
            onClick={onAssetClick}
            lowest={true}
          />

          <TopAssetCard
            title={"Most Likes (FR)"}
            data={paidData}
            metric={"Likes"}
            language="French"
            onClick={onAssetClick}
            lowest={false}
          />

          <TopAssetCard
            title={"Lowest Cost per Profile Visit (FR)"}
            data={paidData}
            metric={"Cost per Profile Visit"}
            language="French"
            onClick={onAssetClick}
            lowest={true}
          />

          <TopAssetCard
            title={"Lowest Cost per Follower (FR)"}
            data={paidData}
            metric={"Cost per Follower"}
            language="English"
            onClick={onAssetClick}
            lowest={true}
          />
        </div>
      </div>

      <div className={styles.topAssets}>
        <h1>Top Performing Organic Assets</h1>
        <div className={styles.topAssetsContainer}>
          <TopAssetCard
            title={"Most Views (EN)"}
            data={organicData}
            metric={"Views"}
            language="English"
            onClick={onAssetClick}
            lowest={false}
          />
          <TopAssetCard
            title={"Most Likes (EN)"}
            data={organicData}
            metric={"Likes"}
            language="English"
            onClick={onAssetClick}
            lowest={false}
          />
          <TopAssetCard
            title={"Most Shares (EN)"}
            data={organicData}
            metric={"Shares"}
            language="English"
            onClick={onAssetClick}
            lowest={false}
          />
          <TopAssetCard
            title={"Most Comments (EN)"}
            data={organicData}
            metric={"Comments"}
            language="English"
            onClick={onAssetClick}
            lowest={false}
          />
          <TopAssetCard
            title={"Most Views (FR)"}
            data={organicData}
            metric={"Views"}
            language="French"
            onClick={onAssetClick}
            lowest={false}
          />
          <TopAssetCard
            title={"Most Likes (FR)"}
            data={organicData}
            metric={"Likes"}
            language="French"
            onClick={onAssetClick}
            lowest={false}
          />
          <TopAssetCard
            title={"Most Shares (FR)"}
            data={organicData}
            metric={"Shares"}
            language="French"
            onClick={onAssetClick}
            lowest={false}
          />
          <TopAssetCard
            title={"Most Comments (FR)"}
            data={organicData}
            metric={"Comments"}
            language="French"
            onClick={onAssetClick}
            lowest={false}
          />
        </div>
      </div>

      <div className={styles.daisyPostTableContainer} ref={daisyPostTableRef}>
        <SortableTable
          columns={daisyPostColumns}
          data={daisyData.slice(0, 50)}
          backgroundColors={daisyData
            .slice(0, 50)
            .map((asset, index) => tableColors[index % 2])}
          defaultSort={{ accessor: "Date", order: 1 }}
          key={"daisyPosts" + daisyDateFilter}
          rowKeyAccessors={["TikTok Video ID"]}
        />
      </div>

      <div className={styles.aggregates}>
        <div
          style={{
            width: "100%",
            height: "2px",
            backgroundColor: "black",
            marginTop: "20px",
            marginBottom: "5px",
          }}
        ></div>
        <h1>Aggregate Analysis</h1>
        <div className={styles.aggregateGroup}>
          <Select
            options={Constants.AGGREGATE_OPTIONS_DAISY()}
            onChange={(selectedOption) => {
              setAggregateMetric(selectedOption.value);
            }}
            defaultValue={{ value: "CPM", label: "CPM" }}
            styles={Constants.SELECT_STYLE()}
          />
        </div>

        <div className={styles.barCharts}>
          {Constants.AGGREGATE_GROUPS_DAISY.map((group) => {
            return (

              
              <div className={styles.barChartWrapper} key = {aggregateMetric + group}>
                <HorizontalBarChart
                  data={getAggregateBarData(
                    aggregate(
                      daisyData.filter((asset) =>
                        evaluateFilter(asset, filters)
                      ),
                      group
                    ).sort(
                      dynamicSortMultiple([
                        aggregateMetric,
                        Constants.AGGREGATE_ORDER_DAISY[aggregateMetric],
                      ])
                    ),
                    aggregateMetric,
                    group,
                    "v2"
                  )}
                  group={group}
                  xAccessor={aggregateMetric}
                  xType={"number"}
                  yAccesor={group}
                  yType={"category"}
                  barFillColor={"var(--clr-primary)"}
                  title={`${aggregateMetric} by ${group}`}
                  key={aggregateMetric + group}
                  setCarouselFilter={setCarouselFilter}
                  setShowCarouselModal={setShowCarouselModal}
                  yAxisWidth = {group === "Campaign" ? 225 : 50}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default withAuthenticator(Daisy, { hideSignUp: true });
