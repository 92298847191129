import styles from "./StackedBarChart.module.css";

import React, { PureComponent } from "react";
import { formatMetric } from "../../Util/util";
import {
  BarChart,
  LabelList,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Text,
  Label,
} from "recharts";
import { formatData } from "../../Util/util";
/*
props:
    data: JSON of data
    yAccessor: 
    yType: "number" or "category"
    xAccesor
    xType
    color
    aspectRatio "width / height"
*/
const CustomLegend = (props) => {
  const { payload } = props;

  return null;
  /*const colors = ["#0000FF", "#ff223f"];
  return (
    <div className="d-flex justify-content-end">
      {payload.map((entry, index) => (
        <>
          <FaSquareFull className="mx-2" size={18} color={colors[index]} />
          <span className="mx-2" key={`item-${index}`}>
            {entry.value}
          </span>
        </>
      ))}
    </div>
  );*/
};
const CustomTooltip = ({ active, payload, label, metric }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles["customTooltipContainer"]}>
        <p className={styles["customTooltipTitle"]}>{`${label}`}</p>
        <p className={styles["customTooltipLabel"]}>
          {" "}
          {`${payload[0].name}: ${formatMetric(payload[0].value, metric)}`}
        </p>
      </div>
    );
  }
};
function CustomizedTick(props) {
  const { x, y, stroke, payload } = props;
  return (
    <Text
      x={x}
      y={y + 5}
      fill="#000"
      width={200}
      textAnchor="middle"
      verticalAnchor="start"
    >
      {payload.value}
    </Text>
  );
}

const CustomizedLabel = (props) => {
  
  const { x, y, width, height, fill, value, metric, index } = props;
  if (index === 1) {
    return (
      <text
        x={x + width}
        y={y + height / 2}
        dy={5}
        dx={5}
        fontSize="12"
        fontFamily="sans-serif"
        fill={fill}
        textAnchor="start"
      >
        {formatMetric(value, metric)}
      </text>
    );
  }
};

const StackedBarChart = (props) => {
  


  return (
    <div className={styles.wrapper}>
      <h1>{props.title}</h1>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={props.data}
          margin={{
            top: 5,
            right: 20,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="5" vertical={false} />
          <XAxis dataKey={props.xDataKey} tick={<CustomizedTick />} />
          <YAxis />
          <Legend verticalAlign="top" />
          <Tooltip
            /*content={<CustomTooltip group={props.group} metric = {props.xAccessor}/>}*/
            cursor={{ fill: "rgba(0,0,0,.05)" }}
          />

          {props.dataKeys.map((key, index) => (
            <Bar
              name={key.name}
              dataKey={key.key}
              fill={key.fill}
              // label={<CustomizedLabel metric={props.xAccessor}/>}
              stackId="a"
              key={key.key}
            >
              <LabelList
                fill="#000"
                position="top"
                valueAccessor={(entry) =>{
                  
                 return index === props.dataKeys.length - 1 ? `+${formatData(entry.value[1]-entry.value[0],null,0)} ${entry.label==="Total Canadians in Our Dairy Journey Ecosystem" ? " Canadians" : " Fragiles"}` : null

                }
                }
              />
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StackedBarChart;

/*<Bar
              dataKey={"previous_value"}
              fill={"#ff0000"}
              // label={<CustomizedLabel metric={props.xAccessor}/>}
              stackId="a"
              
            />
            <Bar
              dataKey={"current_delta"}
              fill={"#00FF00"}
              // label={<CustomizedLabel metric={props.xAccessor}/>}
              stackId="a"
              
            />*/
