import styles from "./ChangeArrow.module.css";
import { faUpLong, faDownLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ChangeArrow(props) {
  let change = "";
  let negative = null;
  
  if (props.metric === "CPM" || props.metric === "CPC" || props.metric === "CPCJ") {
    change = ((props.newValue - props.oldValue) / props.oldValue) * 100;
    
    if (isNaN(change)) {
      return null;
    }
    negative = change < 0;
    change =
      (negative ? -1 : 1) *
        change.toLocaleString("en-US", {
          minimumFractionDigits: props.precision ? props.precision : 0,
          maximumFractionDigits:  props.precision ? props.precision : 0,
        }) +
      "%";
  } else if (props.metric === "CTR") {
    change = (props.newValue - props.oldValue) / props.oldValue;
    negative = change < 0;
    change =
      (negative ? -1 : 1) * (Math.abs(change) < 1 ? 
        change.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) : change.toLocaleString("en-US", {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })) +
      "x";
  }

  return negative ? (
    <div
      className={styles.arrowContainer}
      style={
        ["CPM", "CPC", "CPCJ"].includes(props.metric)
          ? { color: "rgb(56, 217, 72)" }
          : { color: "red" }
      }
    >
      <FontAwesomeIcon icon={faDownLong} size="1x" />
      {` ${change}`}
    </div>
  ) : (
    <div
      className={styles.arrowContainer}
      style={
        ["CPM", "CPC", "CPCJ"].includes(props.metric)
          ? { color: "red" }
          : { color: "rgb(56, 217, 72)" }
      }
    >
      <FontAwesomeIcon icon={faUpLong} />
      {` ${change}`}

    </div>
  );
}

export default ChangeArrow;
