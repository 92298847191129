import styles from "./AudienceSelector.module.css";
import Select from "react-select";
import * as Constants from "../../constants";

const AudienceSelector = (props) => {
  return (
    
      <div className={styles["audienceSelect"]}>
        Choose Audience:
        <Select
          options={[{value: "M2M", label:"M2M"},{value: "CORE", label:"CORE"},{value: "CORE+M2M", label:"CORE+M2M"}, {value: "Overall", label:"Overall"}]}
          onChange={props.handleAudienceChange}
          defaultValue={{ value: "M2M", label: "M2M" }}
          menuPortalTarget={document.querySelector('body')}
          styles={Constants.SELECT_STYLE("auto", "40px", "1rem", "1rem")}
        />
      </div>
    
  );
};
export default AudienceSelector;
