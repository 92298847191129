import React from "react";
import ScoreCard from "./ScoreCard";
const ScorecardGroup = ({ data, title, metrics }) => {
  return (
    <fieldset className="bg-white rounded-xl w-[95%] mx-auto border border-solid border-black p-2 flex flex-col">
      <legend align="center" className="font-semibold text-lg">
        {title}
      </legend>
      <div className=" flex justify-evenly items-center">
        {metrics.map((metric) => (
          <ScoreCard
            title={metric.title}
            content={data[metric.accessor]}
            format={metric.format}
            precision={metric.precision}
            key={metric.accessor}
          />
        ))}
      </div>
    </fieldset>
  );
};

export default ScorecardGroup;
