import styles from "./DataTable.module.css";
import ChangeArrow from "./ChangeArrow";
import PlatformIcon from "../../AssetLibrary/Components/Thumbnail/Icons/PlatformIcon.js";

import { formatMetric } from "../../../Util/util";

const DataTable = (props) => {
  console.log(props)
  const rows = ["CPM", "CPC", "CTR"];
  return (
    <table className={styles.dataTable}>
      <tbody>
        <tr>
          <th className={styles.platformCell} rowSpan="2">
            <div className={styles.platformContainer}>
              <PlatformIcon
                width="3rem"
                height="3rem"
                platform={props.data.platformName}
              />
              {props.data.platformName}
            </div>
          </th>

          {props.data.adsets.map((adset) => {
            return (
              <th
                className={styles.journeyStage}
                colSpan="2"
                key={props.platformName + adset.name}
              >
                {adset.name}
              </th>
            );
          })}
        </tr>

        <tr>
          {props.data.adsets.map((adset) => {
            return [
              <td className={styles.resultType} key={adset.name + "Benchmark"}>
                Benchmark
              </td>,
              <td className={styles.resultType} key={adset.name + "Actual"}>
                Actual
              </td>,
            ];
          })}
        </tr>

        {rows.map((row) => {
          
            return (
              <tr key={props.platformName + row}>
                <td className={styles.rowTitle} key={props.platformName + row}>
                  {row}
                </td>
                {props.data.adsets.map((adset) => {
                  return [
                    <td
                      className={styles.rowData}
                      key={
                        row +
                        adset.data[row]["Benchmark"] +
                        props.platformName +
                        "1"
                      }
                    >
                      {row === "CTR"
                        ? formatMetric(
                            adset.data[row]["Benchmark"] * 100,
                            row,
                            true
                          )
                        : formatMetric(adset.data[row]["Benchmark"], row, true)}
                    </td>,
                    <td
                      className={`${styles.rowData} ${styles.actualColumn}`}
                      key={row + adset.data[row]["Actual"] + props.platformName}
                    >
                      <div style={{ height: "100%" }}>
                        {" "}
                        {row === "CTR"
                          ? formatMetric(
                              adset.data[row]["Actual"] * 100,
                              row,
                              true
                            )
                          : formatMetric(
                              adset.data[row]["Actual"],
                              row,
                              true
                            )}{" "}
                        <ChangeArrow
                          oldValue={adset.data[row]["Benchmark"]}
                          newValue={adset.data[row]["Actual"]}
                          metric={row}
                        />
                      </div>
                    </td>,
                  ];
                })}
              </tr>
            );
          
        })}
      </tbody>
    </table>
  );
};

export default DataTable;

