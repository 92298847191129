import { v4 as uuidv4 } from "uuid";

import { CATEGORICAL_FIELDS } from "../constants";
export function groupBy(data, group) {
  const unique = Array.from(new Set(data.map((item) => item[group])))
    .sort()
    .reverse();

  let groups = [];

  for (const key of unique) {
    groups.push({
      group: key ? key : "N/A",
      data: data.filter((item) => item[group] === key),
      id: uuidv4(),
    });
  }

  return groups;
}

export function generateCategoricalOptions(data) {
  if (data.length === 0) {
    return {};
  } else {
    let options = {};
    for (const field of CATEGORICAL_FIELDS()) {
      const temp = Array.from(new Set(data.map((item) => item[field]))).sort();

      options[field] = temp.map((item) => {
        return { label: item, value: item };
      });
    }
    return options;
  }
}

export function getUniqueValues(data, accessor) {
  if (data.length > 0) {
    const keys = data.flatMap(Object.keys);

    if (!keys.includes(accessor)) {
      throw new Error(
        `Error in getUnique: The key ${accessor} does not exist as a valid accessor in data.`
      );
    }

    const unique = Array.from(new Set(data.map((item) => item[accessor])))
      .sort()
      .reverse();
    return unique;
  }
  return [];
}
