import styles from "./BarChartContainer.module.css";
import { useRef } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  Bar,
  getElementAtEvent,
  getDatasetAtEvent,
  getElementsAtEvent,
} from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: "y",
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: false,
      position: "right",
    },
    title: {
      display: true,
    },
  },
  scales: {
    y: {
      grid: { display: false },
    },
    x: {
      grace: "10%",
    },
  },

  tooltips: {
    titleFont: {
      family: "commic-sans-ms",
    },
  },
};

function BarChartContainer(props) {
  const chartRef = useRef();
  const onClick = (event) => {
    if (getElementsAtEvent(chartRef.current, event).length > 0) {
      const datasetIndexNum = getElementsAtEvent(chartRef.current, event)[0]
        .datasetIndex;
      const dataPoint = getElementAtEvent(chartRef.current, event)[0].index;
      props.setCarouselFilter({
        group: props.data.datasets[datasetIndexNum].group,
        metric: props.data.datasets[datasetIndexNum].label,
        value: props.data.labels[dataPoint],
      });
      props.setShowCarouselModal(true);
    }
  };

  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.barChartContainer}>
          <Bar
            ref={chartRef}
            width={"100%"}
            height={"56.25%"}
            onClick={props.clickable ? onClick : null}
            options={{
              ...options,

              onHover: (event, chartElement) => {
                event.native.target.style.cursor = chartElement[0]
                  ? props.clickable
                    ? "pointer"
                    : "default"
                  : "default";
              },

              plugins: {
                ...options.plugins,
                title: {
                  display: true,
                  text: props.title,
                  align: "center",
                },
                datalabels: {
                  display: false,
                  color: "#FEFEF2",
                  font: {
                    size: 12,
                  },
                  align: "end",
                  anchor: "end",
                },
              },
              scales: {
                ...options.scales,
              },
            }}
            data={props.data}
            plugins={[ChartDataLabels]}
          />
        </div>
      </div>
    </div>
  );
}

export default BarChartContainer;
