import React from "react";
import styles from "./AWSLogout.module.css";


export default function AWSLogout({ signOut, user }) {
  return (
    <button className={styles["logout"]} onClick={signOut}>
      Log Out
    </button>
  );
}
