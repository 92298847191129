function dynamicSort(property, sortOrder = 1) {
  sortOrder = sortOrder === 1 ? 1 : -1;
  
    return function (a,b) {      
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }
  
  function dynamicSortMultiple() {
    
    var props = arguments;
    return function (obj1, obj2) {
        var i = 0, result = 0, numberOfProperties = props.length;
        
        while(result === 0 && i < numberOfProperties) {
          //console.log("prop")
            //console.log(props[i]);
            if(props[i][0] != "None"){
              
            result = dynamicSort(props[i][0], props[i][1])(obj1, obj2);
            }
            i++;
        }
        return result;
    }
  }

  
export {dynamicSort, dynamicSortMultiple};