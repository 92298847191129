import styles from "./TimeSeriesContainer.module.css";
import "chartjs-plugin-datalabels";
import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function TimeSeriesContainer(props) {
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: "top",
        reverse: true,
      },
      title: {
        display: true,
        text: props.title,
        font: {
          size: 22,
        },
      },
    },
  };

  const data = {
    labels: props.data.dates,
    datasets: [
      {
        label: "Core Journeys Completed",
        data: props.data.core,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "M2M Journeys Completed",
        data: props.data.m2m,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <div className={styles.timeSeriesContainer}>
      <Line width={"100%"}
        height = {"56.25%"} options={options} data={data} />
    </div>
  );
}

export default TimeSeriesContainer;
