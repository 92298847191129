import styles from "./AssetModal.module.css";

import AssetCard from "./AssetCard";


import { Fragment } from "react";

function AssetModal({ data, onClose, filteredData}) {
  return (
    <Fragment>
      <div className={styles["backdrop"]} onClick={onClose}></div>
      <AssetCard
        asset = {data}
        filteredData = {filteredData}
        onClose = {onClose}
      />
    </Fragment>
  );
}

export default AssetModal;
