
import styles from "./AddFilter.module.css";


import OrClause from "./OrClause.js";

import { useState, useMemo, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";


const blankFilter = () => {return  {
  id: uuidv4(),
  name: "",
  orClauses: [
    {
      id: uuidv4(),
      clauses: [
        {
          id: uuidv4(),
          clause_data: {
            field: "",
            comparisonType: "",
            comparisonValue: "",
            comparisonValue2: "",
            isValid: false,
          },
        },
      ],
    },
  ],
}};

const AddFilter = (props) => {
  const [filterName, setFilterName] = useState(props.initialFilter === null? "":props.initialFilter.name);
  const [filterState, setFilterState] = useState(
    props.initialFilter === null ? blankFilter() : props.initialFilter
  );


  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        props.onClose();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);


  const isValid = useMemo(() => {
    for (let orClause of filterState.orClauses) {
      for (let clause of orClause.clauses) {
        
        if (!clause.clause_data.isValid) {
          return false;
        }
      }
    }
    return true;
  }, [filterState]);

  
  return (
    <div className={`${styles["add-filter-container"]} ${props.className}`}>
      <div className={styles.xButton}>
        
        <FontAwesomeIcon
          icon={faXmark}
          size="2x"
          onClick={props.onClose}
          data-tooltip-id="close-filter-modal"
          data-tooltip-content="Close"
        />
        <Tooltip id="close-filter-modal" />
        
      </div>
      <div>
        <fieldset className = {styles['filterNameFieldset']}>
        <legend align="left" className = {styles['filterNameFieldsetLegend']} >
            Filter Name
          </legend>
        <input
          className={styles["filterName"]}
          type="text"
          placeholder="Enter a Filter Name"          
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
        />
        </fieldset>
      </div>
      <div className={styles["clauseContainer"]}>
        <div className={styles["orClauseContainer"]}>
          {filterState.orClauses.map((orclause, idx) => {
            return (
              <OrClause
                id={orclause.id}
                key={orclause.id}
                clauses={orclause.clauses}
                setFilterState={setFilterState}
                isLast={idx + 1 === filterState.orClauses.length}
                initialFilter = {props.initialFilter}
                categoricalOptions = {props.categoricalOptions}
              />
            );
          })}
        </div>
        <div className={styles["save-button-container"]}>
          <button
            disabled={!isValid || filterName === ""}
            className={(!isValid || filterName === "") ? styles["addFilterDisabled"] : styles["addFilter"]}
            onClick={() => {
                          
              props.onAdd(filterName, filterState);
              props.onClose();
            }}
            
            title= {isValid ? (filterName === "" ? "Enter a Filter Name" : "Save Filter") : "Invalid Filter"}
          >
          
            Save Filter
          </button>
        </div>
      </div>
    </div>
  );
};


export default AddFilter;
